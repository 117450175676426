import {
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAIL,
} from "./actionTypes"

export const uploadDocument = document => ({
  type: UPLOAD_DOCUMENT,
  payload: document,
})

export const uploadDocumentSuccess = document => ({
  type: UPLOAD_DOCUMENT_SUCCESS,
  payload: document,
})

export const uploadDocumentFail = error => ({
  type: UPLOAD_DOCUMENT_FAIL,
  payload: error,
})