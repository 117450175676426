import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_REPORTS,
  GET_REPORTS_BY_ID,
  ADD_NEW_REPORTS,
  DELETE_REPORTS,
  UPDATE_REPORTS,
} from "./actionTypes"
import {
  getReportsFail,
  getReportsSuccess,
  getReportsByIdFail,
  getReportsByIdSuccess,
  addReportsFail,
  addReportsSuccess,
  updateReportsSuccess,
  updateReportsFail,
  deleteReportsSuccess,
  deleteReportsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getReports,
  getReportsById,
  addNewReports,
  updateReports,
  deleteReports,
} from "helpers/fakebackend_helper"
import 'react-toastify/dist/ReactToastify.css';


function* fetchReports() {
  try {
    const response = yield call(getReports)
    yield put(getReportsSuccess(response.data))
  } catch (error) {
    yield put(getReportsFail(error.response))
  }
}

function* fetchReportsById({payload: reportsId}) {
  try {
    const response = yield call(getReportsById, reportsId)
    yield put(getReportsByIdSuccess(response.data))
  } catch (error) {
    yield put(getReportsByIdFail(error.response))
  }
}

function* onUpdateReports({ payload: reports }) {
  try {
    const response = yield call(updateReports, reports)
    yield put(updateReportsSuccess(response))
  } catch (error) {
    yield put(updateReportsFail(error.response))
  }
}

function* onDeleteReports({ payload: reports }) {
  try {
    const response = yield call(deleteReports, reports)
    yield put(deleteReportsSuccess(response, reports))
  } catch (error) {
    yield put(deleteReportsFail(error.response))
  }
}

function* onAddNewReports({ payload: reports }) {
  try {
    const response = yield call(addNewReports, reports)
    yield put(addReportsSuccess(response))
  } catch (error) {
    yield put(addReportsFail(error.response))
  }
}

function* reportsSaga() {
  yield takeEvery(GET_REPORTS, fetchReports)
  yield takeEvery(GET_REPORTS_BY_ID, fetchReportsById)
  yield takeEvery(ADD_NEW_REPORTS, onAddNewReports)
  yield takeEvery(UPDATE_REPORTS, onUpdateReports)
  yield takeEvery(DELETE_REPORTS, onDeleteReports)
}

export default reportsSaga
