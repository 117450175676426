/* REPORTS */
export const GET_REPORTS = "GET_REPORTS"
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS"
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL"


export const GET_REPORTS_BY_ID = "GET_REPORTS_BY_ID"
export const GET_REPORTS_BY_ID_SUCCESS = "GET_REPORTS_BY_ID_SUCCESS"
export const GET_REPORTS_BY_ID_FAIL = "GET_REPORTS_BY_ID_FAIL"

/**
 * add REPORTS
 */
export const ADD_NEW_REPORTS = "ADD_NEW_REPORTS"
export const ADD_REPORTS_SUCCESS = "ADD_REPORTS_SUCCESS"
export const ADD_REPORTS_FAIL = "ADD_REPORTS_FAIL"

/**
 * Edit REPORTS
 */
export const UPDATE_REPORTS = "UPDATE_REPORTS"
export const UPDATE_REPORTS_SUCCESS = "UPDATE_REPORTS_SUCCESS"
export const UPDATE_REPORTS_FAIL = "UPDATE_REPORTS_FAIL"

/**
 * Delete REPORTS
 */
export const DELETE_REPORTS = "DELETE_REPORTS"
export const DELETE_REPORTS_SUCCESS = "DELETE_REPORTS_SUCCESS"
export const DELETE_REPORTS_FAIL = "DELETE_REPORTS_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';