import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_PORTFOLIO,
  GET_PORTFOLIO_BY_ID,
  ADD_NEW_PORTFOLIO,
  DELETE_PORTFOLIO,
  UPDATE_PORTFOLIO,
} from "./actionTypes"
import {
  getPortfolioFail,
  getPortfolioSuccess,
  getPortfolioByIdFail,
  getPortfolioByIdSuccess,
  addPortfolioFail,
  addPortfolioSuccess,
  updatePortfolioSuccess,
  updatePortfolioFail,
  deletePortfolioSuccess,
  deletePortfolioFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  addNewPortfolio,
  /* getPortfolio,
   addNewPortfolio,
  updatePortfolio,
  deletePortfolio, 
  getPortfolioById, */
  deletePortfolio,
  updatePortfolio
} from "helpers/fakebackend_helper"
import 'react-toastify/dist/ReactToastify.css';

function* fetchPortfolio() {
  try {
    const response = yield call(getPortfolio)
    yield put(getPortfolioSuccess(response.data))
  } catch (error) {
    yield put(getPortfolioFail(error.response))
  }
}

function* fetchPortfolioById({payload: portfolioId}) {
  try {
    const response = yield call(getPortfolioById, portfolioId)
    yield put(getPortfolioByIdSuccess(response.data))
  } catch (error) {
    yield put(getPortfolioByIdFail(error.response))
  }
}

function* onUpdatePortfolio({ payload: portfolio }) {
  try {
    const response = yield call(updatePortfolio, portfolio)
    yield put(updatePortfolioSuccess(response))
  } catch (error) {
    yield put(updatePortfolioFail(error.response))
  }
}

function* onDeletePortfolio({ payload: portfolioId }) {
  try {
    const response = yield call(deletePortfolio, portfolioId)
    yield put(deletePortfolioSuccess(response, portfolioId))
  } catch (error) {
    yield put(deletePortfolioFail(error.response))
  }
}

function* onAddNewPortfolio({ payload: portfolio }) { 
  try {
    const response = yield call(addNewPortfolio, portfolio)
    yield put(addPortfolioSuccess(response))
  } catch (error) {
    yield put(addPortfolioFail(error.response))
  }
}

function* portfolioSaga() {
  yield takeEvery(GET_PORTFOLIO, fetchPortfolio)
  yield takeEvery(GET_PORTFOLIO_BY_ID, fetchPortfolioById)
  yield takeEvery(ADD_NEW_PORTFOLIO, onAddNewPortfolio)
  yield takeEvery(UPDATE_PORTFOLIO, onUpdatePortfolio)
  yield takeEvery(DELETE_PORTFOLIO, onDeletePortfolio)
}

export default portfolioSaga
