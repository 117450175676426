import axios from "axios"
import { getAccessToken } from "./jwt-token-access/accessToken"
import authHeader from "./jwt-token-access/auth-token-header"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//apply base url for axios
// const API_URL = "https://asia-impact-server.onrender.com/asiaimpact/v1"
const API_URL = "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1"

const axiosApi = axios.create({
  baseURL: API_URL,
})
const linkArray = ["/auth/login", "/auth/forgot-password"] //specific ignore token link
//axiosApi.defaults.headers.common["Authorization"] = token

//Add a request interceptor
// axiosApi.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   console.log("config-->", config)
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

axiosApi.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (
      error?.status === 401 ||
      error?.status === 403 ||
      error?.response?.status === 401 ||
      error?.response?.status === 403
    ) {
      // alert('User Session Expired. Please login again.')
      toast.error("User Session Expired. Please login again.")
      setTimeout(() => {
        localStorage.clear()
        window.location.href = "/login"
      }, 3000)
    }
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi.get(url, { ...config }).then(response => {
    return response.data
  })
}

export async function post(url, data, config = {}) {
  console.log("post", data)
  // console.log(data.get("file"))
  const payload = Array.isArray(data) ? data : { ...data }
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .post(url, payload, { ...config })
    .then(response => {
      toast.success(response.message)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}
export async function postDocument(url, data, config = {}) {
  console.log("post", data)
  // console.log(data.get("file"))
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => {
      // toast.success(response.message);
      console.log("postDocument response", response.data)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}

export async function put(url, data, config = {}) {
  const payload = Array.isArray(data) ? data : { ...data }
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .put(url, payload, { ...config })
    //.then(response => response.data)
    .then(response => {
      toast.success(response.message)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}

export async function del(url, config = {}) {
  console.log("delete", url)
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .delete(url, { ...config })
    //.then(response => response.data)
    .then(response => {
      toast.success(response.message)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}

export async function patch(url, data, config = {}) {
  console.log("patch", url)
  if (!linkArray.includes(url)) {
    axiosApi.defaults.headers.common["Authorization"] = getAccessToken()
  }
  return await axiosApi
    .patch(url, { ...data }, { ...config })
    //.then(response => response.data)
    .then(response => {
      toast.success(response.message)
      return response.data
    })
    .catch(error => {
      toast.error(error.response.data.error) // Show error toaster message
      throw error
    })
}
