import {
  GET_USERROLE_FAIL,
  GET_USERROLE_SUCCESS,
  GET_USERROLE_BY_ID_SUCCESS,
  GET_USERROLE_BY_ID_FAIL,
  ADD_USERROLE_SUCCESS,
  ADD_USERROLE_FAIL,
  UPDATE_USERROLE_SUCCESS,
  UPDATE_USERROLE_FAIL,
  DELETE_USERROLE_SUCCESS,
  DELETE_USERROLE_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  userroleList: [],
  userroleById:[],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {}
}

const UserRole = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERROLE_SUCCESS:
      return {
        ...state,
        userroleList: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {}
      }

    case GET_USERROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }

    case GET_USERROLE_BY_ID_SUCCESS:
      console.log('userrole reducer', action.payload);
      return {
        ...state,
        userroleById: action.payload,
        error: {}
      }

    case GET_USERROLE_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        userroleById: [],
      }

    case ADD_USERROLE_SUCCESS:
      return {
        ...state,
        userroleList: [...state.userroleList, action.payload],
        addUpdateSuccess: true,
        error: {}
      }

    case ADD_USERROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_USERROLE_SUCCESS:
      return {
        ...state,
        userroleList: state.userroleList.map(userrole =>
          userrole.id === action.payload.id
            ? { userrole, ...action.payload }
            : userrole
        ),
        addUpdateSuccess: true,
        error: {}
      }

    case UPDATE_USERROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_USERROLE_SUCCESS:
      return {
        ...state,
        userroleList: state.userroleList.filter(
          userrole => userrole.id !== action.userroleId
        ),
        deleteSuccess: true,
        error: {}
      }

    case DELETE_USERROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return { 
        ...state, 
        deleteSuccess: action.payload 
      };
    
    default:
      return state
  }
}

export default UserRole
