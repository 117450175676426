import {
  GET_INTERSTNFAVOURITE_FAIL,
  GET_INTERSTNFAVOURITE_SUCCESS,
  GET_INTERSTNFAVOURITE_BY_ID_SUCCESS,
  GET_INTERSTNFAVOURITE_BY_ID_FAIL,
  ADD_INTERSTNFAVOURITE_SUCCESS,
  ADD_INTERSTNFAVOURITE_FAIL,
  UPDATE_INTERSTNFAVOURITE_SUCCESS,
  UPDATE_INTERSTNFAVOURITE_FAIL,
  DELETE_INTERSTNFAVOURITE_SUCCESS,
  DELETE_INTERSTNFAVOURITE_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  interestnfavouriteList: [],
  interestnfavouriteById:[],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {}
}

const InterestNFavourite = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INTERSTNFAVOURITE_SUCCESS:
      return {
        ...state,
        interestnfavouriteList: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {}
      }

    case GET_INTERSTNFAVOURITE_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }

    case GET_INTERSTNFAVOURITE_BY_ID_SUCCESS:
      return {
        ...state,
        interestnfavouriteById: action.payload,
        error: {}
      }

    case GET_INTERSTNFAVOURITE_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        interestnfavouriteById: [],
      }

    case ADD_INTERSTNFAVOURITE_SUCCESS:
      return {
        ...state,
        interestnfavouriteList: [...state.interestnfavouriteList, action.payload],
        addUpdateSuccess: true,
        error: {}
      }

    case ADD_INTERSTNFAVOURITE_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_INTERSTNFAVOURITE_SUCCESS:
      return {
        ...state,
        interestnfavouriteList: state.interestnfavouriteList.map(interestnfavourite =>
          interestnfavourite.id === action.payload.id
            ? { interestnfavourite, ...action.payload }
            : interestnfavourite
        ),
        addUpdateSuccess: true,
        error: {}
      }

    case UPDATE_INTERSTNFAVOURITE_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_INTERSTNFAVOURITE_SUCCESS:
      return {
        ...state,
        interestnfavouriteList: state.interestnfavouriteList.filter(
          interestnfavourite => interestnfavourite.id !== action.interestnfavouriteId
        ),
        deleteSuccess: true,
        error: {}
      }

    case DELETE_INTERSTNFAVOURITE_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return { 
        ...state, 
        deleteSuccess: action.payload 
      };
    
    default:
      return state
  }
}

export default InterestNFavourite
