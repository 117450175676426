import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "react-draft-wysiwyg"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const AddCommHighlights = () => {
 // const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const [selectedFiles, setselectedFiles] = useState([]);

  //const startDateChange = date => {
    //setstartDate(date);
  //};

  const endDateChange = date => {
    setendDate(date);
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );

    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Highlights</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Highlights" breadcrumbItem="Add Highlights" />
            <Row>
                <Col xs="12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between float-right">
                        <Button
                        color="primary"
                        className="btn-rounded "
                        >
                        Add Highlights
                        </Button>
                    </div>    
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4">Add Highlights</CardTitle>
                            <Form>
                                <FormGroup className="select2-container mb-4" row>
                                    <Label className="col-form-label col-lg-12">
                                    Highlights Type
                                    </Label>
                                    <Col md="10">
                                    <select
                                        className="form-control select2"
                                        title="Highlights"
                                    >
                                        <option value="0">Select</option>
                                        <option value="Online">Online</option>
                                        <option value="Offline">Offline</option>
                                        <option value="Communication">Communication</option>
                                    </select>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="select2-container mb-4" row>
                                    <Label className="col-form-label col-lg-12">
                                    Type of Communication
                                    </Label>
                                    <Col md="10">
                                    <select
                                        className="form-control select2"
                                        title="Highlights"
                                    >
                                        <option value="0">Select</option>
                                        <option value="Research">Research</option>
                                        <option value="Read">Read</option>
                                        <option value="Important">Important</option>
                                    </select>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label className="col-form-label col-lg-12">
                                    Photograph
                                    </Label>
                                    <Col lg="10">
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles);
                                        }}
                                        >
                                        {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Drop files here or click to upload.</h4>
                                                </div>
                                            </div>
                                            </div>
                                        )}
                                        </Dropzone>
                                        <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                        >
                                        {selectedFiles.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            );
                                        })}
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="title"
                                        className="col-form-label col-lg-12"
                                    >
                                        Title
                                    </Label>
                                    <Col lg="10">
                                        <Input
                                        id="title"
                                        name="title"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter title..."
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label className="col-form-label col-lg-12">
                                        Date
                                    </Label>
                                    <Col lg="10">
                                        <Row>
                                            <Col md={6} className="pl-0">
                                                {" "}
                                                <DatePicker
                                                className="form-control"
                                                selected={endDate}
                                                onChange={endDateChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="desc"
                                        className="col-form-label col-lg-12"
                                    >
                                        Description
                                    </Label>
                                    <Col lg="10">
                                        <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        placeholder="Place Your Content Here..."
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="query"
                                        className="col-form-label col-lg-12"
                                    >
                                        For any queries
                                    </Label>
                                    <Col lg="10">
                                        <textarea
                                        className="form-control"
                                        id="query"
                                        rows="2"
                                        placeholder="Enter Description..."
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="select2-container mb-4" row>
                                    <Label className="col-form-label col-lg-12">
                                    User Category
                                    </Label>
                                    <Col md="10">
                                    <select
                                        className="form-control select2"
                                        title="User Category"
                                    >
                                        <option value="0">Select</option>
                                        <option value="Category1">Advisor</option>
                                        <option value="Category2">Investor</option>
                                    </select>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="instruction"
                                        className="col-form-label col-lg-12"
                                    >
                                        Instruction to dev
                                    </Label>
                                    <Col lg="10">
                                        <textarea
                                        className="form-control"
                                        id="instruction"
                                        rows="2"
                                        placeholder="Enter Description..."
                                        />
                                    </Col>
                                </FormGroup>
                            </Form>
                        
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCommHighlights;
