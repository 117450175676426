/* INTERSTNFAVOURITE */
export const GET_INTERSTNFAVOURITE = "GET_INTERSTNFAVOURITE"
export const GET_INTERSTNFAVOURITE_SUCCESS = "GET_INTERSTNFAVOURITE_SUCCESS"
export const GET_INTERSTNFAVOURITE_FAIL = "GET_INTERSTNFAVOURITE_FAIL"


export const GET_INTERSTNFAVOURITE_BY_ID = "GET_INTERSTNFAVOURITE_BY_ID"
export const GET_INTERSTNFAVOURITE_BY_ID_SUCCESS = "GET_INTERSTNFAVOURITE_BY_ID_SUCCESS"
export const GET_INTERSTNFAVOURITE_BY_ID_FAIL = "GET_INTERSTNFAVOURITE_BY_ID_FAIL"
/**
 * add INTERSTNFAVOURITE
 */
export const ADD_INTERSTNFAVOURITE = "ADD_INTERSTNFAVOURITE"
export const ADD_INTERSTNFAVOURITE_SUCCESS = "ADD_INTERSTNFAVOURITE_SUCCESS"
export const ADD_INTERSTNFAVOURITE_FAIL = "ADD_INTERSTNFAVOURITE_FAIL"

/**
 * Edit INTERSTNFAVOURITE
 */
export const UPDATE_INTERSTNFAVOURITE = "UPDATE_INTERSTNFAVOURITE"
export const UPDATE_INTERSTNFAVOURITE_SUCCESS = "UPDATE_INTERSTNFAVOURITE_SUCCESS"
export const UPDATE_INTERSTNFAVOURITE_FAIL = "UPDATE_INTERSTNFAVOURITE_FAIL"

/**
 * Delete INTERSTNFAVOURITE
 */
export const DELETE_INTERSTNFAVOURITE = "DELETE_INTERSTNFAVOURITE"
export const DELETE_INTERSTNFAVOURITE_SUCCESS = "DELETE_INTERSTNFAVOURITE_SUCCESS"
export const DELETE_INTERSTNFAVOURITE_FAIL = "DELETE_INTERSTNFAVOURITE_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';
