/* USERROLE */
export const GET_USERROLE = "GET_USERROLE"
export const GET_USERROLE_SUCCESS = "GET_USERROLE_SUCCESS"
export const GET_USERROLE_FAIL = "GET_USERROLE_FAIL"


export const GET_USERROLE_BY_ID = "GET_USERROLE_BY_ID"
export const GET_USERROLE_BY_ID_SUCCESS = "GET_USERROLE_BY_ID_SUCCESS"
export const GET_USERROLE_BY_ID_FAIL = "GET_USERROLE_BY_ID_FAIL"
/**
 * add USERROLE
 */
export const ADD_USERROLE = "ADD_USERROLE"
export const ADD_USERROLE_SUCCESS = "ADD_USERROLE_SUCCESS"
export const ADD_USERROLE_FAIL = "ADD_USERROLE_FAIL"

/**
 * Edit USERROLE
 */
export const UPDATE_USERROLE = "UPDATE_USERROLE"
export const UPDATE_USERROLE_SUCCESS = "UPDATE_USERROLE_SUCCESS"
export const UPDATE_USERROLE_FAIL = "UPDATE_USERROLE_FAIL"

/**
 * Delete USERROLE
 */
export const DELETE_USERROLE = "DELETE_USERROLE"
export const DELETE_USERROLE_SUCCESS = "DELETE_USERROLE_SUCCESS"
export const DELETE_USERROLE_FAIL = "DELETE_USERROLE_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';
