/* HIGHLIGHTS */
export const GET_HIGHLIGHTS = "GET_HIGHLIGHTS"
export const GET_HIGHLIGHTS_SUCCESS = "GET_HIGHLIGHTS_SUCCESS"
export const GET_HIGHLIGHTS_FAIL = "GET_HIGHLIGHTS_FAIL"

export const GET_HIGHLIGHTS_BY_ID = "GET_HIGHLIGHTS_BY_ID"
export const GET_HIGHLIGHTS_BY_ID_SUCCESS = "GET_HIGHLIGHTS_BY_ID_SUCCESS"
export const GET_HIGHLIGHTS_BY_ID_FAIL = "GET_HIGHLIGHTS_BY_ID_FAIL"
/**
 * add HIGHLIGHTS
 */
export const ADD_NEW_HIGHLIGHTS = "ADD_NEW_HIGHLIGHTS"
export const ADD_HIGHLIGHTS_SUCCESS = "ADD_HIGHLIGHTS_SUCCESS"
export const ADD_HIGHLIGHTS_FAIL = "ADD_HIGHLIGHTS_FAIL"

/**
 * Edit HIGHLIGHTS
 */
export const UPDATE_HIGHLIGHTS = "UPDATE_HIGHLIGHTS"
export const UPDATE_HIGHLIGHTS_SUCCESS = "UPDATE_HIGHLIGHTS_SUCCESS"
export const UPDATE_HIGHLIGHTS_FAIL = "UPDATE_HIGHLIGHTS_FAIL"

/**
 * Delete HIGHLIGHTS
 */
export const DELETE_HIGHLIGHTS = "DELETE_HIGHLIGHTS"
export const DELETE_HIGHLIGHTS_SUCCESS = "DELETE_HIGHLIGHTS_SUCCESS"
export const DELETE_HIGHLIGHTS_FAIL = "DELETE_HIGHLIGHTS_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';