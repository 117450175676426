import {
  GET_COMPANY,
  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  ADD_NEW_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_COMPANY_BY_ID,
  GET_COMPANY_BY_ID_SUCCESS,
  GET_COMPANY_BY_ID_FAIL,
  RESET_COMPANY_BY_ID,
  UPDATE_DELETE_SUCCESS,
  DELETE_SECTION_ENTRY,
  DELETE_SECTION_ENTRY_SUCCESS,
  DELETE_SECTION_ENTRY_FAIL
} from "./actionTypes"

export const getCompany = () => ({
  type: GET_COMPANY
})

export const getCompanySuccess = Company => ({
  type: GET_COMPANY_SUCCESS,
  payload: Company,
})

export const getCompanyFail = error => ({
  type: GET_COMPANY_FAIL,
  payload: error,
})

export const getCompanyById = (companyId) => ({
  type: GET_COMPANY_BY_ID,
  payload: companyId,
})

export const getCompanyByIdSuccess = Company => ({
  type: GET_COMPANY_BY_ID_SUCCESS,
  payload: Company,
})

export const getCompanyByIdFail = error => ({
  type: GET_COMPANY_BY_ID_FAIL,
  payload: error,
})

export const resetCompanyByIdData = () => ({
  type: RESET_COMPANY_BY_ID,
})

export const addNewCompany = company => ({
  type: ADD_NEW_COMPANY,
  payload: company,
})

export const addCompanySuccess = company => ({
  type: ADD_COMPANY_SUCCESS,
  payload: company,
})

export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
})

export const updateCompany = company => ({
  type: UPDATE_COMPANY,
  payload: company,
})

export const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})

export const deleteCompany = company => ({
  type: DELETE_COMPANY,
  payload: company,
})

export const deleteCompanySuccess = (response, companyId) => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: response,
  companyId: companyId
})

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = (success) => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
});

export const deleteSectionEntry = (entryId, entryType) => ({
  type: DELETE_SECTION_ENTRY,
  entryId: entryId,
  entryType: entryType
})

export const deleteSectionEntrySuccess = (response) => ({
  type: DELETE_SECTION_ENTRY_SUCCESS,
  payload: response
})

export const deleteSectionEntryFail = error => ({
  type: DELETE_SECTION_ENTRY_FAIL,
  payload: error,
})