import React, {useState} from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

const ScheduleContent = () => {
  const [users, setUsers] = useState([
    {
      id: "customCheck2",
      customerName: "Neal Matthews",
      mobileNo: "9999999999",
      emailId: "neal.matthews@test.com",
      type: "Investor",
    },
    {
      id: "customCheck3",
      customerName: "Jamal Burnett",
      mobileNo: "9999999988",
      emailId: "Jamal.Burnett@test.com",
      type: "Investor",
    },
    {
      id: "customCheck4",
      customerName: "Juan Mitchell",
      mobileNo: "9999999977",
      emailId: "Juan.Mitchell@test.com",
      type: "Advisor",
    },
    {
      id: "customCheck5",
      customerName: "Barry Dick",
      mobileNo: "9999999966",
      emailId: "Barry.Dick@test.com",
      type: "Investor",
    },
    {
      id: "customCheck6",
      customerName: "Ronald Taylor",
      mobileNo: "9999999955",
      emailId: "Ronald.Taylor@test.com",
      type: "Advisor",
    }
  ])

  const usersColumns = () => [
    {
      dataField: "customerName",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "mobileNo",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "emailId",
      text: "Email Id",
      sort: true,
    },
    {
      dataField: "type",
      text: "User Type",
      sort: true,
    },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {/* <CardTitle className="mb-4">Schedule Content</CardTitle> */}
          <Row>
            <Col xl="9">
              <div className="mb-4 h4 card-title">Schedule Content</div>
            </Col>
            <Col xl="3">
              <Link to="/#" color="link" className="btn btn-link btn-view-all">View All</Link>
            </Col>
          </Row>
          
          <ToolkitProvider
            keyField="id"
            data={users}
            columns={usersColumns()}
            bootstrap4
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRow}
                        classes={
                          "table align-middle table-nowrap table-check"
                        }
                        headerWrapperClasses={"table-light"}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ScheduleContent
