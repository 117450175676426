import Users from "pages/Users"
import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USERS_BY_ID,
  GET_USERS_BY_ID_SUCCESS,
  GET_USERS_BY_ID_FAIL,
  ADD_NEW_USERS,
  ADD_USERS_SUCCESS,
  ADD_USERS_FAIL,
  UPDATE_USERS,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAIL,
  DELETE_USERS,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  VERIFY_USERS,
  VERIFY_USERS_SUCCESS,
  VERIFY_USERS_FAIL,
  REJECT_USERS,
  REJECT_USERS_SUCCESS,
  REJECT_USERS_FAIL,
  GET_DELETED_USERS,
  GET_DELETED_USERS_FAIL,
  GET_DELETED_USERS_SUCCESS,
  DELETE_USERS_REQUEST,
  DELETE_USERS_REQUEST_SUCCESS,
  DELETE_USERS_REQUEST_FAIL,
  UPDATE_DELETE_SUCCESS,
  FREEZE_USERS,
  FREEZE_USERS_SUCCESS,
  FREEZE_USERS_FAIL,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = Users => ({
  type: GET_USERS_SUCCESS,
  payload: Users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUsersById = usersId => ({
  type: GET_USERS_BY_ID,
  payload: usersId,
})

export const getUsersByIdSuccess = Users => ({
  type: GET_USERS_BY_ID_SUCCESS,
  payload: Users,
})

export const getUsersByIdFail = error => ({
  type: GET_USERS_BY_ID_FAIL,
  payload: error,
})

export const addNewUsers = users => ({
  type: ADD_NEW_USERS,
  payload: users,
})

export const addUsersSuccess = users => ({
  type: ADD_USERS_SUCCESS,
  payload: users,
})

export const addUsersFail = error => ({
  type: ADD_USERS_FAIL,
  payload: error,
})

export const updateUsers = users => ({
  type: UPDATE_USERS,
  payload: users,
})

export const updateUsersSuccess = users => ({
  type: UPDATE_USERS_SUCCESS,
  payload: users,
})

export const updateUsersFail = error => ({
  type: UPDATE_USERS_FAIL,
  payload: error,
})

export const deleteUsers = users => ({
  type: DELETE_USERS,
  payload: users,
})

export const deleteUsersSuccess = (response, usersId) => ({
  type: DELETE_USERS_SUCCESS,
  payload: response,
  usersId: usersId,
})

export const deleteUsersFail = error => ({
  type: DELETE_USERS_FAIL,
  payload: error,
})

export const verifyUsers = users => ({
  type: VERIFY_USERS,
  payload: users,
})

export const verifyUsersSuccess = (response, usersId) => ({
  type: VERIFY_USERS_SUCCESS,
  payload: response,
})

export const verifyUsersFail = error => ({
  type: VERIFY_USERS_FAIL,
  payload: error,
})

export const rejectUsers = users => ({
  type: REJECT_USERS,
  payload: users,
})

export const rejectUsersSuccess = (response, usersId) => ({
  type: REJECT_USERS_SUCCESS,
  payload: response,
})

export const rejectUsersFail = error => ({
  type: REJECT_USERS_FAIL,
  payload: error,
})

export const getDeletedUsers = () => ({
  type: GET_DELETED_USERS,
})

export const getDeletedUsersSuccess = Users => ({
  type: GET_DELETED_USERS_SUCCESS,
  payload: Users,
})

export const getDeletedUsersFail = error => ({
  type: GET_DELETED_USERS_FAIL,
  payload: error,
})

export const deleteUserRequest = users => ({
  type: DELETE_USERS_REQUEST,
  payload: users,
})

export const deleteUsersRequestSuccess = (response, usersId) => ({
  type: DELETE_USERS_REQUEST_SUCCESS,
  payload: response,
  usersId: usersId,
})

export const deleteUsersRequestFail = error => ({
  type: DELETE_USERS_REQUEST_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = success => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
})

export const freezeUser = freezeObj => ({
  type: FREEZE_USERS,
  payload: freezeObj,
})

export const freezeUsersSuccess = (response, usersId) => ({
  type: FREEZE_USERS_SUCCESS,
  payload: response,
  usersId: usersId,
})

export const freezeUsersFail = error => ({
  type: FREEZE_USERS_FAIL,
  payload: error,
})
