/* TRANSACTION */
export const GET_TRANSACTION = "GET_TRANSACTION"
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS"
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL"

export const GET_TRANSACTION_BY_ID = "GET_TRANSACTION_BY_ID"
export const GET_TRANSACTION_BY_ID_SUCCESS = "GET_TRANSACTION_BY_ID_SUCCESS"
export const GET_TRANSACTION_BY_ID_FAIL = "GET_TRANSACTION_BY_ID_FAIL"

export const GET_ACTIVECHATREQUESTHISTORY_BY_ID =
  "GET_ACTIVECHATREQUESTHISTORY_BY_ID"
export const GET_ACTIVECHATREQUESTHISTORY_BY_ID_SUCCESS =
  "GET_ACTIVECHATREQUESTHISTORY_BY_ID_SUCCESS"
export const GET_ACTIVECHATREQUESTHISTORY_BY_ID_FAIL =
  "GET_ACTIVECHATREQUESTHISTORY_BY_ID_FAIL"

/**
 * add TRANSACTION
 */
export const ADD_NEW_TRANSACTION = "ADD_NEW_TRANSACTION"
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS"
export const ADD_TRANSACTION_FAIL = "ADD_TRANSACTION_FAIL"

/**
 * Edit TRANSACTION
 */
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION"
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS"
export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL"

/**
 * Delete TRANSACTION
 */
export const DELETE_TRANSACTION = "DELETE_TRANSACTION"
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS"
export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = "UPDATE_DELETE_SUCCESS"
