import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
//import DeleteModal from "../../../components/Common/DeleteModal";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import {
  getCompany as onGetCompany,
  deleteCompany as onDeleteCompany,
  updateDeleteSuccessFlag,
} from "store/company/actions"
import { toast } from "react-toastify"

const Companies = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { companies, error, deleteSuccess } = useSelector(state => ({
    companies: state.Company.company,
    error: state.Company.error,
    deleteSuccess: state.Company.deleteSuccess,
  }))
  const [companyList, setCompanyList] = useState([])
  const [selectedCompany, setSelectedCompany] = useState([])
  useEffect(() => {
    dispatch(onGetCompany())
  }, [])

  useEffect(() => {
    if (error?.status === 401) {
      history.push("/login")
    } else if (deleteSuccess) {
      toast.success(`Company deleted successfully`, { autoClose: 2000 })
    } else if (
      !deleteSuccess &&
      deleteSuccess !== null &&
      deleteSuccess !== undefined &&
      deleteSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
    dispatch(updateDeleteSuccessFlag(null))
  }, [error, deleteSuccess])

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = selectedCompany => {
    setSelectedCompany(selectedCompany)
    setDeleteModal(true)
  }

  const handleDeleteCompany = () => {
    if (selectedCompany.id) {
      dispatch(onDeleteCompany(selectedCompany.id))
      setDeleteModal(false)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setCompanyList(
      companies.filter(companies =>
        Object.keys(companies).some(key =>
          companies[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: companies.length, // replace later with size(orders),
    //totalSize: 10,
    custom: true,
  }

  const companyColumns = () => [
    {
      dataField: "id",
      text: "Company ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      // formatter: (cellContent, row) => (
      //   <Link to={`/company-details?id=${row.id}`} className="text-body fw-bold">
      //     {row.id}
      //   </Link>
      // ),
    },
    {
      dataField: "name",
      text: "Company Name",
      sort: true,
      filter: textFilter(),
    },
    /* {
      dataField: "otherdetail",
      text: "Description",
      sort: true,
    }, */
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, company) => (
        <>
          <div className="d-flex gap-3">
            <Link to={`/add-company?id=${company.id}`} className="text">
              <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View Details
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(company)}
            >
              <i className="bx bxs-trash font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCompany}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Companies</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Company" breadcrumbItem="Companies" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    {/* <Col sm="6">
                      <CardTitle className="mb-4">Companies</CardTitle>
                    </Col> */}
                    <Col sm="12">
                      <div className="text-sm-end">
                        <Link
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2 btn btn-primary"
                          //onClick={handleOrderClicks}
                          to="/add-company"
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add New Comapny
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={companyColumns()}
                    data={companies}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={companies || []}
                        columns={companyColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    onTableChange={handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                    filter={ filterFactory() }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Companies.propTypes = {
  companies: PropTypes.array,
  onGetCompany: PropTypes.func,
}

export default Companies
