import {
  GET_USERROLE,
  GET_USERROLE_FAIL,
  GET_USERROLE_SUCCESS,
  GET_USERROLE_BY_ID,
  GET_USERROLE_BY_ID_SUCCESS,
  GET_USERROLE_BY_ID_FAIL,
  ADD_USERROLE,
  ADD_USERROLE_SUCCESS,
  ADD_USERROLE_FAIL,
  UPDATE_USERROLE,
  UPDATE_USERROLE_SUCCESS,
  UPDATE_USERROLE_FAIL,
  DELETE_USERROLE,
  DELETE_USERROLE_SUCCESS,
  DELETE_USERROLE_FAIL,
  UPDATE_DELETE_SUCCESS
} from "./actionTypes"

export const getUserRole = () => ({
  type: GET_USERROLE,
})

export const getUserRoleSuccess = UserRole => ({
  type: GET_USERROLE_SUCCESS,
  payload: UserRole,
})

export const getUserRoleFail = error => ({
  type: GET_USERROLE_FAIL,
  payload: error,
})

export const getUserRoleById = (userroleId) => ({
  type: GET_USERROLE_BY_ID,
  payload: userroleId,
})

export const getUserRoleByIdSuccess = UserRole => ({
  type: GET_USERROLE_BY_ID_SUCCESS,
  payload: UserRole,
})

export const getUserRoleByIdFail = error => ({
  type: GET_USERROLE_BY_ID_FAIL,
  payload: error,
})

export const addNewUserRole = userrole => ({
  type: ADD_USERROLE,
  payload: userrole,
})

export const addUserRoleSuccess = userrole => ({
  type: ADD_USERROLE_SUCCESS,
  payload: userrole,
})

export const addUserRoleFail = error => ({
  type: ADD_USERROLE_FAIL,
  payload: error,
})

export const updateUserRole = userrole => ({
  type: UPDATE_USERROLE,
  payload: userrole,
})

export const updateUserRoleSuccess = userrole => ({
  type: UPDATE_USERROLE_SUCCESS,
  payload: userrole,
})

export const updateUserRoleFail = error => ({
  type: UPDATE_USERROLE_FAIL,
  payload: error,
})

export const deleteUserRole = userrole => ({
  type: DELETE_USERROLE,
  payload: userrole,
})

export const deleteUserRoleSuccess = (response, userroleId) => ({
  type: DELETE_USERROLE_SUCCESS,
  payload: response,
  userroleId: userroleId
})

export const deleteUserRoleFail = error => ({
  type: DELETE_USERROLE_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = (success) => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
});