import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
//import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";
//Company
import Company from "./company/reducer"
import DocumentUpload from "./document-upload/reducer"
import Common from "./common/reducer"
//Highlights
import Highlights from "./highlights/reducer"
//News
import News from "./news/reducer"
//Insights
import Insights from "./insights/reducer"
//Reports
import Reports from "./reports/reducer"
//Users
import Users from "./users/reducer"

import Interestnfavourite from "./interestnfavourite/reducer"

import Transaction from "./transaction/reducer"

import Portfolio from "./portfolio/reducer"

import Complaints from "./complaints/reducer"
import UserRole from "./userRole/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  //contacts,
  Dashboard,
  DashboardSaas,
  Company,
  DocumentUpload,
  Common,
  Highlights,
  News,
  Insights,
  Reports,
  Users,
  Interestnfavourite,
  Transaction,
  Portfolio,
  Complaints,
  UserRole
})

export default rootReducer
