import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"
import { Editor } from "react-draft-wysiwyg"
import { convertToRaw, ContentState, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  addNewInsights as onAddNewInsights,
  updateInsights as onUpdateInsights,
  getInsightsById as onGetInsightsById,
} from "store/insights/actions"
import { uploadDocument as uploadDocument } from "store/document-upload/actions"
import { toast } from "react-toastify"
import axios from "axios"
import { getAccessToken } from "helpers/jwt-token-access/accessToken"

const initialValues = {
  id: null,
  lang_id: null,
  file_url: "",
  file_type: "",
  file_name: "",
  tag: "",
  headline: "",
  insightDate: new Date(),
  description: "",
  targetUser: "",
  access_group: "",
  isNew: true,
  is_read: false,
  insightcode: "",
  addhome: "",
}

const validationSchema = Yup.object({
  headline: Yup.string().required("Please Enter News title"),
  file_url: Yup.mixed().required("Please upload file"),
  tag: Yup.string()
    .required("Please Select tag")
    .notOneOf(["-1"], "Please Select tag"),
  insightDate: Yup.date().required("Please Select Date"),
  description: Yup.string().required("Please Enter description"),
  targetUser: Yup.array().required("Please select target user"),
  access_group: Yup.array().when("targetUser", {
    is: targetUser =>
      targetUser?.length >= 1 &&
      targetUser?.some(
        item =>
          item["value"] === "individual_investor" ||
          item["value"] === "legalrepresent"
      ), // Condition: apply validation only if eventType is 'online'
    then: Yup.array().required("Please select user access level"),
    otherwise: Yup.array(), // No validation rule when condition is not met
  }),
  addhome: Yup.string().required("Please select add to home"),
})

const AddInsights = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const insightId = searchParams.get("insightcode")
  const language = JSON.parse(localStorage.getItem("language"))
  const [activeTab, setActiveTab] = useState(language[0].id)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedInsights, setSelectedInsights] = useState({})
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedFiles3, setSelectedFiles3] = useState([])
  const [basicUserFlag, setBasicUserFlag] = useState(false)

  const [insightList, setInsightList] = useState([])

  const optionGroup1 = [
    { label: "Individual Investor", value: "individual_investor" },
    { label: "Advisor", value: "advisor" },
    { label: "Legal Representative", value: "legalrepresent" },
    { label: "Basic", value: "basic" },
  ]
  const userlevelOption = [
    { label: "Intermediate", value: "intermediate" },
    { label: "Advanced", value: "advanced" },
  ]

  const { insightsById, error, addUpdateSuccess, deleteSuccess, document } =
    useSelector(state => ({
      insightsById: state.Insights.insightsById,
      error: state.Insights.error,
      addUpdateSuccess: state.Insights.addUpdateSuccess,
      deleteSuccess: state.Insights.deleteSuccess,
      document: state.DocumentUpload.document,
    }))

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
    else if (addUpdateSuccess) {
      toast.success(`Insights ${isEdit ? "updated" : "added"} successfully`, {
        autoClose: 2000,
      })
      validation.resetForm()
      validation3.resetForm()
      history.push("/templates")
    } else if (
      !addUpdateSuccess &&
      addUpdateSuccess !== null &&
      addUpdateSuccess !== undefined &&
      addUpdateSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
  }, [error, addUpdateSuccess])

  /* useEffect(() => {
    if ((insightId !== null && insightId !== undefined) && insightList.length <= 0) {
      //call api to get user data;
      dispatch(onGetInsightsById(insightId))
    //} else if (usersList?.length <= 0 && usersId !== null) {
    } else if(insightList.length > 0 && insightId !== null) {  
      //call api to get users data;
      setSelectedInsights(insightList[0])
      setIsEdit(true)
      assignInitialValues(insightList[0])
    }
  }, [insightId, insightList]) */

  useEffect(() => {
    if (insightId !== null && insightId !== undefined) {
      //call api to get company data;
      let insightObj = {
        insightcode: insightId,
        lang_id: activeTab === 1 ? language[1].id : language[0].id,
      }
      // console.log(
      //   activeTab,
      //   insightObj,
      //   getNewsById(insightObj),
      //   "News obj and active tab"
      // )
      axios
        .get(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/auth/insight?insightcode=" +
            insightObj.insightcode +
            "&lang_id=" +
            insightObj.lang_id,
          { headers: { Authorization: getAccessToken() } }
        )
        .then(res => {
          setInsightList(res.data.data)
        })
      // dispatch(getNewsById(insightObj))
    }
  }, [activeTab])

  useEffect(() => {
    if (insightList.length > 0 && insightId !== null) {
      setSelectedInsights(insightList[0])
      setIsEdit(true)
      if (insightList[0]?.lang_id == 1) assignInitialValues(insightList[0])
      else assignInitialValues3(insightList[0])
    }
  }, [insightList])

  const assignInitialValues = insight => {
    validation.setFieldValue("lang_id", insight?.lang_id)
    // setActiveTab(insight?.lang_id)
    validation.setFieldValue("file_url", insight?.file_url)
    validation.setFieldValue("file_name", insight?.file_name)
    validation.setFieldValue("file_type", insight?.file_type)
    validation.setFieldValue("tag", insight?.tag)
    validation.setFieldValue("headline", insight?.headline)
    validation.setFieldValue("insightDate", new Date(insight?.insightDate))
    validation.setFieldValue("description", insight?.description)
    validation.setFieldValue("isNew", false)
    validation.setFieldValue("is_read", insight?.insightis_readcode)
    validation.setFieldValue("insightcode", insight?.insightcode)
    validation.setFieldValue("id", insight.id)
    validation.setFieldValue("addhome", insight.addhome)

    // access group prefill
    if (insight.access_group !== "" && insight.access_group !== null) {
      let tempArr1 = insight.access_group.split(",")
      let tempaccess_groupArr = []
      tempArr1.forEach(element => {
        let tempObj = { label: element, value: element }
        tempaccess_groupArr.push(tempObj)
      })
      if (tempaccess_groupArr.length > 0)
        validation.setFieldValue("access_group", tempaccess_groupArr)
    }

    // target user prefill
    if (insight.targetUser !== "" && insight.targetUser !== null) {
      let tempArr = insight.targetUser.split(",")
      let tempTargetUserArr = []
      tempArr.forEach(element => {
        let objIndex = findIndexByPropertyValue(optionGroup1, "value", element)
        tempTargetUserArr.push(optionGroup1[objIndex])
      })
      if (tempTargetUserArr.length > 0) {
        validation.setFieldValue("targetUser", tempTargetUserArr)
        if (
          tempTargetUserArr.length >= 1 &&
          !tempTargetUserArr.some(
            item =>
              item["value"] === "individual_investor" ||
              item["value"] === "legalrepresent"
          )
        ) {
          validation.setFieldValue("access_group", "")
          setBasicUserFlag(true)
        } else {
          setBasicUserFlag(false)
        }
      }
    }

    setDescriptionEditorState(prevState => ({
      ...prevState,
      editorValue: getContentBlock(insight?.description),
      id: insight.id,
      insightid: insight?.id,
      value: insight?.value,
    }))

    getImageFileFromUrl(insight?.file_url, insight?.file_name).then(file => {
      if (file) {
        let temp = [
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            link: insight?.file_url,
          }),
        ]
        setSelectedFiles(temp)
      }
    })
    validation.setFieldValue("file_url", insight?.file_url)
  }

  const assignInitialValues3 = insight => {
    validation3.setFieldValue("lang_id", insight?.lang_id)
    // setActiveTab(insight?.lang_id)
    validation3.setFieldValue("file_url", insight?.file_url)
    validation3.setFieldValue("file_name", insight?.file_name)
    validation3.setFieldValue("file_type", insight?.file_type)
    validation3.setFieldValue("tag", insight?.tag)
    validation3.setFieldValue("headline", insight?.headline)
    validation3.setFieldValue("insightDate", new Date(insight?.insightDate))
    validation3.setFieldValue("description", insight?.description)
    validation3.setFieldValue("isNew", false)
    validation3.setFieldValue("is_read", insight?.insightis_readcode)
    validation3.setFieldValue("insightcode", insight?.insightcode)
    validation3.setFieldValue("id", insight.id)
    validation3.setFieldValue("addhome", insight.addhome)

    // access group prefill
    if (insight.access_group !== "" && insight.access_group !== null) {
      let tempArr1 = insight.access_group.split(",")
      let tempaccess_groupArr = []
      tempArr1.forEach(element => {
        let tempObj = { label: element, value: element }
        tempaccess_groupArr.push(tempObj)
      })
      if (tempaccess_groupArr.length > 0)
        validation3.setFieldValue("access_group", tempaccess_groupArr)
    }

    // target user prefill
    if (insight.targetUser !== "" && insight.targetUser !== null) {
      let tempArr = insight.targetUser.split(",")
      let tempTargetUserArr = []
      tempArr.forEach(element => {
        let objIndex = findIndexByPropertyValue(optionGroup1, "value", element)
        tempTargetUserArr.push(optionGroup1[objIndex])
      })
      if (tempTargetUserArr.length > 0) {
        validation3.setFieldValue("targetUser", tempTargetUserArr)
        if (
          tempTargetUserArr.length >= 1 &&
          !tempTargetUserArr.some(
            item =>
              item["value"] === "individual_investor" ||
              item["value"] === "legalrepresent"
          )
        ) {
          validation3.setFieldValue("access_group", "")
          setBasicUserFlag(true)
        } else {
          setBasicUserFlag(false)
        }
      }
    }

    setDescriptionEditorState3(prevState => ({
      ...prevState,
      editorValue: getContentBlock(insight?.description),
      id: insight.id,
      insightid: insight?.id,
      value: insight?.value,
    }))

    getImageFileFromUrl(insight?.file_url, insight?.file_name).then(file => {
      if (file) {
        let temp = [
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            link: insight?.file_url,
          }),
        ]
        setSelectedFiles3(temp)
      }
    })
    validation3.setFieldValue("file_url", insight?.file_url)
  }

  const findIndexByPropertyValue = (array, property, value) => {
    return array.findIndex(obj => obj[property] === value)
  }

  const getContentBlock = htmlContent => {
    const contentBlock = htmlToDraft(htmlContent)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const newEditorState = EditorState.createWithContent(contentState)
      return newEditorState
    }
    return ""
  }

  function handleAcceptedFiles(files) {
    console.log("files[0]", files[0])
    dispatch(uploadDocument(files[0]))
    // validation.setFieldValue(
    //   "file_url",
    //   "https://ucarecdn.com/b223131b-a0a0-4812-9805-0029fe27c169/banyan_logo.png"
    // )
    let testFileName = files[0].name
    let testFileNameSplit = testFileName.split(".")
    if (activeTab === 1) {
      validation.setFieldValue("file_name", testFileName)
      validation.setFieldValue(
        "file_type",
        testFileNameSplit[testFileNameSplit.length - 1]
      )
    } else {
      validation3.setFieldValue("file_name", testFileName)
      validation3.setFieldValue(
        "file_type",
        testFileNameSplit[testFileNameSplit.length - 1]
      )
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    activeTab === 1 ? setSelectedFiles(files) : setSelectedFiles3(files)
  }

  useEffect(() => {
    console.log("document", document)
    if (document !== undefined) {
      activeTab === 1
        ? validation.setFieldValue("file_url", document?.fileurl)
        : validation3.setFieldValue("file_url", document?.fileurl)
    }
  }, [document])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [descriptionEditorState, setDescriptionEditorState] = useState(
    EditorState.createEmpty()
  )
  const [descriptionEditorState3, setDescriptionEditorState3] = useState(
    EditorState.createEmpty()
  )
  const handleEditorChange = (newEditorState, fieldName) => {
    //setDescriptionEditorState(newEditorState)
    const currentContent = convertToRaw(newEditorState.getCurrentContent())
    const isEmpty = currentContent?.blocks[0]?.text === ""
    // const html = draftToHtml(rawContentState);
    if (activeTab === 1) {
      validation.setFieldValue(
        fieldName,
        isEmpty ? "" : draftToHtml(currentContent)
      )
      validation.setFieldTouched(fieldName, true)

      setDescriptionEditorState(prevState => ({
        ...prevState,
        editorValue: newEditorState,
        value: isEmpty ? "" : draftToHtml(currentContent),
      }))
    } else {
      validation3.setFieldValue(
        fieldName,
        isEmpty ? "" : draftToHtml(currentContent)
      )
      validation3.setFieldTouched(fieldName, true)

      setDescriptionEditorState3(prevState => ({
        ...prevState,
        editorValue: newEditorState,
        value: isEmpty ? "" : draftToHtml(currentContent),
      }))
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      console.log("values", values)
      let targetUserString = ""
      if (values["targetUser"] !== "" && values["targetUser"].length > 0) {
        values["targetUser"].forEach(element => {
          targetUserString +=
            targetUserString === "" ? element.value : `,${element.value}`
        })
      }
      let access_groupString = ""
      if (values["access_group"] !== "" && values["access_group"].length > 0) {
        values["access_group"].forEach(element => {
          access_groupString +=
            access_groupString === "" ? element.value : `,${element.value}`
        })
      }
      let payload = []
      payload.push({ ...values })
      payload[0].targetUser = targetUserString
      if (
        !targetUserString.includes("individual_investor") ||
        !targetUserString.includes("legalrepresent")
      )
        payload[0].access_group = null
      else payload[0].access_group = access_groupString
      payload[0].lang_id = activeTab
      if (isEdit) {
        if (insightList[0].lang_id !== activeTab) {
          payload[0].id = null
        } else {
          payload[0].id = insightList[0].id
        }
        console.log("updateInsightPayload", payload)
        // update company
        dispatch(onUpdateInsights(payload))
        // history.push('/templates');
      } else {
        console.log("newinsightPayload", payload)
        // save new customer
        dispatch(onAddNewInsights(payload))
        validation.resetForm()
        // history.push('/templates');
      }
    },
  })

  const validation3 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      console.log("values", values)
      let targetUserString = ""
      if (values["targetUser"] !== "" && values["targetUser"].length > 0) {
        values["targetUser"].forEach(element => {
          targetUserString +=
            targetUserString === "" ? element.value : `,${element.value}`
        })
      }
      let access_groupString = ""
      if (values["access_group"] !== "" && values["access_group"].length > 0) {
        values["access_group"].forEach(element => {
          access_groupString +=
            access_groupString === "" ? element.value : `,${element.value}`
        })
      }
      let payload = []
      payload.push({ ...values })
      payload[0].targetUser = targetUserString
      if (
        !targetUserString.includes("individual_investor") ||
        !targetUserString.includes("legalrepresent")
      )
        payload[0].access_group = null
      else payload[0].access_group = access_groupString
      payload[0].lang_id = activeTab
      if (isEdit) {
        console.log("updateInsightPayload", payload)
        if (insightList[0].lang_id !== activeTab) {
          payload[0].id = null
        } else {
          payload[0].id = insightList[0].id
        }
        // update company
        dispatch(onUpdateInsights(payload))
        // history.push('/templates');
      } else {
        console.log("newinsightPayload", payload)
        // save new customer
        dispatch(onAddNewInsights(payload))
        validation3.resetForm()
        // history.push('/templates');
      }
    },
  })

  useEffect(() => {
    if (
      validation?.values["lang_id"] === undefined ||
      validation?.values["lang_id"] === null
    )
      validation.setFieldValue("lang_id", language[0].id)

    if (
      validation3?.values["lang_id"] === undefined ||
      validation3?.values["lang_id"] === null
    )
      validation3.setFieldValue("lang_id", language[0].id)
  }, [])

  // useEffect(() => {
  //   console.log("formik obj", validation)
  // })

  async function getImageFileFromUrl(imageUrl, fileName) {
    try {
      // Fetch image data
      const response = await fetch(imageUrl)
      const blob = await response.blob()

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: blob.type })

      return file
    } catch (error) {
      console.error("Error fetching image:", error)
      return null
    }
  }
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Insights</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {isEdit ? (
            <Breadcrumbs title="Templates" breadcrumbItem="Edit Insights" />
          ) : (
            <Breadcrumbs title="Templates" breadcrumbItem="Add Insights" />
          )}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified mb-2">
                    {language.map((option, index) => (
                      <NavItem key={index} value={option.id}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === option.id,
                          })}
                          onClick={() => {
                            toggle(option.id)
                          }}
                        >
                          {option.language_name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId={1} key={1} value={1}>
                      <Form onSubmit={validation.handleSubmit}>
                        {/* <FormGroup className="select2-container mb-4" row>
                                <Label className="col-form-label col-lg-12">
                                  Language
                                </Label>
                                <Col md="10">
                                  <select
                                    className={`form-control select2`}
                                    title="Language"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    name="lang_id"
                                    value={validation.values.lang_id || ""}
                                    placeholder="select language"
                                  >
                                    {language.map((option, index) => (
                                      <option key={index} value={option.id}>
                                        {option.language_name}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </FormGroup> */}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Show on Home Page
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation.touched.addhome &&
                                validation.errors.addhome
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Highlights"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="addhome"
                              value={
                                validation.values.addhome === 1
                                  ? true
                                  : validation.values.addhome === 0
                                  ? false
                                  : validation.values.addhome
                              }
                              aria-invalid={
                                validation.touched.addhome &&
                                validation.errors.addhome
                                  ? true
                                  : false
                              }
                              placeholder="select Highlight type"
                            >
                              <option value="-1">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            {validation.touched.addhome &&
                            validation.errors.addhome ? (
                              <FormFeedback type="invalid">
                                {validation.errors.addhome}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Category
                          </Label>
                          <Col
                            md="10"
                            className={`${
                              validation.touched.tag && validation.errors.tag
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <select
                              className={`form-control select2 ${
                                validation.touched.tag && validation.errors.tag
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Chip"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              name="tag"
                              value={validation.values.tag || ""}
                              aria-invalid={
                                validation.touched.tag && validation.errors.tag
                                  ? true
                                  : false
                              }
                            >
                              <option value="-1">Select</option>
                              <option value="Blog">Blog</option>
                              <option value="Newsletter">Newsletter</option>
                              {/*  <option value="News">News </option> */}
                            </select>
                          </Col>
                          {validation.touched.tag && validation.errors.tag ? (
                            <FormFeedback type="invalid">
                              {validation.errors.tag}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Date
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col className="pl-0">
                                {/* {" "}
                                      <DatePicker
                                        className="form-control"
                                        name="insightDate"
                                        onChange={(newDate) => {
                                          validation.setFieldValue('insightDate', new Date(newDate))
                                        }}
                                        selected={validation.values.insightDate}
                                        invalid={
                                          validation.touched.insightDate &&
                                          validation.errors.insightDate
                                            ? true
                                            : false
                                        }
                                      /> */}
                                <Input
                                  className="form-control"
                                  type="date"
                                  defaultValue="2019-08-19"
                                  id="insightDate"
                                  name="insightDate"
                                  onChange={event => {
                                    validation.setFieldValue(
                                      "insightDate",
                                      new Date(event.target.value)
                                    )
                                  }}
                                  selected={validation.values.insightDate}
                                  invalid={
                                    validation.touched.insightDate &&
                                    validation.errors.insightDate
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              {validation.touched.insightDate &&
                              validation.errors.insightDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.insightDate}
                                </FormFeedback>
                              ) : null}
                            </Row>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="headline"
                            className="col-form-label col-lg-12"
                          >
                            Title
                          </Label>
                          <Col
                            lg="10"
                            className={`${
                              validation.touched.headline &&
                              validation.errors.headline
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <Input
                              id="title"
                              name="headline"
                              type="text"
                              className="form-control"
                              placeholder="Enter title..."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.headline}
                              invalid={
                                validation.touched.headline &&
                                validation.errors.headline
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          {validation.touched.headline &&
                          validation.errors.headline ? (
                            <FormFeedback type="invalid">
                              {validation.errors.headline}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="desc"
                            className="col-form-label col-lg-12"
                          >
                            Description
                          </Label>
                          <Col
                            lg="10"
                            className={`${
                              validation.touched.description &&
                              validation.errors.description
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Place Your Content Here..."
                              id="description"
                              name="description"
                              editorState={descriptionEditorState.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(
                                  newEditorState,
                                  "description"
                                )
                              }
                              value={validation.values.description || ""}
                            />
                          </Col>
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            User Category
                          </Label>
                          <Col
                            md="10"
                            className={`${
                              validation.touched.targetUser &&
                              validation.errors.targetUser
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <div className={``}>
                              <Select
                                isMulti={true}
                                options={optionGroup1}
                                classNamePrefix="select2-selection"
                                id="targetUser"
                                name="targetUser"
                                type="select"
                                onChange={(option, e) => {
                                  if (
                                    option.length >= 1 &&
                                    !option.some(
                                      item =>
                                        item["value"] ===
                                          "individual_investor" ||
                                        item["value"] === "legalrepresent"
                                    )
                                  ) {
                                    validation.setFieldValue("access_group", "")
                                    setBasicUserFlag(true)
                                  } else {
                                    setBasicUserFlag(false)
                                  }
                                  validation.handleChange("targetUser", option)
                                  validation.setFieldValue("targetUser", option)
                                }}
                                value={validation.values.targetUser}
                              />
                            </div>
                          </Col>
                          {validation.touched.targetUser &&
                          validation.errors.targetUser ? (
                            <FormFeedback type="invalid">
                              {validation.errors.targetUser}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {!basicUserFlag && (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              User Access level
                            </Label>
                            <Col
                              md="10"
                              className={`${
                                validation.touched.access_group &&
                                validation.errors.access_group
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <div className={``}>
                                <Select
                                  isMulti={true}
                                  options={userlevelOption}
                                  classNamePrefix="select2-selection"
                                  id="access_group"
                                  name="access_group"
                                  type="select"
                                  onChange={(option, e) => {
                                    //console.log("option", option)
                                    validation.handleChange(
                                      "access_group",
                                      option
                                    )
                                    validation.setFieldValue(
                                      "access_group",
                                      option
                                    )
                                  }}
                                  value={validation.values.access_group}
                                />
                              </div>
                            </Col>
                            {validation.touched.access_group &&
                            validation.errors.access_group ? (
                              <FormFeedback type="invalid">
                                {validation.errors.access_group}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        )}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Upload Document
                          </Label>
                          <Col lg="10">
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className={`dropzone ${
                                    validation.touched.file_url &&
                                    validation.errors.file_url
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input
                                      name="file_url"
                                      onChange={validation.handleChange}
                                      {...getInputProps()}
                                    />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <a
                                            href={f.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </a>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                            {validation.touched.file_url &&
                            validation.errors.file_url ? (
                              <FormFeedback type="invalid">
                                {validation.errors.file_url}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <Row>
                          <Col xs="12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                              <Button
                                color="primary"
                                type="submit"
                                className="btn-rounded p-4 pb-2 pt-2"
                              >
                                Save
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={3} key={3} value={3}>
                      <Form onSubmit={validation3.handleSubmit}>
                        {/* <FormGroup className="select2-container mb-4" row>
                                <Label className="col-form-label col-lg-12">
                                  Language
                                </Label>
                                <Col md="10">
                                  <select
                                    className={`form-control select2`}
                                    title="Language"
                                    onChange={validation3.handleChange}
                                    onBlur={validation3.handleBlur}
                                    name="lang_id"
                                    value={validation3.values.lang_id || ""}
                                    placeholder="select language"
                                  >
                                    {language.map((option, index) => (
                                      <option key={index} value={option.id}>
                                        {option.language_name}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </FormGroup> */}
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Show on Home Page
                          </Label>
                          <Col md="10">
                            <select
                              className={`form-control select2 ${
                                validation3.touched.addhome &&
                                validation3.errors.addhome
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Highlights"
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              name="addhome"
                              value={
                                validation3.values.addhome === 1
                                  ? true
                                  : validation3.values.addhome === 0
                                  ? false
                                  : validation3.values.addhome
                              }
                              aria-invalid={
                                validation3.touched.addhome &&
                                validation3.errors.addhome
                                  ? true
                                  : false
                              }
                              placeholder="select Highlight type"
                            >
                              <option value="-1">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            {validation3.touched.addhome &&
                            validation3.errors.addhome ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.addhome}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Category
                          </Label>
                          <Col
                            md="10"
                            className={`${
                              validation3.touched.tag && validation3.errors.tag
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <select
                              className={`form-control select2 ${
                                validation3.touched.tag &&
                                validation3.errors.tag
                                  ? "is-invalid"
                                  : ""
                              }`}
                              title="Chip"
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              name="tag"
                              value={validation3.values.tag || ""}
                              aria-invalid={
                                validation3.touched.tag &&
                                validation3.errors.tag
                                  ? true
                                  : false
                              }
                            >
                              <option value="-1">Select</option>
                              <option value="Blog">Blog</option>
                              <option value="Newsletter">Newsletter</option>
                              {/*  <option value="News">News </option> */}
                            </select>
                          </Col>
                          {validation3.touched.tag && validation3.errors.tag ? (
                            <FormFeedback type="invalid">
                              {validation3.errors.tag}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Date
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col className="pl-0">
                                {/* {" "}
                                      <DatePicker
                                        className="form-control"
                                        name="insightDate"
                                        onChange={(newDate) => {
                                          validation3.setFieldValue('insightDate', new Date(newDate))
                                        }}
                                        selected={validation3.values.insightDate}
                                        invalid={
                                          validation3.touched.insightDate &&
                                          validation3.errors.insightDate
                                            ? true
                                            : false
                                        }
                                      /> */}
                                <Input
                                  className="form-control"
                                  type="date"
                                  defaultValue="2019-08-19"
                                  id="insightDate"
                                  name="insightDate"
                                  onChange={event => {
                                    validation3.setFieldValue(
                                      "insightDate",
                                      new Date(event.target.value)
                                    )
                                  }}
                                  selected={validation3.values.insightDate}
                                  invalid={
                                    validation3.touched.insightDate &&
                                    validation3.errors.insightDate
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              {validation3.touched.insightDate &&
                              validation3.errors.insightDate ? (
                                <FormFeedback type="invalid">
                                  {validation3.errors.insightDate}
                                </FormFeedback>
                              ) : null}
                            </Row>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="headline"
                            className="col-form-label col-lg-12"
                          >
                            Title
                          </Label>
                          <Col
                            lg="10"
                            className={`${
                              validation3.touched.headline &&
                              validation3.errors.headline
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <Input
                              id="title"
                              name="headline"
                              type="text"
                              className="form-control"
                              placeholder="Enter title..."
                              onChange={validation3.handleChange}
                              onBlur={validation3.handleBlur}
                              value={validation3.values.headline}
                              invalid={
                                validation3.touched.headline &&
                                validation3.errors.headline
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          {validation3.touched.headline &&
                          validation3.errors.headline ? (
                            <FormFeedback type="invalid">
                              {validation3.errors.headline}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="desc"
                            className="col-form-label col-lg-12"
                          >
                            Description
                          </Label>
                          <Col
                            lg="10"
                            className={`${
                              validation3.touched.description &&
                              validation3.errors.description
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Place Your Content Here..."
                              id="description"
                              name="description"
                              editorState={descriptionEditorState3.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(
                                  newEditorState,
                                  "description"
                                )
                              }
                              value={validation3.values.description || ""}
                            />
                          </Col>
                          {validation3.touched.description &&
                          validation3.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation3.errors.description}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>

                        <FormGroup className="select2-container mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            User Category
                          </Label>
                          <Col
                            md="10"
                            className={`${
                              validation3.touched.targetUser &&
                              validation3.errors.targetUser
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <div className={``}>
                              <Select
                                isMulti={true}
                                options={optionGroup1}
                                classNamePrefix="select2-selection"
                                id="targetUser"
                                name="targetUser"
                                type="select"
                                onChange={(option, e) => {
                                  if (
                                    option.length >= 1 &&
                                    !option.some(
                                      item =>
                                        item["value"] ===
                                          "individual_investor" ||
                                        item["value"] === "legalrepresent"
                                    )
                                  ) {
                                    validation3.setFieldValue(
                                      "access_group",
                                      ""
                                    )
                                    setBasicUserFlag(true)
                                  } else {
                                    setBasicUserFlag(false)
                                  }
                                  validation3.handleChange("targetUser", option)
                                  validation3.setFieldValue(
                                    "targetUser",
                                    option
                                  )
                                }}
                                value={validation3.values.targetUser}
                              />
                            </div>
                          </Col>
                          {validation3.touched.targetUser &&
                          validation3.errors.targetUser ? (
                            <FormFeedback type="invalid">
                              {validation3.errors.targetUser}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                        {!basicUserFlag && (
                          <FormGroup className="select2-container mb-4" row>
                            <Label className="col-form-label col-lg-12">
                              User Access level
                            </Label>
                            <Col
                              md="10"
                              className={`${
                                validation3.touched.access_group &&
                                validation3.errors.access_group
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <div className={``}>
                                <Select
                                  isMulti={true}
                                  options={userlevelOption}
                                  classNamePrefix="select2-selection"
                                  id="access_group"
                                  name="access_group"
                                  type="select"
                                  onChange={(option, e) => {
                                    //console.log("option", option)
                                    validation3.handleChange(
                                      "access_group",
                                      option
                                    )
                                    validation3.setFieldValue(
                                      "access_group",
                                      option
                                    )
                                  }}
                                  value={validation3.values.access_group}
                                />
                              </div>
                            </Col>
                            {validation3.touched.access_group &&
                            validation3.errors.access_group ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.access_group}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        )}
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Upload Document
                          </Label>
                          <Col lg="10">
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className={`dropzone ${
                                    validation3.touched.file_url &&
                                    validation3.errors.file_url
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input
                                      name="file_url"
                                      onChange={validation3.handleChange}
                                      {...getInputProps()}
                                    />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles3.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <a
                                            href={f.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </a>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                            {validation3.touched.file_url &&
                            validation3.errors.file_url ? (
                              <FormFeedback type="invalid">
                                {validation3.errors.file_url}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>
                        <Row>
                          <Col xs="12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                              <Button
                                color="primary"
                                type="submit"
                                className="btn-rounded p-4 pb-2 pt-2"
                              >
                                Save
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddInsights
