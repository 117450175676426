import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import { convertToRaw, ContentState, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  getUsers as onGetUsers,
  addNewUsers as onAddNewUsers,
  updateUsers as onUpdateUsers,
  deleteUsers as onDeleteUsers,
  getUsersById as onGetUsersById,
} from "store/users/actions"
import { uploadDocument as uploadDocument } from "store/document-upload/actions"

const initialValues = {
  lang_id: null,
  img: "",
  firstname: "",
  lastname: "",
  email: "",
  contact: "",
  password: "",
  countrycode: "",
  role: "",
  country: "",
  dateofbirth: new Date(),
  city: "",
  zipcode: "",
  gender: "",
  //linkDevice: "",
  isActive: false,
  id: null,
}

const validationSchema = Yup.object({
  firstname: Yup.string().required("Please Enter First name"),
  lastname: Yup.string().required("Please Enter First name"),
  //img: Yup.mixed().required("Please upload users picture"),
  role: Yup.string()
    .required("Please Select Role")
    .notOneOf(["-1"], "Please Select Role"),
  country: Yup.string()
    .required("Please Select Country")
    .notOneOf(["-1"], "Please Select Country"),
  gender: Yup.string()
    .required("Please Select gender")
    .notOneOf(["-1"], "Please Select gender"),
  dateofbirth: Yup.date()
    .required("Please Select Date")
    .max(new Date(), "You can't be born in the future!"),
  //link: Yup.string().required("Please Enter Users link"),
  email: Yup.string().required("Please Enter Your Email"),
  password: Yup.string().required("Please Enter Your Password"),
  city: Yup.string().required("Please Enter Your City"),
  contact: Yup.number().required("Please Enter Your Phone"),
  zipcode: Yup.number().required("Please Enter Zipcode"),
})

const AddUsers = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const usersId = searchParams.get("id")
  const language = JSON.parse(localStorage.getItem("language"))

  const [isEdit, setIsEdit] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState({})
  const [selectedFiles, setSelectedFiles] = useState([])

  const optionGroup1 = [
    { label: "Individual Investor", value: "individual_investor" },
    { label: "Advisor", value: "advisor" },
    { label: "Legal Representative", value: "legalrepresent" },
    { label: "Basic", value: "basic" },
  ]
  const userlevelOption = [
    { label: "Intermediate", value: "intermediate" },
    { label: "Advanced", value: "advanced" },
  ]
  const optionCountry = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahrain" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BR", label: "Brazil" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "CV", label: "Cape Verde" },
    { value: "KY", label: "Cayman Islands" },
    { value: "CF", label: "Central African Republic" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmas Island" },
    { value: "CC", label: "Cocos (Keeling) Islands" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros" },
    { value: "CG", label: "Congo" },
    { value: "CK", label: "Cook Islands" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Cote d&#39;Ivoire" },
    { value: "HR", label: "Croatia (Hrvatska)" },
    { value: "CU", label: "Cuba" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "ET", label: "Ethiopia" },
    { value: "FK", label: "Falkland Islands (Malvinas)" },
    { value: "FO", label: "Faroe Islands" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GF", label: "French Guiana" },
    { value: "PF", label: "French Polynesia" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Greece" },
    { value: "GL", label: "Greenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IQ", label: "Iraq" },
    { value: "IE", label: "Ireland" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JO", label: "Jordan" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KR", label: "Korea, Republic of" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" },
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libyan Arab Jamahiriya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macau" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "MD", label: "Moldova, Republic of" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands" },
    { value: "AN", label: "Netherlands Antilles" },
    { value: "NC", label: "New Caledonia" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Island" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" },
    { value: "PW", label: "Palau" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" },
    { value: "RE", label: "Reunion" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russian Federation" },
    { value: "RW", label: "Rwanda" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint LUCIA" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" },
    { value: "SN", label: "Senegal" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SK", label: "Slovakia (Slovak Republic)" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SH", label: "St. Helena" },
    { value: "PM", label: "St. Pierre and Miquelon" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Suriname" },
    { value: "SZ", label: "Swaziland" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syrian Arab Republic" },
    { value: "TW", label: "Taiwan, Province of China" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania, United Republic of" },
    { value: "TH", label: "Thailand" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks and Caicos Islands" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates" },
    { value: "GB", label: "United Kingdom" },
    { value: "US", label: "United States" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Viet Nam" },
    { value: "VG", label: "Virgin Islands (British)" },
    { value: "VI", label: "Virgin Islands (U.S.)" },
    { value: "WF", label: "Wallis and Futuna Islands" },
    { value: "EH", label: "Western Sahara" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
  ]
  const { usersList, error, document } = useSelector(state => ({
    usersList: state.Users.users,
    error: state.Users.error,
    document: state.DocumentUpload.document,
  }))

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
  }, [error])

  useEffect(() => {
    /* if (usersId !== null && usersList?.length > 0) {
      let users = usersList.filter(item => {
        return String(item.id) === usersId
      })
      console.log("selectedUsers", users)
      setSelectedUsers(users[0])
      setIsEdit(true)
      assignInitialValues(users[0]) */
    console.log("usersList", usersList)
    if (usersId !== null && usersId !== undefined && usersList.length <= 0) {
      //call api to get user data;
      dispatch(onGetUsersById(usersId))
      //} else if (usersList?.length <= 0 && usersId !== null) {
    } else if (usersList.length > 0 && usersId !== null) {
      //call api to get users data;
      //dispatch(onGetUsers())
      setSelectedUsers(usersList[0])
      setIsEdit(true)
      assignInitialValues(usersList[0])
    }
  }, [usersId, usersList])

  const assignInitialValues = users => {
    validation.setFieldValue("lang_id", users?.lang_id)
    validation.setFieldValue("imgUrl", users?.imgUrl)
    validation.setFieldValue("firstname", users?.firstname)
    validation.setFieldValue("lastname", users?.lastname)
    validation.setFieldValue("role", users?.role)
    validation.setFieldValue("country", users?.country)
    //validation.setFieldValue("gender", users?.userdetail?.gender);
    //validation.setFieldValue("dateofbirth", new Date(users?.dateofbirth));
    //validation.setFieldValue("link", users?.link);
    validation.setFieldValue("email", users?.email)
    validation.setFieldValue("password", users.password)
    //validation.setFieldValue("city", users?.userdetail?.city);
    validation.setFieldValue("contact", users.contact)
    //validation.setFieldValue("zipcode", users?.userdetail.zipcode);
    validation.setFieldValue("id", users.id)
    validation.setFieldValue("isActive", false)

    /* let tempArr = (users.targetUser).split(',');
    let tempTargetUserArr = [];
    tempArr.forEach(element => {
      let tempObj = {"label": element, "value": element}
      tempTargetUserArr.push(tempObj);
    }); */
    validation.setFieldValue("targetUser", users.targetUser)
  }

  function handleAcceptedFiles(files) {
    dispatch(uploadDocument(files[0]))
    // validation.setFieldValue(
    //   "imgUrl",
    //   "https://ucarecdn.com/b223131b-a0a0-4812-9805-0029fe27c169/banyan_logo.png"
    // )
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setSelectedFiles(files)
  }

  useEffect(() => {
    console.log("document", document)
    if (document !== undefined) {
      validation.setFieldValue("imgUrl", document?.fileurl)
    }
  }, [document])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [descriptionEditorState, setDescriptionEditorState] = useState(
    EditorState.createEmpty()
  )
  const handleEditorChange = newEditorState => {
    setDescriptionEditorState(newEditorState)
    const currentContent = convertToRaw(newEditorState.getCurrentContent())
    const isEmpty = currentContent?.blocks[0]?.text === ""
    // const html = draftToHtml(rawContentState);

    validation.setFieldValue(
      "description",
      isEmpty ? "" : draftToHtml(currentContent)
    )
    validation.setFieldTouched("description", true)
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      /* console.log("values", values)
      let targetUserString = '';
      values['targetUser'].forEach(element => {
        targetUserString+= targetUserString === '' ? element.value : `,${element.value}`
      });*/
      let payload = { ...values }
      //payload.targetUser = targetUserString;
      if (isEdit) {
        console.log("updateUsersPayload", payload)
        // update User
        dispatch(onUpdateUsers(payload))
        history.push("/users")
      } else {
        console.log("newUsersPayload", payload)
        // save new customer
        dispatch(onAddNewUsers(payload))
        validation.resetForm()
        history.push("/users")
      }
    },
  })

  useEffect(() => {
    if (
      validation?.values["lang_id"] === undefined ||
      validation?.values["lang_id"] === null
    )
      validation.setFieldValue("lang_id", language[0].id)
  }, [])

  // useEffect(() => {
  //   console.log("formik obj", validation)
  // })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Users</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Users" breadcrumbItem="Add Users" />
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <FormGroup className="select2-container mb-4" row>
                      <Label className="col-form-label col-lg-12">
                        Language
                      </Label>
                      <Col md="10">
                        <select
                          className={`form-control select2`}
                          title="Language"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="lang_id"
                          value={validation.values.lang_id || ""}
                          placeholder="select language"
                        >
                          {language.map((option, index) => (
                            <option key={index} value={option.id}>
                              {option.language_name}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-12">
                        Picture
                      </Label>
                      <Col lg="10">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className={`dropzone ${
                                validation.touched.imgUrl &&
                                validation.errors.imgUrl
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input
                                  name="imgUrl"
                                  onChange={validation.handleChange}
                                  {...getInputProps()}
                                />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                        {validation.touched.imgUrl &&
                        validation.errors.imgUrl ? (
                          <FormFeedback type="invalid">
                            {validation.errors.imgUrl}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-12"
                      >
                        First Name
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.firstname &&
                          validation.errors.firstname
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="firstname"
                          name="firstname"
                          type="text"
                          className="form-control"
                          placeholder="Enter firstname..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname}
                          invalid={
                            validation.touched.firstname &&
                            validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.firstname &&
                      validation.errors.firstname ? (
                        <FormFeedback type="invalid">
                          {validation.errors.firstname}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-12"
                      >
                        Last Name
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.lastname &&
                          validation.errors.lastname
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="lastname"
                          name="lastname"
                          type="text"
                          className="form-control"
                          placeholder="Enter lastname..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.lastname &&
                      validation.errors.lastname ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lastname}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-12">
                        Date of Birth
                      </Label>
                      <Col lg="10">
                        <Row>
                          <Col className="pl-0">
                            {/* {" "}
                            <DatePicker
                              className="form-control"
                              name="dateofbirth"
                              onChange={(dateofbirth) => {
                                validation.setFieldValue('dateofbirth', new Date(dateofbirth))
                              }}
                              selected={validation.values.dateofbirth}
                              invalid={
                                validation.touched.dateofbirth &&
                                validation.errors.dateofbirth
                                  ? true
                                  : false
                              }
                            /> */}
                            <Input
                              className="form-control"
                              type="date"
                              defaultValue="2019-08-19"
                              id="dateofbirth"
                              name="dateofbirth"
                              onChange={event => {
                                validation.setFieldValue(
                                  "dateofbirth",
                                  new Date(event.target.value)
                                )
                              }}
                              selected={validation.values.dateofbirth}
                              invalid={
                                validation.touched.dateofbirth &&
                                validation.errors.dateofbirth
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          {validation.touched.dateofbirth &&
                          validation.errors.dateofbirth ? (
                            <FormFeedback type="invalid">
                              {validation.errors.dateofbirth}
                            </FormFeedback>
                          ) : null}
                        </Row>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-12"
                      >
                        Mobile no.
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.contact &&
                          validation.errors.contact
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="contact"
                          name="contact"
                          type="number"
                          className="form-control"
                          placeholder="Enter contact..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contact}
                          invalid={
                            validation.touched.contact &&
                            validation.errors.contact
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.contact &&
                      validation.errors.contact ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contact}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="select2-container mb-4" row>
                      <Label className="col-form-label col-lg-12">Gender</Label>
                      <Col
                        md="10"
                        className={`${
                          validation.touched.gender && validation.errors.gender
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <select
                          className={`form-control select2 ${
                            validation.touched.gender &&
                            validation.errors.gender
                              ? "is-invalid"
                              : ""
                          }`}
                          title="gender"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="gender"
                          value={validation.values.gender || ""}
                          aria-invalid={
                            validation.touched.gender &&
                            validation.errors.gender
                              ? true
                              : false
                          }
                        >
                          <option value="-1">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </Col>
                      {validation.touched.gender && validation.errors.gender ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gender}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-12"
                      >
                        Password
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.password &&
                          validation.errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="password"
                          name="password"
                          type="text"
                          className="form-control"
                          placeholder="Enter password..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.lastname &&
                      validation.errors.lastname ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lastname}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-12"
                      >
                        Email Id
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.password &&
                          validation.errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Enter email..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="select2-container mb-4" row>
                      <Label className="col-form-label col-lg-12">Role</Label>
                      <Col
                        md="10"
                        className={`${
                          validation.touched.role && validation.errors.role
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <select
                          className={`form-control select2 ${
                            validation.touched.role && validation.errors.role
                              ? "is-invalid"
                              : ""
                          }`}
                          title="role"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="role"
                          value={validation.values.role || ""}
                          aria-invalid={
                            validation.touched.role && validation.errors.role
                              ? true
                              : false
                          }
                        >
                          <option value="-1">select Role</option>
                          {optionGroup1.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </Col>
                      {validation.touched.role && validation.errors.role ? (
                        <FormFeedback type="invalid">
                          {validation.errors.role}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-12"
                      >
                        Country code
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.countrycode &&
                          validation.errors.countrycode
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="countrycode"
                          name="countrycode"
                          type="text"
                          className="form-control"
                          placeholder="Enter countrycode..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.countrycode}
                          invalid={
                            validation.touched.countrycode &&
                            validation.errors.countrycode
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.countrycode &&
                      validation.errors.countrycode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.countrycode}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>

                    <FormGroup className="select2-container mb-4" row>
                      <Label className="col-form-label col-lg-12">
                        Country
                      </Label>
                      <Col md="10">
                        <select
                          className={`form-control select2 ${
                            validation.touched.country &&
                            validation.errors.country
                              ? "is-invalid"
                              : ""
                          }`}
                          title="Country"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="country"
                          value={validation.values.country || ""}
                          aria-invalid={
                            validation.touched.country &&
                            validation.errors.country
                              ? true
                              : false
                          }
                          placeholder="select country"
                        >
                          <option value="-1">select country</option>
                          {optionCountry.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {validation.touched.country &&
                        validation.errors.country ? (
                          <FormFeedback type="invalid">
                            {validation.errors.country}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="city"
                        className="col-form-label col-lg-12"
                      >
                        City
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.city && validation.errors.city
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="city"
                          name="city"
                          type="text"
                          className="form-control"
                          placeholder="Enter city..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.city}
                          invalid={
                            validation.touched.city && validation.errors.city
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.city && validation.errors.city ? (
                        <FormFeedback type="invalid">
                          {validation.errors.city}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="city"
                        className="col-form-label col-lg-12"
                      >
                        Zipcode
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.zipcode &&
                          validation.errors.zipcode
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="zipcode"
                          name="zipcode"
                          type="number"
                          className="form-control"
                          placeholder="Enter zipcode..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.zipcode}
                          invalid={
                            validation.touched.zipcode &&
                            validation.errors.zipcode
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.zipcode &&
                      validation.errors.zipcode ? (
                        <FormFeedback type="invalid">
                          {validation.errors.zipcode}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="link"
                        className="col-form-label col-lg-12"
                      >
                        Link
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.link && validation.errors.link
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="link"
                          name="link"
                          type="text"
                          className="form-control"
                          placeholder="Enter link..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.link}
                          invalid={
                            validation.touched.link && validation.errors.link
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.link && validation.errors.link ? (
                        <FormFeedback type="invalid">
                          {validation.errors.link}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <Button
                    color="primary"
                    type="submit"
                    className="btn-rounded p-4 pb-2 pt-2"
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddUsers
