/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

export const GET_USERS_BY_ID = "GET_USERS_BY_ID"
export const GET_USERS_BY_ID_SUCCESS = "GET_USERS_BY_ID_SUCCESS"
export const GET_USERS_BY_ID_FAIL = "GET_USERS_BY_ID_FAIL"

/**
 * add USERS
 */
export const ADD_NEW_USERS = "ADD_NEW_USERS"
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS"
export const ADD_USERS_FAIL = "ADD_USERS_FAIL"

/**
 * Edit USERS
 */
export const UPDATE_USERS = "UPDATE_USERS"
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS"
export const UPDATE_USERS_FAIL = "UPDATE_USERS_FAIL"

/**
 * Delete USERS
 */
export const DELETE_USERS = "DELETE_USERS"
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS"
export const DELETE_USERS_FAIL = "DELETE_USERS_FAIL"

export const VERIFY_USERS = "VERIFY_USERS"
export const VERIFY_USERS_SUCCESS = "VERIFY_USERS_SUCCESS"
export const VERIFY_USERS_FAIL = "VERIFY_USERS_FAIL"

export const REJECT_USERS = "REJECT_USERS"
export const REJECT_USERS_SUCCESS = "REJECT_USERS_SUCCESS"
export const REJECT_USERS_FAIL = "REJECT_USERS_FAIL"

/**
 * Deleted USERS Request
 */
export const GET_DELETED_USERS = "GET_DELETED_USERS"
export const GET_DELETED_USERS_SUCCESS = "GET_DELETED_USERS_SUCCESS"
export const GET_DELETED_USERS_FAIL = "GET_DELETED_USERS_FAIL"

export const DELETE_USERS_REQUEST = "DELETE_USERS_REQUEST"
export const DELETE_USERS_REQUEST_SUCCESS = "DELETE_USERS_REQUEST_SUCCESS"
export const DELETE_USERS_REQUEST_FAIL = "DELETE_USERS_REQUEST_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = "UPDATE_DELETE_SUCCESS"

/**
 * Freeze Users
 */
export const FREEZE_USERS = "FREEZE_USERS"
export const FREEZE_USERS_SUCCESS = "FREEZE_USERS_SUCCESS"
export const FREEZE_USERS_FAIL = "FREEZE_USERS_FAIL"
