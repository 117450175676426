import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  addNewUserRole as onAddNewUserRole,
  updateUserRole as onUpdateUserRole,
  getUserRoleById as onGetUserRoleById,
} from "store/userRole/actions"
import { toast } from "react-toastify"

const initialValues = {
  password: "",
  email: "",
  contact: "",
  role: "",
  //id: null
}

const validationSchema = Yup.object({
  password: Yup.string().required("Please Enter Password"),
  email: Yup.mixed().required("Please Enter Email id"),
  contact: Yup.string().required("Please Enter contact no."),
  role: Yup.string()
    .required("Please Select Role")
    .notOneOf(["-1"], "Please Select Role"),
})

const AddUserRole = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const userRoleId = searchParams.get("id")
  const language = JSON.parse(localStorage.getItem("language"))

  const [isEdit, setIsEdit] = useState(false)
  const [selectedNews, setSelectedNews] = useState({})
  const userroleoption = [
    { label: "Admin", value: "admin" },
    { label: "Editor", value: "editor" },
    { label: "AI Officer", value: "ai_officer" },
  ]
  const userlevelOption = [
    { label: "Intermediate", value: "intermediate" },
    { label: "Advanced", value: "advanced" },
  ]

  const { userRoleById, addUpdateSuccess, error } = useSelector(state => ({
    userRoleById: state.UserRole.userroleById,
    error: state.UserRole.error,
    addUpdateSuccess: state.UserRole.addUpdateSuccess,
  }))

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
    else if (addUpdateSuccess) {
      toast.success(`User Role ${isEdit ? "updated" : "added"} successfully`, {
        autoClose: 2000,
      })
      validation.resetForm()
      history.push("/userRole")
    } else if (
      !addUpdateSuccess &&
      addUpdateSuccess !== null &&
      addUpdateSuccess !== undefined &&
      addUpdateSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
  }, [error, addUpdateSuccess])

  useEffect(() => {
    if (userRoleId !== null && userRoleId !== undefined) {
      //call api to get company data;
      dispatch(onGetUserRoleById(userRoleId))
    }
  }, [userRoleId])

  useEffect(() => {
    if (userRoleById.length > 0 && userRoleId !== null) {
      setSelectedNews(userRoleById[0])
      setIsEdit(true)
      assignInitialValues(userRoleById[0])
    }
  }, [userRoleById])

  const assignInitialValues = userRole => {
    validation.setFieldValue("password", userRole?.password)
    validation.setFieldValue("email", userRole?.email)
    validation.setFieldValue("contact", userRole?.contact)
    validation.setFieldValue("role", userRole?.role)
    //validation.setFieldValue("id", userRole.id);
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      let payload = {}
      if (isEdit) {
        payload.id = userRoleId
        payload.contact = values.contact
        payload.role = values.role
        //payload.type = values.role === "admin" ? "admin" : "user";
        payload.password = values.password
        console.log("updateNewsPayload", payload)
        // update company
        dispatch(onUpdateUserRole(payload))
        // history.push('/templates');
      } else {
        payload = { ...values }
        payload.type =
          values.role === "admin"
            ? "admin"
            : values.role === "editor"
            ? "admin"
            : "user"
        console.log("newNewsPayload", payload)
        // save new customer
        dispatch(onAddNewUserRole(payload))
        validation.resetForm()
        // history.push('/templates');
      }
    },
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          {isEdit ? (
            <title>Edit User Role</title>
          ) : (
            <title>Add User Role</title>
          )}
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {isEdit ? (
            <Breadcrumbs title="User Role" breadcrumbItem="Edit User Role" />
          ) : (
            <Breadcrumbs title="Templates" breadcrumbItem="Add User Role" />
          )}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={validation.handleSubmit}>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="email"
                        className="col-form-label col-lg-12"
                      >
                        Email Id
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.email && validation.errors.email
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Enter email..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          disabled={
                            userRoleId !== null && userRoleId !== undefined
                          }
                        />
                      </Col>
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="contact"
                        className="col-form-label col-lg-12"
                      >
                        Contact no.
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.contact &&
                          validation.errors.contact
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="contact"
                          name="contact"
                          type="text"
                          className="form-control"
                          placeholder="Enter contact..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contact}
                          invalid={
                            validation.touched.contact &&
                            validation.errors.contact
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.contact &&
                      validation.errors.contact ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contact}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="password"
                        className="col-form-label col-lg-12"
                      >
                        Password
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.password &&
                          validation.errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="Enter password..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="select2-container mb-4" row>
                      <Label className="col-form-label col-lg-12">
                        User Role
                      </Label>
                      <Col
                        md="10"
                        className={`${
                          validation.touched.role && validation.errors.role
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <select
                          className={`form-control select2 ${
                            validation.touched.role && validation.errors.role
                              ? "is-invalid"
                              : ""
                          }`}
                          title="role"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="role"
                          value={validation.values.role || ""}
                          aria-invalid={
                            validation.touched.role && validation.errors.role
                              ? true
                              : false
                          }
                        >
                          <option value="-1">Select</option>
                          <option value="admin">Admin</option>
                          <option value="editor">Editor</option>
                          <option value="ai_officer">AI Officer</option>
                        </select>
                      </Col>
                      {validation.touched.role && validation.errors.role ? (
                        <FormFeedback type="invalid">
                          {validation.errors.role}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <Row>
                      <Col xs="12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                          <Button
                            color="primary"
                            type="submit"
                            className="btn-rounded p-4 pb-2 pt-2"
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddUserRole
