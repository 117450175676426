import { call, put, takeEvery } from "redux-saga/effects"
import React from "react"
import {
  GET_COMPANY,
  GET_COMPANY_BY_ID,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  DELETE_SECTION_ENTRY,
} from "./actionTypes"
import {
  getCompanyFail,
  getCompanySuccess,
  getCompanyByIdFail,
  getCompanyByIdSuccess,
  addCompanyFail,
  addCompanySuccess,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  deleteSectionEntrySuccess,
  deleteSectionEntryFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCompany,
  addNewCompany,
  updateCompany,
  deleteCompany,
  getCompanyById,
  deleteSectionEntry,
} from "helpers/fakebackend_helper"
import "react-toastify/dist/ReactToastify.css"

function* fetchCompany() {
  try {
    const response = yield call(getCompany)
    yield put(getCompanySuccess(response.data))
  } catch (error) {
    yield put(getCompanyFail(error.response))
  }
}

function* fetchCompanyById({ payload: comapnyId }) {
  try {
    console.log(comapnyId, "Comp Obj")
    const response = yield call(getCompanyById, comapnyId)
    yield put(getCompanyByIdSuccess(response.data))
  } catch (error) {
    yield put(getCompanyByIdFail(error.response))
  }
}

function* onUpdateCompany({ payload: comapny }) {
  try {
    const response = yield call(updateCompany, comapny)
    yield put(updateCompanySuccess(response))
  } catch (error) {
    yield put(updateCompanyFail(error.response))
  }
}

function* onDeleteCompany({ payload: comapny }) {
  try {
    const response = yield call(deleteCompany, comapny)
    yield put(deleteCompanySuccess(response, comapny))
  } catch (error) {
    yield put(deleteCompanyFail(error.response))
  }
}

function* onAddNewCompany({ payload: comapny }) {
  try {
    const response = yield call(addNewCompany, comapny)
    yield put(addCompanySuccess(response))
  } catch (error) {
    yield put(addCompanyFail(error.response))
  }
}

function* onDeleteSectionEntry({ entryId, entryType }) {
  try {
    const response = yield call(deleteSectionEntry, entryId, entryType)
    yield put(deleteSectionEntrySuccess(response))
  } catch (error) {
    yield put(deleteSectionEntryFail(error.response))
  }
}

function* comapnySaga() {
  yield takeEvery(GET_COMPANY, fetchCompany)
  yield takeEvery(GET_COMPANY_BY_ID, fetchCompanyById)
  yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  yield takeEvery(DELETE_SECTION_ENTRY, onDeleteSectionEntry)
}

export default comapnySaga
