import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  Accordion,
  AccordionHeader,
  AccordionItem,
  CardText,
  CardTitle,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { useFormik } from "formik"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import classnames from "classnames"
import { useSelector, useDispatch } from "react-redux"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { getCompanyById as onGetCompanyById } from "store/company/actions"
import { getSustainGoal as onGetSustainGoals } from "store/common/actions"
import { convertToRaw, ContentState, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { getReports as onGetReports } from "store/reports/actions"
const CompanyDetailsPage = () => {
  const inpRow = [{ name: "", file: "" }]
  const [selectedFiles, setselectedFiles] = useState([])
  const [inputFields, setinputFields] = useState(inpRow)
  const [activeTab1, setactiveTab1] = useState("5")
  const [selectedMulti, setselectedMulti] = useState(null)

  const optionGroup = [
    { label: "Life on Land", value: "Life on Land" },
    { label: "No Poverty", value: "No Poverty" },
    {
      label: "Decent Work and Economic Growth",
      value: "Decent Work and Economic Growth",
    },
    {
      label: "Industry Innovation and Infrastructure",
      value: "Industry Innovation and Infrastructure",
    },
    { label: "Climate Action", value: "Climate Action" },
    { label: "Gender Equality", value: "Gender Equality" },
    { label: "Partnership for the goals", value: "Partnership for the goals" },
    {
      label: "Clean water and sanitation",
      value: "Clean water and sanitation",
    },
    {
      label: "Affordable and clean energy",
      value: "Affordable and clean energy",
    },
    { label: "Quality Education", value: "Quality Education" },
    {
      label: "Good health and well being",
      value: "Good health and well being",
    },
    {
      label: "Sustainable cities and communities",
      value: "Sustainable cities and communities",
    },
    {
      label: "Peace, Justice and strong institutions",
      value: "Peace, Justice and strong institutions",
    },
    { label: "Zero Hunger", value: "Zero Hunger" },
    { label: "Reduced Inequality", value: "Reduced Inequality" },
    {
      label: "Responsible Production and Consumption",
      value: "Responsible Production and Consumption",
    },
    { label: "Life Below Water", value: "Life Below Water" },
  ]
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const companyId = searchParams.get("id")
  const inpSummaryRow = [{ sectionname: "summary", key: "", value: "" }]
  const inpImpactRow = [{ sectionname: "impact", key: "", value: "" }]
  //const [selectedFiles, setselectedFiles] = useState([])
  const [impactInputFields, setImpactInputFields] = useState([])
  const [summaryInputFields, setSummaryInputFields] = useState([])
  const [summaryEditorState, setSummaryEditorState] = useState({
    // id: null,
    // companyid: null,
    sectionname: "summary",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
  })
  const [impactEditorState, setImpactEditorState] = useState({
    // id: null,
    // companyid: null,
    sectionname: "impact",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
  })
  const [sdgEditorState, setSdgEditorState] = useState({
    // id: null,
    // companyid: null,
    sectionname: "sustainGoal",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
  })
  const [isEdit, setIsEdit] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState({})
  const [tempEntityData, setTempEntityData] = useState({})
  const language = JSON.parse(localStorage.getItem("language"))
  const { companyById, sustainGoals, addUpdateSuccess, error, deleteSuccess } =
    useSelector(state => ({
      companyById: state.Company.companyById,
      sustainGoals: state.Common.sustainGoals,
      error: state.Company.error,
    }))

  const [sustainArray, setSustainArray] = useState([])
  useEffect(() => {
    console.log("sustainGoals", sustainGoals)
    if (sustainGoals.length <= 0) {
      //call api to get company data;
      dispatch(onGetSustainGoals())
    } else {
      let temp = []
      sustainGoals.forEach(element => {
        temp.push({
          label: element.value,
          value: element.id,
        })
      })
      setSustainArray(temp)
    }
  }, [sustainGoals])

  useEffect(() => {
    if (companyId !== null && companyId !== undefined) {
      //call api to get company data;
      let company_id = {
        companyId: companyId,
        lang_id: 1,
      }
      dispatch(onGetCompanyById(company_id))
    }
  }, [companyId])

  useEffect(() => {
    console.log("company", companyById)
    if (companyById.length > 0 && companyId !== null) {
      setSelectedCompany(companyById[0])
      setIsEdit(true)
      prefillCompanyData(companyById[0])
    }
  }, [companyById])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      lang_id: language[0].id,
      name: "",
      companylogo: "",
      country: "",
      ebita: null,
      turnover: null,
      activerequest: null,
      summaryDesc: "",
      impactDesc: "",
      sdgDesc: "",
      sdgOptions: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Company Name"),
      companylogo: Yup.mixed().required("Please upload company logo"),
      country: Yup.string()
        .required("Please Select Country")
        .notOneOf(["-1"], "Please Select Country"),
      ebita: Yup.number().required("Please Enter EBITA"),
      turnover: Yup.number().required("Please Enter Turnover"),
      activerequest: Yup.number().required("Please Enter Turnover"),
    }),
    onSubmit: values => {
      let sectionData = []
      let tempSustainArr = []
      if (values["summaryDesc"] !== "") {
        sectionData.push(summaryEditorState)
      }
      if (values["impactDesc"] !== "") {
        sectionData.push(impactEditorState)
      }
      if (values["sdgDesc"] !== "") {
        sectionData.push(sdgEditorState)
      }

      if (summaryInputFields.length > 0) {
        let temp = sectionData.concat(summaryInputFields)
        sectionData = temp
      }
      if (impactInputFields.length > 0) {
        let temp = sectionData.concat(impactInputFields)
        sectionData = temp
      }
      if (values["sdgOptions"]?.length > 0) {
        values["sdgOptions"].forEach(element => {
          if (isEdit)
            tempSustainArr.push({
              sustaingoalid: element.value,
              companyid: selectedCompany.id,
              id: `${element.id !== undefined ? element.id : null}`,
            })
          else tempSustainArr.push({ sustaingoalid: element.value })
        })
      }

      addOrUpdateCompany(values, sectionData, tempSustainArr)
    },
  })

  const addOrUpdateCompany = (values, sectionData, tempSustainArr) => {
    // if (isEdit) {
    const updateCompanyPayload = {
      lang_id: values["lang_id"],
      name: values["name"],
      companylogo: values["companylogo"],
      country: values["country"],
      ebita: values["ebita"],
      turnover: values["turnover"],
      activerequest: values["activerequest"],
      sectiondata: sectionData,
      sustaingoalarchiveddesc: values["sdgDesc"],
      sustainarr: tempSustainArr,

      id: selectedCompany.id,
      companycode: selectedCompany.companycode,
      value: selectedCompany.value,
      type: selectedCompany.type,
      targetUser: selectedCompany.targetUser,
      imgUrl: selectedCompany.imgUrl,
      otherdetail: selectedCompany.otherdetail,
      isNew: false,
    }
    // update company
    dispatch(onUpdateCompany(updateCompanyPayload))
    // }
  }

  const handleEditorChange = (newEditorState, fieldName) => {
    const currentContent = convertToRaw(newEditorState.getCurrentContent())
    const isEmpty = currentContent?.blocks[0]?.text === ""
    // const html = draftToHtml(rawContentState);

    validation.setFieldValue(
      fieldName,
      isEmpty ? "" : draftToHtml(currentContent)
    )
    validation.setFieldTouched(fieldName, true)

    fieldName === "summaryDesc"
      ? setSummaryEditorState(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : fieldName === "impactDesc"
      ? setImpactEditorState(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : setSdgEditorState(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
  }

  const handleOnChange = (secRef, fieldRef, index, value) => {
    let tempObj =
      secRef === "summary"
        ? summaryInputFields[index]
        : impactInputFields[index]
    if (fieldRef === "key") {
      tempObj.key = value
    } else if (fieldRef === "value") {
      tempObj.value = value
    }
    if (isEdit) tempObj["companyid"] = selectedCompany?.id

    if (secRef === "summary") {
      setSummaryInputFields(prevItems => {
        const newArray = [...prevItems]
        newArray[index] = tempObj
        return newArray
      })
    } else if (secRef === "impact") {
      setImpactInputFields(prevItems => {
        const newArray = [...prevItems]
        newArray[index] = tempObj
        return newArray
      })
    }
  }

  const prefillCompanyData = company => {
    console.log("company prefill data", company)

    validation.setFieldValue("lang_id", company?.lang_id)
    validation.setFieldValue("name", company?.name)
    validation.setFieldValue("country", company?.country)
    validation.setFieldValue("ebita", company?.ebita)
    validation.setFieldValue("turnover", company?.turnover)
    validation.setFieldValue("activerequest", company?.activerequest)

    let tempSummary = [],
      tempImpact = []
    company?.sectiondata.forEach(item => {
      if (item.key === "description") {
        item.sectionname === "summary"
          ? setSummaryEditorState(prevState => ({
              ...prevState,
              editorValue: getContentBlock(item.value),
              id: item.id,
              companyid: company?.id,
              value: item?.value,
            }))
          : item.sectionname === "impact"
          ? setImpactEditorState(prevState => ({
              ...prevState,
              editorValue: getContentBlock(item.value),
              id: item.id,
              companyid: company?.id,
              value: item?.value,
            }))
          : item.sectionname === "sustainGoal"
          ? setSdgEditorState(prevState => ({
              ...prevState,
              editorValue: getContentBlock(item.value),
              id: item.id,
              companyid: company?.id,
              value: item?.value,
            }))
          : ""
        item.sectionname === "summary"
          ? validation.setFieldValue("summaryDesc", item?.value)
          : item.sectionname === "impact"
          ? validation.setFieldValue("impactDesc", item?.value)
          : item.sectionname === "sustainGoal"
          ? validation.setFieldValue("sdgDesc", item?.value)
          : ""
      } else {
        let tempSecSummary = { ...inpSummaryRow[0] }
        let tempSecImpact = { ...inpImpactRow[0] }
        if (item.sectionname === "summary") {
          tempSecSummary.key = item.key
          tempSecSummary.value = item.value
          tempSecSummary["companyid"] = company.id
          tempSecSummary["id"] = item.id
          tempSummary.push(tempSecSummary)
        } else if (item.sectionname === "impact") {
          tempSecImpact.key = item.key
          tempSecImpact.value = item.value
          tempSecImpact["companyid"] = company.id
          tempSecImpact["id"] = item.id
          tempImpact.push(tempSecImpact)
        }
      }
    })
    setSummaryInputFields(tempSummary)
    setImpactInputFields(tempImpact)

    let tempSdgOptions = []
    if (company?.sustainarr.length > 0) {
      company?.sustainarr.forEach(item => {
        tempSdgOptions.push({
          label: item.SustainGoal.value,
          value: item.sustaingoalid,
          id: item.id,
        })
      })
    }
    validation.setFieldValue("sdgOptions", tempSdgOptions)

    /* getImageFileFromUrl(company?.companylogo, 'company-logo.png')
      .then(file => {
        if(file) {
          let temp = [Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })]
          setselectedFiles(temp)
        }
      }) */
    validation.setFieldValue("companylogo", company?.companylogo)
  }

  const getContentBlock = htmlContent => {
    const contentBlock = htmlToDraft(htmlContent)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const newEditorState = EditorState.createWithContent(contentState)
      return newEditorState
    }
    return ""
  }

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }
  const toggle = () => {
    if (modal) {
      setModal(false)
      setOrder(null)
    } else {
      setModal(true)
    }
  }
  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAddFields() {
    const item1 = { name: "", file: "" }
    setinputFields([...inputFields, item1])
  }

  // Function for Remove Input Fields
  function handleRemoveFields(idx) {
    document.getElementById("nested" + idx).style.display = "none"
  }

  const [report, setReport] = useState([
    {
      id: "customCheck2",
      reportTitle: "Report Title 1",
      type: "Quaterly",
      category: "Category 5",
    },
    {
      id: "customCheck3",
      reportTitle: "Report Title 2",
      type: "Annual",
      category: "Category 4",
    },
    {
      id: "customCheck4",
      reportTitle: "Report Title 3",
      type: "Quaterly",
      category: "Category 2",
    },
    {
      id: "customCheck5",
      reportTitle: "Report Title 4",
      type: "Annual",
      category: "Category 3",
    },
    {
      id: "customCheck6",
      reportTitle: "Report Title 5",
      type: "General",
      category: "Category 1",
    },
  ])
  const dispatch = useDispatch()

  // validation

  /*const { orders } = useSelector(state => ({
    orders: state.ecommerce.orders,
  }));*/

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: report.length, // replace later with size(orders),
    custom: true,
  }

  /* const reportColumns = () => [
    {
      dataField: "id",
      text: "Report ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.id}
        </Link>
      ),
    },
    {
      dataField: "reportTitle",
      text: "Report Title",
      sort: true,
    },
    {
      dataField: "type",
      text: "Report Type",
      sort: true,
    },
    {
      dataField: "category",
      text: "User Category",
      sort: true,
    }
  ] */

  const reportColumns = () => [
    {
      dataField: "id",
      text: "Report Id",
      sort: true,
    },
    {
      dataField: "filename",
      text: "Title",
      sort: true,
    },
    {
      dataField: "companyid",
      text: "Company",
      sort: true,
    },
    {
      dataField: "reporttype",
      text: "Type",
      sort: true,
    },
    {
      dataField: "targetUser",
      text: "Category",
      sort: true,
    },
    {
      dataField: "tenure",
      text: "Tenure",
      sort: true,
    },
    {
      dataField: "tag",
      text: "Tag",
      sort: true,
    },
    {
      dataField: "reportdate",
      text: "Date and Time",
      sort: true,
    },
    /* {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, report) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={`/add-reports?id=${report.id}`}
              className="text-success"
              //onClick={() => onClickDelete(order)}
            >
              <i className="bx bxs-edit-alt font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDeleteReports(report)}
            >
              <i className="bx bxs-trash font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    } */
  ]

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  const { reports, error3, reportsDeleteSuccess } = useSelector(state => ({
    reports: state.Reports.reports,
    error3: state.Reports.error,
    deleteSuccess: state.Reports.deleteSuccess,
  }))
  const allReportsCompany = reports.filter(
    reportsData => reportsData.companyid === parseInt(companyId)
  )
  const quaterlyReportsCompany = reports.filter(
    reportsData =>
      reportsData.reporttype === "Quaterly" &&
      reportsData.companyid === parseInt(companyId)
  )
  const annualReportsCompany = reports.filter(
    reportsData =>
      reportsData.reporttype === "Annual" &&
      reportsData.companyid === parseInt(companyId)
  )
  const generalReportsCompany = reports.filter(
    reportsData =>
      reportsData.reporttype === "General Report" &&
      reportsData.companyid === parseInt(companyId)
  )
  const [reportsList, setReportsList] = useState([])
  const [selectedReports, setSelectedReports] = useState([])
  useEffect(() => {
    dispatch(onGetReports())
  }, [])

  // eslint-disable-next-line no-unused-vars
  const handleTableChangeReports = (type, { page, searchText }) => {
    setReportsList(
      reports.filter(reports =>
        Object.keys(reports).some(key =>
          reports[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  const reportsPageOptions = {
    sizePerPage: 5,
    totalSize: reports.length, // replace later with size(orders),
    custom: true,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Comapny Details</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Company" breadcrumbItem="Company Details" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Overview</CardTitle>
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between float-right">
                        {/* <Button
                              color="primary"
                              className="btn-rounded "
                              >
                              Edit
                              </Button> */}
                        <Link
                          to={`/add-company?id=${companyId}`}
                          className="text"
                        >
                          <Button color="primary" className="btn-rounded ">
                            Edit
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Summary
                          </Label>
                          <Col lg="10">
                            <Editor
                              id="summaryDesc"
                              name="summaryDesc"
                              editorState={summaryEditorState.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(
                                  newEditorState,
                                  "summaryDesc"
                                )
                              }
                              value={validation.values.summaryDesc || ""}
                              readOnly
                            />
                            {validation.touched.summaryDesc &&
                            validation.errors.summaryDesc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.summaryDesc}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col className="mt-3" lg="10">
                            <div className="inner-repeater mb-4">
                              <Label className="col-form-label col-lg-12">
                                Statistics
                              </Label>
                              <div className="inner form-group mb-0 row">
                                <div
                                  className="inner col-lg-10 ml-md-auto"
                                  id="repeater"
                                >
                                  {summaryInputFields.map((item, index) => (
                                    <div
                                      key={index}
                                      id={"summary" + index}
                                      className="mb-3 row align-items-center"
                                    >
                                      <Col md="5">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          defaultValue={item.key}
                                          placeholder="Enter Label..."
                                          onChange={e =>
                                            handleOnChange(
                                              "summary",
                                              "key",
                                              index,
                                              e.target.value
                                            )
                                          }
                                          readOnly
                                        />
                                      </Col>
                                      <Col md="5">
                                        <div className="mt-4 mt-md-0">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.value}
                                            placeholder="Enter Value..."
                                            onChange={e =>
                                              handleOnChange(
                                                "summary",
                                                "value",
                                                index,
                                                e.target.value
                                              )
                                            }
                                            readOnly
                                          />
                                        </div>
                                      </Col>
                                      {/* <Col md="2">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="primary"
                                            className="inner"
                                            onClick={() => {
                                              handleRemoveFields(
                                                "summary",
                                                index,
                                                item.id
                                              )
                                            }}
                                            block
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </Col> */}
                                    </div>
                                  ))}
                                </div>
                              </div>
                              {/* <Row className="justify-content-start">
                                <Col lg="10">
                                  <Button
                                    color="success"
                                    className="inner"
                                    onClick={() => {
                                      handleAddFields("summary")
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Col>
                              </Row> */}
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Impact
                          </Label>
                          <Col lg="10">
                            <Editor
                              id="impactDesc"
                              name="impactDesc"
                              editorState={impactEditorState.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(newEditorState, "impactDesc")
                              }
                              value={validation.values.impactDesc || ""}
                              readOnly
                            />
                          </Col>
                          <Col className="mt-3" lg="10">
                            <div className="inner-repeater mb-4">
                              <Label className="col-form-label col-lg-12">
                                Statistics
                              </Label>
                              <div className="inner form-group mb-0 row">
                                <div
                                  className="inner col-lg-10 ml-md-auto"
                                  id="repeater"
                                >
                                  {impactInputFields.map((item, index) => (
                                    <div
                                      key={index}
                                      id={"impact" + index}
                                      className="mb-3 row align-items-center"
                                    >
                                      <Col md="6">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          defaultValue={item.key}
                                          placeholder="Enter Label..."
                                          onChange={e =>
                                            handleOnChange(
                                              "impact",
                                              "key",
                                              index,
                                              e.target.value
                                            )
                                          }
                                          readOnly
                                        />
                                      </Col>
                                      <Col md="4">
                                        <div className="mt-4 mt-md-0">
                                          <Input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.value}
                                            placeholder="Enter Value..."
                                            onChange={e =>
                                              handleOnChange(
                                                "impact",
                                                "value",
                                                index,
                                                e.target.value
                                              )
                                            }
                                            readOnly
                                          />
                                        </div>
                                      </Col>
                                      {/* <Col md="2">
                                        <div className="mt-2 mt-md-0 d-grid">
                                          <Button
                                            color="primary"
                                            className="inner"
                                            onClick={() => {
                                              handleRemoveFields(
                                                "impact",
                                                index,
                                                item.id
                                              )
                                            }}
                                            block
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </Col> */}
                                    </div>
                                  ))}
                                </div>
                              </div>
                              {/* <Row className="justify-content-start">
                                <Col lg="10">
                                  <Button
                                    color="success"
                                    className="inner"
                                    onClick={() => {
                                      handleAddFields("impact")
                                    }}
                                  >
                                    Add
                                  </Button>
                                </Col>
                              </Row> */}
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-12">
                            Sustanable Developement Goals
                          </Label>
                          <Col lg="10">
                            <Editor
                              id="sdgDesc"
                              name="sdgDesc"
                              editorState={sdgEditorState.editorValue}
                              onEditorStateChange={newEditorState =>
                                handleEditorChange(newEditorState, "sdgDesc")
                              }
                              value={validation.values.sdgDesc || ""}
                              readOnly
                            />
                          </Col>
                          <Col className="mt-3" md="10">
                            <div className="mb-3">
                              <Select
                                id="sdgOptions"
                                name="sdgOptions"
                                type="select"
                                onChange={(option, e) => {
                                  console.log("option", option, e)
                                  if (e.action === "remove-value") {
                                    if (
                                      checkDataIsPresent(
                                        "sustainarr",
                                        e.removedValue.id
                                      )
                                    ) {
                                      setTempEntityData({
                                        fieldRef: "sustainarr",
                                        index: option,
                                      })
                                      dispatch(
                                        deleteSectionEntry(
                                          e.removedValue.id,
                                          "sustainarr"
                                        )
                                      )
                                    } else {
                                      validation.handleChange(
                                        "sdgOptions",
                                        option
                                      )
                                      validation.setFieldValue(
                                        "sdgOptions",
                                        option
                                      )
                                    }
                                  } else {
                                    validation.handleChange(
                                      "sdgOptions",
                                      option
                                    )
                                    validation.setFieldValue(
                                      "sdgOptions",
                                      option
                                    )
                                  }
                                }}
                                value={validation.values.sdgOptions}
                                isMulti={true}
                                options={sustainArray}
                                classNamePrefix="select2-selection"
                                styles={{
                                  clearIndicator: base => ({
                                    ...base,
                                    display: "none",
                                  }),
                                }}
                                readOnly
                                disabled={true}
                              />
                            </div>
                          </Col>
                        </FormGroup>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Reports</CardTitle>
                  <Row>
                    <Col xs="12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between float-right">
                        {/* <Button
                                color="primary"
                                className="btn-rounded "
                                >
                                Add New Report
                                </Button> */}

                        <Link
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2 btn btn-primary"
                          //onClick={handleOrderClicks}
                          to={`/add-reports?companyId=${companyId}`}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add Reports
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "5",
                        })}
                        onClick={() => {
                          toggle1("5")
                        }}
                      >
                        All
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "6",
                        })}
                        onClick={() => {
                          toggle1("6")
                        }}
                      >
                        Quaterly
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "7",
                        })}
                        onClick={() => {
                          toggle1("7")
                        }}
                      >
                        Annual
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "8",
                        })}
                        onClick={() => {
                          toggle1("8")
                        }}
                      >
                        General Report
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <PaginationProvider
                            pagination={paginationFactory(reportsPageOptions)}
                            keyField="id"
                            columns={reportColumns(toggle)}
                            data={allReportsCompany}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={allReportsCompany}
                                columns={reportColumns(toggle)}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            onClick={handleTableChangeReports}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <Row>
                        <Col sm="12">
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={reportColumns()}
                            data={quaterlyReportsCompany}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={quaterlyReportsCompany}
                                columns={reportColumns()}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7">
                      <Row>
                        <Col sm="12">
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={reportColumns()}
                            data={annualReportsCompany}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={annualReportsCompany}
                                columns={reportColumns()}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="8">
                      <Row>
                        <Col sm="12">
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={reportColumns()}
                            data={generalReportsCompany}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={generalReportsCompany}
                                columns={reportColumns()}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompanyDetailsPage
