import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { addNewPortfolio, updatePortfolio } from "../../store/portfolio/actions"

const AddEditPortfolioModal = props => {
  const dispatch = useDispatch();
  const { isOpen, userId, companyData, selectedPortfolio, closeModal } = props

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      companyid: "",
      count: "",
      userid: userId,
      id: null
    },
    validationSchema: Yup.object({
      companyid: Yup.mixed().required('Please Select Company').notOneOf(['-1'], 'Please Select Company'),
      count: Yup.string().required("Please Enter count"),
    }),
    onSubmit: values => {
      if(Object.keys(selectedPortfolio).length === 0) {
        dispatch(addNewPortfolio(values));
        validation.resetForm();
      } else {
        dispatch(updatePortfolio(values));
        validation.resetForm();
      }
    },
  })

  useEffect(() => {
    if(Object.keys(selectedPortfolio).length > 0 && isOpen) {
      const tempCompany = companyData.filter((item) => {
        return item.id === selectedPortfolio.companyid
      })
      
      validation.setFieldValue("companyid", tempCompany[0]?.id)
      validation.setFieldValue("count", selectedPortfolio?.count)
      validation.setFieldValue("id", selectedPortfolio?.id)
    }
  }, [isOpen])

  const handleModalClose = () => {
    validation.resetForm();
    closeModal();
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      // toggle={toggle}
      //imageUrl = {imageUrl}
    >
      <div className="modal-content">
        <ModalHeader toggle={handleModalClose}>{Object.keys(selectedPortfolio).length === 0 ? 'Add portfolio' : 'Edit Portfolio'}</ModalHeader>
        <ModalBody>
          <Form onSubmit={validation.handleSubmit}>
            <FormGroup className="select2-container mb-4" row>
              <Label className="col-form-label col-lg-12">
                Company
              </Label>
              <Col md="10">
                <select
                    className={`form-control select2 ${validation.touched.companyid && validation.errors.companyid ? 'is-invalid': ''}`}
                    title="Company Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="companyid"
                    value={validation.values.companyid || ""}
                    aria-invalid={
                      validation.touched.companyid && validation.errors.companyid
                        ? true
                        : false
                    }
                    placeholder="select Report Type"
                    disabled = {Object.keys(selectedPortfolio).length > 0 ? true : false }
                >
                    <option value="-1">Select Company</option>
                    {companyData.map((option, index) => (
                        <option key={index} value={option.id}>{option.name}</option>
                    ))}
                </select>
                {validation.touched.companyid &&
                validation.errors.companyid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.companyid}
                  </FormFeedback>
                ) : null}
              </Col>
            </FormGroup>
            <FormGroup className="mb-4" row>
              <Label
                htmlFor="count"
                className="col-form-label col-lg-12"
              >
                Count
              </Label>
              <Col lg="10">
                <Input
                  id="count"
                  name="count"
                  type="text"
                  className="form-control"
                  placeholder="Enter Portfolio count"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.count}
                  invalid={
                  validation.touched.count && validation.errors.count
                    ? true
                    : false
                  }
                />
                {validation.touched.count && validation.errors.count ? (
                  <FormFeedback type="invalid">
                    {validation.errors.count}
                  </FormFeedback>
                ) : null}
              </Col>
            </FormGroup>
            <Row>
              <Col xs="12">
                <Button type="submit" color="secondary" className="btn btn-success mr-2" disabled={!validation.isValid}>
                  Submit
                </Button>
                <Button type="button" color="secondary" onClick={handleModalClose} className="btn btn-success">
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          
        </ModalFooter>
      </div>
    </Modal>
  )
}

AddEditPortfolioModal.propTypes = {
  saveData: PropTypes.func,
  isOpen: PropTypes.bool,
  companyData: PropTypes.array,
  selectedPortfolio: PropTypes.object,
  closeModal: PropTypes.func,
  userId: PropTypes.string
}

export default AddEditPortfolioModal
