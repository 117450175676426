import {
  GET_INTERSTNFAVOURITE,
  GET_INTERSTNFAVOURITE_FAIL,
  GET_INTERSTNFAVOURITE_SUCCESS,
  GET_INTERSTNFAVOURITE_BY_ID,
  GET_INTERSTNFAVOURITE_BY_ID_SUCCESS,
  GET_INTERSTNFAVOURITE_BY_ID_FAIL,
  ADD_INTERSTNFAVOURITE,
  ADD_INTERSTNFAVOURITE_SUCCESS,
  ADD_INTERSTNFAVOURITE_FAIL,
  UPDATE_INTERSTNFAVOURITE,
  UPDATE_INTERSTNFAVOURITE_SUCCESS,
  UPDATE_INTERSTNFAVOURITE_FAIL,
  DELETE_INTERSTNFAVOURITE,
  DELETE_INTERSTNFAVOURITE_SUCCESS,
  DELETE_INTERSTNFAVOURITE_FAIL,
  UPDATE_DELETE_SUCCESS
} from "./actionTypes"

export const getInterestNFavourite = () => ({
  type: GET_INTERSTNFAVOURITE,
})

export const getInterestNFavouriteSuccess = interestNFavourite => ({
  type: GET_INTERSTNFAVOURITE_SUCCESS,
  payload: interestNFavourite,
})

export const getInterestNFavouriteFail = error => ({
  type: GET_INTERSTNFAVOURITE_FAIL,
  payload: error,
})

export const getInterestNFavouriteById = (interestNFavouriteId) => ({
  type: GET_INTERSTNFAVOURITE_BY_ID,
  payload: interestNFavouriteId,
})

export const getInterestNFavouriteByIdSuccess = interestNFavourite => ({
  type: GET_INTERSTNFAVOURITE_BY_ID_SUCCESS,
  payload: interestNFavourite,
})

export const getInterestNFavouriteByIdFail = error => ({
  type: GET_INTERSTNFAVOURITE_BY_ID_FAIL,
  payload: error,
})

export const addNewInterestNFavourite = interestnfavourite => ({
  type: ADD_INTERSTNFAVOURITE,
  payload: interestnfavourite,
})

export const addInterestNFavouriteSuccess = interestnfavourite => ({
  type: ADD_INTERSTNFAVOURITE_SUCCESS,
  payload: interestnfavourite,
})

export const addInterestNFavouriteFail = error => ({
  type: ADD_INTERSTNFAVOURITE_FAIL,
  payload: error,
})

export const updateInterestNFavourite = interestnfavourite => ({
  type: UPDATE_INTERSTNFAVOURITE,
  payload: interestnfavourite,
})

export const updateInterestNFavouriteSuccess = interestnfavourite => ({
  type: UPDATE_INTERSTNFAVOURITE_SUCCESS,
  payload: interestnfavourite,
})

export const updateInterestNFavouriteFail = error => ({
  type: UPDATE_INTERSTNFAVOURITE_FAIL,
  payload: error,
})

export const deleteInterestNFavourite = interestnfavourite => ({
  type: DELETE_INTERSTNFAVOURITE,
  payload: interestnfavourite,
})

export const deleteInterestNFavouriteSuccess = (response, interestNFavouriteId) => ({
  type: DELETE_INTERSTNFAVOURITE_SUCCESS,
  payload: response,
  interestNFavouriteId: interestNFavouriteId
})

export const deleteInterestNFavouriteFail = error => ({
  type: DELETE_INTERSTNFAVOURITE_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = (success) => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
});