/* NEWS */
export const GET_NEWS = "GET_NEWS"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_NEWS_FAIL = "GET_NEWS_FAIL"


export const GET_NEWS_BY_ID = "GET_NEWS_BY_ID"
export const GET_NEWS_BY_ID_SUCCESS = "GET_NEWS_BY_ID_SUCCESS"
export const GET_NEWS_BY_ID_FAIL = "GET_NEWS_BY_ID_FAIL"
/**
 * add NEWS
 */
export const ADD_NEWS = "ADD_NEWS"
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS"
export const ADD_NEWS_FAIL = "ADD_NEWS_FAIL"

/**
 * Edit NEWS
 */
export const UPDATE_NEWS = "UPDATE_NEWS"
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS"
export const UPDATE_NEWS_FAIL = "UPDATE_NEWS_FAIL"

/**
 * Delete NEWS
 */
export const DELETE_NEWS = "DELETE_NEWS"
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS"
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';
