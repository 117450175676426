import {
  GET_REPORTS,
  GET_REPORTS_FAIL,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_BY_ID,
  GET_REPORTS_BY_ID_SUCCESS,
  GET_REPORTS_BY_ID_FAIL,
  ADD_NEW_REPORTS,
  ADD_REPORTS_SUCCESS,
  ADD_REPORTS_FAIL,
  UPDATE_REPORTS,
  UPDATE_REPORTS_SUCCESS,
  UPDATE_REPORTS_FAIL,
  DELETE_REPORTS,
  DELETE_REPORTS_SUCCESS,
  DELETE_REPORTS_FAIL,
  UPDATE_DELETE_SUCCESS
} from "./actionTypes"

export const getReports = () => ({
  type: GET_REPORTS,
})

export const getReportsSuccess = Reports => ({
  type: GET_REPORTS_SUCCESS,
  payload: Reports,
})

export const getReportsFail = error => ({
  type: GET_REPORTS_FAIL,
  payload: error,
})

export const getReportsById = (reportsId) => ({
  type: GET_REPORTS_BY_ID,
  payload: reportsId,
})

export const getReportsByIdSuccess = Reports => ({
  type: GET_REPORTS_BY_ID_SUCCESS,
  payload: Reports,
})

export const getReportsByIdFail = error => ({
  type: GET_REPORTS_BY_ID_FAIL,
  payload: error,
})

export const addNewReports = reports => ({
  type: ADD_NEW_REPORTS,
  payload: reports,
})

export const addReportsSuccess = reports => ({
  type: ADD_REPORTS_SUCCESS,
  payload: reports,
})

export const addReportsFail = error => ({
  type: ADD_REPORTS_FAIL,
  payload: error,
})

export const updateReports = reports => ({
  type: UPDATE_REPORTS,
  payload: reports,
})

export const updateReportsSuccess = reports => ({
  type: UPDATE_REPORTS_SUCCESS,
  payload: reports,
})

export const updateReportsFail = error => ({
  type: UPDATE_REPORTS_FAIL,
  payload: error,
})

export const deleteReports = reports => ({
  type: DELETE_REPORTS,
  payload: reports,
})

export const deleteReportsSuccess = (response, reportsId) => ({
  type: DELETE_REPORTS_SUCCESS,
  payload: response,
  reportsId: reportsId
})

export const deleteReportsFail = error => ({
  type: DELETE_REPORTS_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = (success) => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
});