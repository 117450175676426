import {
  GET_TRANSACTION_FAIL,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_BY_ID_SUCCESS,
  GET_TRANSACTION_BY_ID_FAIL,
  GET_ACTIVECHATREQUESTHISTORY_BY_ID_SUCCESS,
  GET_ACTIVECHATREQUESTHISTORY_BY_ID_FAIL,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAIL,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAIL,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  transaction: [],
  transactionById: [],
  activeChatRequestHistoryById: [],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {},
}

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {},
      }

    case GET_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }

    case GET_TRANSACTION_BY_ID_SUCCESS:
      return {
        ...state,
        transactionById: action.payload,
        error: {},
      }

    case GET_TRANSACTION_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        transactionById: [],
      }

    case GET_ACTIVECHATREQUESTHISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        activeChatRequestHistoryById: action.payload,
        error: {},
      }

    case GET_ACTIVECHATREQUESTHISTORY_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        activeChatRequestHistoryById: [],
      }

    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: [...state.transaction, action.payload],
        addUpdateSuccess: true,
        error: {},
      }

    case ADD_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: state.transaction.map(transaction =>
          transaction.id === action.payload.id
            ? { transaction, ...action.payload }
            : transaction
        ),
        addUpdateSuccess: true,
        error: {},
      }

    case UPDATE_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: state.transaction.filter(
          transaction => transaction.id !== action.transactionId
        ),
        deleteSuccess: true,
        error: {},
      }

    case DELETE_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.payload,
      }

    default:
      return state
  }
}

export default Transaction
