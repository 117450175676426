import {
  GET_PORTFOLIO,
  GET_PORTFOLIO_FAIL,
  GET_PORTFOLIO_SUCCESS,
  GET_PORTFOLIO_BY_ID,
  GET_PORTFOLIO_BY_ID_SUCCESS,
  GET_PORTFOLIO_BY_ID_FAIL,
  ADD_NEW_PORTFOLIO,
  ADD_PORTFOLIO_SUCCESS,
  ADD_PORTFOLIO_FAIL,
  UPDATE_PORTFOLIO,
  UPDATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_FAIL,
  DELETE_PORTFOLIO,
  DELETE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_FAIL,
  UPDATE_DELETE_SUCCESS,
  RESET_ADD_UPDATE_SUCCESS
} from "./actionTypes"

export const getPortfolio = () => ({
  type: GET_PORTFOLIO,
})

export const getPortfolioSuccess = Portfolio => ({
  type: GET_PORTFOLIO_SUCCESS,
  payload: Portfolio,
})

export const getPortfolioFail = error => ({
  type: GET_PORTFOLIO_FAIL,
  payload: error,
})

export const getPortfolioById = (portfolioId) => ({
  type: GET_PORTFOLIO_BY_ID,
  payload: portfolioId,
})

export const getPortfolioByIdSuccess = Portfolio => ({
  type: GET_PORTFOLIO_BY_ID_SUCCESS,
  payload: Portfolio,
})

export const getPortfolioByIdFail = error => ({
  type: GET_PORTFOLIO_BY_ID_FAIL,
  payload: error,
})

export const addNewPortfolio = portfolio => ({
  type: ADD_NEW_PORTFOLIO,
  payload: portfolio,
})

export const addPortfolioSuccess = portfolio => ({
  type: ADD_PORTFOLIO_SUCCESS,
  payload: portfolio,
})

export const addPortfolioFail = error => ({
  type: ADD_PORTFOLIO_FAIL,
  payload: error,
})

export const updatePortfolio = portfolio => ({
  type: UPDATE_PORTFOLIO,
  payload: portfolio,
})

export const updatePortfolioSuccess = portfolio => ({
  type: UPDATE_PORTFOLIO_SUCCESS,
  payload: portfolio,
})

export const updatePortfolioFail = error => ({
  type: UPDATE_PORTFOLIO_FAIL,
  payload: error,
})

export const deletePortfolio = portfolioId => ({
  type: DELETE_PORTFOLIO,
  payload: portfolioId,
})

export const deletePortfolioSuccess = (response, portfolioId) => ({
  type: DELETE_PORTFOLIO_SUCCESS,
  payload: response,
  portfolioId: portfolioId
})

export const deletePortfolioFail = error => ({
  type: DELETE_PORTFOLIO_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = (success) => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
});

export const resetAddUpdateSuccessFlag = (success) => ({
  type: RESET_ADD_UPDATE_SUCCESS,
  payload: success,
});