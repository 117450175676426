import {
  GET_INSIGHTS_FAIL,
  GET_INSIGHTS_SUCCESS,
  GET_INSIGHTS_BY_ID_SUCCESS,
  GET_INSIGHTS_BY_ID_FAIL,
  ADD_INSIGHTS_SUCCESS,
  ADD_INSIGHTS_FAIL,
  UPDATE_INSIGHTS_SUCCESS,
  UPDATE_INSIGHTS_FAIL,
  DELETE_INSIGHTS_SUCCESS,
  DELETE_INSIGHTS_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  insights: [],
  insightsById : [],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {}
}

const Insights = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {}
      }

    case GET_INSIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }
      
    case GET_INSIGHTS_BY_ID_SUCCESS:
      return {
        ...state,
        insightsById: action.payload,
        error: {}
      }

    case GET_INSIGHTS_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        insightsById : [],
      }  

    case ADD_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: [...state.insights, action.payload],
        addUpdateSuccess: true,
        error: {}
      }

    case ADD_INSIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: state.insights.map(insights =>
          insights.id === action.payload.id
            ? { insights, ...action.payload }
            : insights
        ),
        addUpdateSuccess: true,
        error: {}
      }

    case UPDATE_INSIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: state.insights.filter(
          insights => insights.id !== action.insightsId
        ),
        deleteSuccess: true,
        error: {}
      }

    case DELETE_INSIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return { 
        ...state, 
        deleteSuccess: action.payload 
      };

    default:
      return state
  }
}

export default Insights
