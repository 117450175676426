import {
  GET_COMPANY_FAIL,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_BY_ID_FAIL,
  GET_COMPANY_BY_ID_SUCCESS,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  RESET_COMPANY_BY_ID,
  UPDATE_DELETE_SUCCESS,
  DELETE_SECTION_ENTRY_SUCCESS,
  DELETE_SECTION_ENTRY_FAIL
} from "./actionTypes"

const INIT_STATE = {
  company: [],
  companyById: [],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {}
}

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        companyById: [],
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {}
      }

    case GET_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        companyById: [],
        addUpdateSuccess: null,
        deleteSuccess: null,
      }

    case GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        companyById: action.payload,
        error: {}
      }
    
    case RESET_COMPANY_BY_ID:
      return {
        ...state,
        companyById: [],
        error: {}
      }

    case GET_COMPANY_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        company: [...state.company, action.payload],
        success: action.payload,
        addUpdateSuccess: true,
        error: {}
      }

    case ADD_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
        success: {}
      }

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: state.company.map(company =>
          company.id === action.payload.id
            ? { company, ...action.payload }
            : company
        ),
        success: action.payload,
        addUpdateSuccess: true,
        error: {}
      }

    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
        success: {}
      }

    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        company: state.company.filter(
          company => company.id !== action.companyId
        ),
        success: action.payload,
        deleteSuccess: true,
        error: {}
      }

    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
        success: {}
      }
    
    case UPDATE_DELETE_SUCCESS:
      return { 
        ...state, 
        deleteSuccess: action.payload 
      };
    
    case DELETE_SECTION_ENTRY_SUCCESS:
      return {
        ...state,
        success: action.payload,
        deleteSuccess: true,
        error: {}
      }

    case DELETE_SECTION_ENTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
        success: {}
      }

    default:
      return state
  }
}

export default Company
