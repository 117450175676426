import {
  GET_LANGUAGE,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_FAIL,
  GET_SUSTAIN_GOAL,
  GET_SUSTAIN_GOAL_SUCCESS,
  GET_SUSTAIN_GOAL_FAIL,
  GET_COUNTRY,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL
} from "./actionTypes"

export const getLanguage = lang => ({
  type: GET_LANGUAGE,
  payload: lang,
})

export const getLanguageSuccess = lang => ({
  type: GET_LANGUAGE_SUCCESS,
  payload: lang,
})

export const getLanguageFail = error => ({
  type: GET_LANGUAGE_FAIL,
  payload: error,
})

export const getSustainGoal = () => ({
  type: GET_SUSTAIN_GOAL,
})

export const getSustainGoalSuccess = data => ({
  type: GET_SUSTAIN_GOAL_SUCCESS,
  payload: data,
})

export const getSustainGoalFail = error => ({
  type: GET_SUSTAIN_GOAL_FAIL,
  payload: error,
})

export const getCountry = () => ({
  type: GET_COUNTRY,
})

export const getCountrySuccess = data => ({
  type: GET_COUNTRY_SUCCESS,
  payload: data,
})

export const getCountryFail = error => ({
  type: GET_COUNTRY_FAIL,
  payload: error,
})