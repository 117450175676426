import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_INTERSTNFAVOURITE,
  GET_INTERSTNFAVOURITE_BY_ID,
  ADD_INTERSTNFAVOURITE,
  DELETE_INTERSTNFAVOURITE,
  UPDATE_INTERSTNFAVOURITE,
} from "./actionTypes"
import {
  getInterestNFavouriteFail,
  getInterestNFavouriteSuccess,
  getInterestNFavouriteByIdFail,
  getInterestNFavouriteByIdSuccess,
  addInterestNFavouriteFail,
  addInterestNFavouriteSuccess,
  updateInterestNFavouriteSuccess,
  updateInterestNFavouriteFail,
  deleteInterestNFavouriteSuccess,
  deleteInterestNFavouriteFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getInterestNFavourite,
  /* addNewInterestNFavourite,
  updateInterestNFavourite,
  deleteInterestNFavourite, */
  getInterestNFavouriteById,
} from "helpers/fakebackend_helper"
import "react-toastify/dist/ReactToastify.css"

function* fetchInterestNFavourite() {
  try {
    const response = yield call(getInterestNFavourite)
    yield put(getInterestNFavouriteSuccess(response.data))
  } catch (error) {
    yield put(getInterestNFavouriteFail(error.response))
  }
}

function* fetchInterestNFavouriteById({ payload: interestnfavouriteId }) {
  console.log(interestnfavouriteId, "Inside request")
  try {
    const response = yield call(getInterestNFavouriteById, interestnfavouriteId)
    console.log(response, "response inside req")
    yield put(getInterestNFavouriteByIdSuccess(response.data))
  } catch (error) {
    yield put(getInterestNFavouriteByIdFail(error.response))
  }
}

function* onUpdateInterestNFavourite({ payload: interestnfavourite }) {
  try {
    const response = yield call(updateInterestNFavourite, interestnfavourite)
    yield put(updateInterestNFavouriteSuccess(response))
  } catch (error) {
    yield put(updateInterestNFavouriteFail(error.response))
  }
}

function* onDeleteInterestNFavourite({ payload: interestnfavourite }) {
  try {
    const response = yield call(deleteInterestNFavourite, interestnfavourite)
    yield put(deleteInterestNFavouriteSuccess(response, interestnfavourite))
  } catch (error) {
    yield put(deleteInterestNFavouriteFail(error.response))
  }
}

function* onAddNewInterestNFavourite({ payload: interestnfavourite }) {
  try {
    const response = yield call(addNewInterestNFavourite, interestnfavourite)

    yield put(addInterestNFavouriteSuccess(response))
  } catch (error) {
    yield put(addInterestNFavouriteFail(error.response))
  }
}

function* interestnfavouriteSaga() {
  yield takeEvery(GET_INTERSTNFAVOURITE, fetchInterestNFavourite)
  yield takeEvery(GET_INTERSTNFAVOURITE_BY_ID, fetchInterestNFavouriteById)
  yield takeEvery(ADD_INTERSTNFAVOURITE, onAddNewInterestNFavourite)
  yield takeEvery(UPDATE_INTERSTNFAVOURITE, onUpdateInterestNFavourite)
  yield takeEvery(DELETE_INTERSTNFAVOURITE, onDeleteInterestNFavourite)
}

export default interestnfavouriteSaga
