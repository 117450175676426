import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
//import { Container } from "reactstrap";
import {
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Card,
  Button,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import "./CompanyDetails.css"
//import {PersonaDetail} from "./personaDetail.js"
import UsersModal from "pages/Users/UserModal"
import { withRouter, Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"
import {
  getCompany as onGetCompany,
  addNewCompany as onAddNewCompany,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
} from "store/company/actions"

import {
  freezeUser,
  //addNewReports as onAddNewReports,
  //updateReports as onUpdateReports,
  //getReports as onGetReports,
  getUsersById as onGetUsersById,
  verifyUsers as onVerifyUsers,
} from "store/users/actions"
import ReasonModal from "pages/Users/ReasonModal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import AddEditPortfolioModal from "pages/Users/AddEditPortfolioModal"
import { deletePortfolio } from "store/actions"
import {
  resetAddUpdateSuccessFlag,
  updateDeleteSuccessFlag,
} from "store/portfolio/actions"

const LegalEntityDetails = () => {
  //const dispatch = useDispatch();

  const { company } = useSelector(state => ({
    company: state.Company.Company,
  }))
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search)
  const userId = searchParams.get("id")
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [addEditPortfolioModal, setAddEditPortfolioModal] = useState(false)
  const [selectedPortfolio, setSelectedPortfolio] = useState({})
  const [approveEnable, setApproveEnable] = useState(false)
  const { usersById, addUpdateSuccess, error, deleteSuccess, freezeSuccess } =
    useSelector(state => ({
      usersById: state.Users.usersById,
      error: state.Users.error,
      addUpdateSuccess: state.Users.addUpdateSuccess,
      deleteSuccess: state.Users.deleteSuccess,
      freezeSuccess: state.Users.freezeSuccess,
    }))
  const { companies } = useSelector(state => ({
    companies: state.Company.company,
  }))
  const { addUpdateSuccessPortfolio, deleteSuccessPortfolio } = useSelector(
    state => ({
      addUpdateSuccessPortfolio: state.Portfolio.addUpdateSuccess,
      deleteSuccessPortfolio: state.Portfolio.deleteSuccess,
    })
  )
  const history = useHistory()
  const [selectedUsers, setSelectedUsers] = useState({})
  const [modalImageUrl, setModalImageUrl] = useState("")
  const [companyDetail, setCompanyDetail] = useState([])
  const [uboDetail, setUboDetail] = useState([])
  const [lrDetail, setLrDetail] = useState([])
  const toggleViewModal = imageUrl => {
    setModalImageUrl(imageUrl)
    setModal1(!modal1)
  }
  const [documents, setDocuments] = useState([])
  useEffect(() => {
    if (userId !== null && userId !== undefined) {
      //call api to get company data;
      dispatch(onGetUsersById(userId))
      dispatch(onGetCompany())
    }
  }, [userId])

  useEffect(() => {
    //console.log('Insights', usersById)
    if (usersById.length > 0 && usersById !== null) {
      setSelectedUsers(usersById[0])
      //setDocuments(usersById[0]?.userdetail?.userlrdetail.filter(user => user.detailtype === "company").document)
      //setIsEdit(true)
      //assignInitialValues(usersById[0])
      setCompanyDetail(
        usersById[0]?.userdetail?.userlrdetail.filter(
          user => user.detailtype === "company"
        )
      )
      setUboDetail(
        usersById[0]?.userdetail?.userlrdetail.filter(
          user => user.detailtype === "ubo"
        )
      )
      setLrDetail(
        usersById[0]?.userdetail?.userlrdetail.filter(
          user => user.detailtype === "lr"
        )
      )
      setDocuments(
        usersById[0]?.userdetail?.userlrdetail.filter(
          user => user.detailtype === "company"
        )[0]?.document
      )
    }
  }, [usersById])
  const [selectedDocument, setSelectedDocument] = useState(false)
  const onClickApprove = () => {
    //setSelectedDocument(selectedDocument);
    //setModal2(!modal1)
    // console.log('documents', documents, uboDetail, lrDetail);

    usersById[0].userdetail.document = documents.concat(
      uboDetail[0].document,
      lrDetail[0].document
    )
    // console.log(usersById)
    const obj = {
      id: usersById[0].id,
      //"userdetail":[{
      rejectionreason: null,
      document: usersById[0].userdetail.document,
      //}]
    }
    
    obj["email"] = usersById[0]?.email;
    obj["firstname"] = usersById[0]?.userdetail?.firstname;
    obj["lastname"] = "";
    dispatch(onVerifyUsers(obj))
  }
  const [rejectType, setRejectType] = useState("")
  const onClickReject = type => {
    setRejectType(type)
    setModal2(!modal2)
    const obj = {
      id: usersById[0].id,
      //"userdetail":[{
      rejectionreason: null,
      document: usersById[0].userdetail.document,
      //}]
    }

    // if (type === "document") {
    //   obj["email"] = usersById[0]?.email
    // }
    obj["email"] = usersById[0]?.email;
    obj["firstname"] = companyDetail[0]?.companyname;
    obj["lastname"] = "";
    
    //dispatch(onVerifyUsers(obj))
    setModalImageUrl(obj)
  }

  const onClickFreeze = status => {
    const obj = {
      id: usersById[0].id,
      isActive: status === "freeze" ? true : false,
    }
    dispatch(freezeUser(obj))
  }

  useEffect(() => {
    if (freezeSuccess === true && usersById[0]?.isActive) {
      toast.success("Account has been freezed!")
      dispatch(onGetUsersById(userId))
    } else if (freezeSuccess === true && !usersById[0]?.isActive) {
      toast.success("Account has been Unfreezed!")
      dispatch(onGetUsersById(userId))
    } else if (freezeSuccess === false) {
      toast.error("Something went wrong!")
    }
  }, [freezeSuccess])

  const toggleDocumentStatus = (id, status, fieldName, i, j) => {
    if (fieldName === "doc") {
      setDocuments(
        documents.map((doc, i) => {
          if (doc.id === id) {
            documents[i].status = status
            return { ...doc, status: status }
          }
          return doc
        })
      )
    } /* else if(fieldName === "ubo"){ 
        uboDetail[i].document[j].status = status
    }
    else if(fieldName === "lr"){ 
        lrDetail[i].document[j].status = status
    } */ else if (fieldName === "ubo") {
      //uboDetail[i].document[j].status = status
      let tempUBODetails = [...uboDetail]

      tempUBODetails?.map((innerArray, outerIdx) => {
        if (innerArray?.document) {
          return innerArray?.document.map((doc, idx) => {
            if (doc.id === id) {
              // const updatedDocuments = [...innerArray?.document];
              // updatedDocuments[idx].status = status;
              doc.status = status
              // return { ...doc, status: status };
              return doc
            }
            return doc
          })
        }
        return innerArray
      })
      //console.log('tempUBODetails', tempUBODetails);
      setUboDetail(tempUBODetails)
    } else if (fieldName === "lr") {
      //lrDetail[i].document[j].status = status
      let tempLRDetails = [...lrDetail]

      tempLRDetails?.map((innerArray, outerIdx) => {
        if (innerArray?.document) {
          return innerArray?.document.map((doc, idx) => {
            if (doc.id === id) {
              // const updatedDocuments = [...innerArray?.document];
              // updatedDocuments[idx].status = status;
              doc.status = status
              // return { ...doc, status: status };
              return doc
            }
            return doc
          })
        }
        return innerArray
      })
      //console.log('tempUBODetails', tempLRDetails);
      setLrDetail(tempLRDetails)
    }
    console.log(usersById[0].userdetail.document, "Docssss")

    /* approveArray.push(documents)
    approveArray.push(documents)
    approveArray.push(documents)
    console.log("uboDocument-->",uboDocument)
    console.log("lrDocument-->",lrDocument) */

    setApproveEnable(
      areAllStatusApproved([
        ...documents,
        ...lrDetail.flatMap(item => item.document.map(doc => doc)),
        ...uboDetail.flatMap(item => item.document.map(doc => doc)),
      ])
    )
  }
  const areAllStatusApproved = array => {
    //console.log(array)
    return array.every(obj => obj.status === "Approved")
  }

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
    else if (addUpdateSuccess) {
      toast.success(`User updated successfully`, { autoClose: 2000 })
      //validation.resetForm();
      history.push("/users")
    } else if (
      !addUpdateSuccess &&
      addUpdateSuccess !== null &&
      addUpdateSuccess !== undefined &&
      addUpdateSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
  }, [error, addUpdateSuccess])

  /* const companyDetail = usersById[0]?.userdetail?.userlrdetail.filter(user => user.detailtype === "company");
  const uboDetail = usersById[0]?.userdetail?.userlrdetail.filter(user => user.detailtype === "ubo");
  const lrDetail = usersById[0]?.userdetail?.userlrdetail.filter(user => user.detailtype === "lr"); */
  //console.log(companyDetail)

  // Add Update portfolio function
  useEffect(() => {
    console.log("addUpdateSuccessPortfolio", addUpdateSuccessPortfolio)
    setAddEditPortfolioModal(false)
    setSelectedPortfolio({})
    if (addUpdateSuccessPortfolio) {
      toast.success("Portfolio data action successfull.")
      dispatch(onGetUsersById(userId))
      dispatch(resetAddUpdateSuccessFlag(null))
    } else if (addUpdateSuccessPortfolio === false) {
      toast.error("Something went wrong!")
      dispatch(resetAddUpdateSuccessFlag(null))
    }
  }, [addUpdateSuccessPortfolio])

  const onClickDeletePortfolio = item => {
    console.log("item?.id", item?.id)
    dispatch(deletePortfolio(item?.id))
  }

  useEffect(() => {
    console.log("deleteSuccess", deleteSuccessPortfolio)
    if (deleteSuccessPortfolio) {
      toast.success("Portfolio deleted successfully.")
      dispatch(onGetUsersById(userId))
      dispatch(updateDeleteSuccessFlag(null))
    } else if (deleteSuccessPortfolio === false) {
      toast.error("Something went wrong!")
      dispatch(updateDeleteSuccessFlag(null))
    }
  }, [deleteSuccessPortfolio])

  return (
    <>
      {usersById && usersById !== undefined ? (
        <>
          <UsersModal
            isOpen={modal1}
            toggle={toggleViewModal}
            imageUrl={modalImageUrl}
          />
          <ReasonModal
            isOpen={modal2}
            toggle={onClickReject}
            imageUrl={modalImageUrl}
            rejectType={rejectType}
          />
          <AddEditPortfolioModal
            isOpen={addEditPortfolioModal}
            companyData={companies}
            selectedPortfolio={selectedPortfolio}
            closeModal={() => {
              setAddEditPortfolioModal(false)
            }}
            userId={userId}
          />
          <div className="page-content">
            <MetaTags>
              <title>Legal Entity</title>
            </MetaTags>
            <Container fluid={true}>
              <Breadcrumbs title="Users" breadcrumbItem="Legal Entity" />
              {/* <Row>
                            <Col xs="12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between float-right">
                                    <Button
                                    color="primary"
                                    className="btn-rounded "
                                    >
                                    Remove
                                    </Button>
                                </div>    
                            </Col>
                        </Row> */}
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <CardTitle className="h4">Company Details</CardTitle>
                        <Col>
                          <label
                            htmlFor="name"
                            className="col-md-2 col-form-label"
                          >
                            Name
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Enter Company Name"
                              value={companyDetail[0]?.companyname || ""}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col>
                          <label
                            htmlFor="yearOfInception"
                            className="col-md-4 col-form-label"
                          >
                            Year of Inception
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              id="yearOfInception"
                              name="yearOfInception"
                              placeholder="Enter Year of Inception"
                              type="text"
                              value={
                                companyDetail[0]?.companyincorporatedate || ""
                              }
                              disabled
                            />
                          </div>
                        </Col>
                        <Col>
                          <label
                            htmlFor="taxId"
                            className="col-md-10 col-form-label"
                          >
                            Tax identification No.
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              id="taxId"
                              name="taxId"
                              placeholder="Enter Tax identification No."
                              type="text"
                              value={companyDetail[0]?.taxnumber || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <CardTitle className="h4">Company Documents</CardTitle>
                        <Row>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Sub Type</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {companyDetail[0]?.document.map(
                                    (doc, idx) => (
                                      <tr key={idx}>
                                        <th scope="row">{doc.id}</th>
                                        <td>{doc.fileName}</td>
                                        <td>{doc.documenttype}</td>
                                        <td>{doc.documentsubtype}</td>
                                        <td>
                                          {doc.status == "Decline"
                                            ? "Rejected"
                                            : doc.status}
                                        </td>
                                        <td>
                                          {/* <button type="button" className="btn btn-light btn-sm">View</button>
                                                                    <button type="button" className="btn btn-light btn-sm">Approve</button>
                                                                    <button type="button" className="btn btn-light btn-sm">Reject</button> */}
                                          <div className="d-flex gap-3">
                                            <div
                                              className="text"
                                              onClick={() =>
                                                toggleViewModal(doc.documenturl)
                                              }
                                            >
                                              <i
                                                className="mdi mdi-eye font-size-18"
                                                id="viewtooltip"
                                              />
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="viewtooltip"
                                              >
                                                View
                                              </UncontrolledTooltip>
                                            </div>
                                            {usersById[0]?.status !==
                                            "approved" ? (
                                              <div
                                                className="text-success"
                                                onClick={() =>
                                                  toggleDocumentStatus(
                                                    doc.id,
                                                    "Approved",
                                                    "doc"
                                                  )
                                                }
                                              >
                                                <i
                                                  className="mdi mdi-check-decagram font-size-18"
                                                  id="approvetooltip"
                                                />
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target="approvetooltip"
                                                >
                                                  Approve
                                                </UncontrolledTooltip>
                                              </div>
                                            ) : null}
                                            {usersById[0]?.status !==
                                            "approved" ? (
                                              <div
                                                className="text-danger"
                                                onClick={() =>
                                                  toggleDocumentStatus(
                                                    doc.id,
                                                    "Decline",
                                                    "doc"
                                                  )
                                                }
                                              >
                                                <i
                                                  className="mdi mdi-alpha-x-circle font-size-18"
                                                  id="rejecttooltip"
                                                />
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target="rejecttooltip"
                                                >
                                                  Reject
                                                </UncontrolledTooltip>
                                              </div>
                                            ) : null}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    {uboDetail?.map((innerArray, outerIdx) => (
                      <CardBody key={`${outerIdx}`}>
                        {innerArray?.document?.length > 0 ? (
                          <>
                            <Row className="mb-3">
                              <CardTitle className="h4">
                                UBO&apos;s Details({outerIdx + 1})
                              </CardTitle>
                              <Col>
                                <Label for="name">Name</Label>
                                <Input
                                  id="name"
                                  name="name"
                                  placeholder="Enter UBO Name"
                                  type="text"
                                  value={
                                    uboDetail[outerIdx]?.firstname +
                                      " " +
                                      uboDetail[outerIdx]?.lastname || ""
                                  }
                                  disabled
                                />
                              </Col>
                              <Col>
                                <Label for="emailId">Email Id</Label>
                                <Input
                                  id="emailId"
                                  name="emailId"
                                  placeholder="Enter Email Id"
                                  type="email"
                                  value={uboDetail[outerIdx]?.email}
                                  disabled
                                />
                              </Col>
                              <Col>
                                <Label for="address">Address</Label>
                                <Input
                                  id="address"
                                  name="address"
                                  placeholder="Enter Your Address"
                                  type="text"
                                  value={
                                    (uboDetail[outerIdx].document[1].street
                                      ? uboDetail[outerIdx].document[1].street
                                      : "") +
                                    ", " +
                                    (uboDetail[outerIdx].document[1].city
                                      ? uboDetail[outerIdx].document[1].city
                                      : "") +
                                    ", " +
                                    (uboDetail[outerIdx].document[1].provience
                                      ? uboDetail[outerIdx].document[1]
                                          .provience
                                      : "") +
                                    ", " +
                                    (uboDetail[outerIdx].document[1].zipcode
                                      ? uboDetail[outerIdx].document[1].zipcode
                                      : "")
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <CardTitle className="h4">
                                UBO&apos;s Documents({outerIdx + 1})
                              </CardTitle>
                              <Col>
                                <div className="table-responsive">
                                  <Table className="align-middle mb-0">
                                    <thead>
                                      <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Sub Type</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {uboDetail[outerIdx]?.document.map(
                                        (doc, innerIdx) => (
                                          <tr key={innerIdx}>
                                            <td scope="row">{doc.id}</td>
                                            <td>{doc.fileName}</td>
                                            <td>{doc.documenttype}</td>
                                            <td>{doc.documentsubtype}</td>
                                            <td>
                                              {doc.status == "Decline"
                                                ? "Rejected"
                                                : doc.status}
                                            </td>
                                            <td>
                                              <div className="d-flex gap-3">
                                                <div
                                                  className="text"
                                                  onClick={() =>
                                                    toggleViewModal(
                                                      doc.documenturl
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="mdi mdi-eye font-size-18"
                                                    id="viewtooltip"
                                                  />
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target="viewtooltip"
                                                  >
                                                    View
                                                  </UncontrolledTooltip>
                                                </div>
                                                {usersById[0]?.status !==
                                                "approved" ? (
                                                  <div
                                                    className="text-success"
                                                    onClick={() =>
                                                      toggleDocumentStatus(
                                                        doc.id,
                                                        "Approved",
                                                        "ubo",
                                                        outerIdx,
                                                        innerIdx
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="mdi mdi-check-decagram font-size-18"
                                                      id="approvetooltip"
                                                    />
                                                    <UncontrolledTooltip
                                                      placement="top"
                                                      target="approvetooltip"
                                                    >
                                                      Approve
                                                    </UncontrolledTooltip>
                                                  </div>
                                                ) : null}
                                                {usersById[0]?.status !==
                                                "approved" ? (
                                                  <div
                                                    className="text-danger"
                                                    onClick={() =>
                                                      toggleDocumentStatus(
                                                        doc.id,
                                                        "Decline",
                                                        "ubo",
                                                        outerIdx,
                                                        innerIdx
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="mdi mdi-alpha-x-circle font-size-18"
                                                      id="rejecttooltip"
                                                    />
                                                    <UncontrolledTooltip
                                                      placement="top"
                                                      target="rejecttooltip"
                                                    >
                                                      Reject
                                                    </UncontrolledTooltip>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </Col>
                            </Row>
                          </>
                        ) : null}
                      </CardBody>
                    ))}
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    {lrDetail?.map((innerArray, outerIdx) => (
                      <CardBody key={outerIdx}>
                        {lrDetail[outerIdx]?.document.length > 0 ? (
                          <>
                            <Row className="mb-3">
                              <CardTitle className="h4">
                                Legal Representative&apos;s Details (
                                {outerIdx + 1})
                              </CardTitle>
                              <Col>
                                <Label for="name">Name</Label>
                                <Input
                                  id="name"
                                  name="name"
                                  placeholder="Enter Your Name"
                                  type="text"
                                  value={
                                    lrDetail[outerIdx]?.firstname +
                                    " " +
                                    lrDetail[outerIdx]?.lastname
                                  }
                                  disabled
                                />
                              </Col>
                              <Col>
                                <Label for="emailId">Email Id</Label>
                                <Input
                                  id="emailId"
                                  name="emailId"
                                  placeholder="Enter Email Id"
                                  type="email"
                                  value={lrDetail[outerIdx]?.email}
                                  disabled
                                />
                              </Col>
                              <Col>
                                <Label for="address">Address</Label>
                                <textarea
                                  className="form-control"
                                  id="address"
                                  rows="3"
                                  placeholder="Enter full address"
                                  value={
                                    (lrDetail[outerIdx].document[1].street
                                      ? lrDetail[outerIdx].document[1].street
                                      : "") +
                                    ", " +
                                    (lrDetail[outerIdx].document[1].city
                                      ? lrDetail[outerIdx].document[1].city
                                      : "") +
                                    ", " +
                                    (lrDetail[outerIdx].document[1].provience
                                      ? lrDetail[outerIdx].document[1].provience
                                      : "") +
                                    ", " +
                                    (lrDetail[outerIdx].document[1].zipcode
                                      ? lrDetail[outerIdx].document[1].zipcode
                                      : "")
                                  }
                                  disabled
                                />
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <CardTitle className="h4">
                                Legal Representative&apos;s Documents (
                                {outerIdx + 1})
                              </CardTitle>
                              <Row>
                                <Col>
                                  <div className="table-responsive">
                                    <Table className="align-middle mb-0">
                                      <thead>
                                        <tr>
                                          <th>Id</th>
                                          <th>Name</th>
                                          <th>Type</th>
                                          <th>Sub Type</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {lrDetail[outerIdx]?.document.map(
                                          (doc, innerIdx) => (
                                            <tr key={innerIdx}>
                                              <td scope="row">{doc.id}</td>
                                              <td>{doc.fileName}</td>
                                              <td>{doc.documenttype}</td>
                                              <td>{doc.documentsubtype}</td>
                                              <td>
                                                {doc.status == "Decline"
                                                  ? "Rejected"
                                                  : doc.status}
                                              </td>
                                              <td>
                                                <div className="d-flex gap-3">
                                                  <div
                                                    className="text"
                                                    onClick={() =>
                                                      toggleViewModal(
                                                        doc.documenturl
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="mdi mdi-eye font-size-18"
                                                      id="viewtooltip"
                                                    />
                                                    <UncontrolledTooltip
                                                      placement="top"
                                                      target="viewtooltip"
                                                    >
                                                      View
                                                    </UncontrolledTooltip>
                                                  </div>
                                                  {usersById[0]?.status !==
                                                  "approved" ? (
                                                    <div
                                                      className="text-success"
                                                      onClick={() =>
                                                        toggleDocumentStatus(
                                                          doc.id,
                                                          "Approved",
                                                          "lr"
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className="mdi mdi-check-decagram font-size-18"
                                                        id="approvetooltip"
                                                      />
                                                      <UncontrolledTooltip
                                                        placement="top"
                                                        target="approvetooltip"
                                                      >
                                                        Approve
                                                      </UncontrolledTooltip>
                                                    </div>
                                                  ) : null}
                                                  {usersById[0]?.status !==
                                                  "approved" ? (
                                                    <div
                                                      className="text-danger"
                                                      onClick={() =>
                                                        toggleDocumentStatus(
                                                          doc.id,
                                                          "Decline",
                                                          "lr"
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className="mdi mdi-alpha-x-circle font-size-18"
                                                        id="rejecttooltip"
                                                      />
                                                      <UncontrolledTooltip
                                                        placement="top"
                                                        target="rejecttooltip"
                                                      >
                                                        Reject
                                                      </UncontrolledTooltip>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </Col>
                              </Row>
                            </Row>
                          </>
                        ) : null}
                      </CardBody>
                    ))}
                  </Card>
                </Col>
              </Row>
              {/* User Relation Integration */}
              {usersById[0]?.UserRelations &&
              usersById[0]?.UserRelations.length > 0 ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row className="mb-3">
                          <CardTitle className="h4">Advisors Details</CardTitle>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Email Id</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {usersById[0]?.UserRelations.map(item => {
                                    return (
                                      item.requestStatus === "approved" && (
                                        <tr>
                                          <td>{item?.advisor?.id}</td>
                                          <td>
                                            {
                                              item?.advisor?.userdetail
                                                ?.firstname
                                            }{" "}
                                            {
                                              item?.advisor?.userdetail
                                                ?.lastname
                                            }
                                          </td>
                                          <td>{item?.advisor?.email}</td>
                                        </tr>
                                      )
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              {usersById[0]?.status === "approved" ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row className="mb-3">
                          <CardTitle className="h4">Portfolio</CardTitle>
                          <Row>
                            <Col xs="12">
                              <div className="page-title-box d-sm-flex align-items-center justify-content-between float-right">
                                <Button
                                  color="primary"
                                  className="btn-rounded"
                                  onClick={() => {
                                    setSelectedPortfolio({})
                                    setAddEditPortfolioModal(true)
                                  }}
                                >
                                  Add Portfolio
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Holdings</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {usersById[0]?.Portfolios.length > 0 ? (
                                    <>
                                      {usersById[0]?.Portfolios.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <tr key={index}>
                                                <th scope="row">{item?.id}</th>
                                                <td>
                                                  {item?.Company_details?.name}
                                                </td>
                                                <td>{item?.count}</td>
                                                <td>
                                                  <div className="d-flex gap-3">
                                                    <div
                                                      className="text"
                                                      onClick={() => {
                                                        setSelectedPortfolio(
                                                          item
                                                        )
                                                        setAddEditPortfolioModal(
                                                          true
                                                        )
                                                      }}
                                                    >
                                                      <i
                                                        className="mdi mdi-pencil-circle font-size-18"
                                                        id="edittooltip"
                                                      />
                                                      <UncontrolledTooltip
                                                        placement="top"
                                                        target="edittooltip"
                                                      >
                                                        Edit
                                                      </UncontrolledTooltip>
                                                    </div>
                                                    <div
                                                      className="text-danger"
                                                      onClick={() =>
                                                        onClickDeletePortfolio(
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className="mdi mdi-delete font-size-18"
                                                        id="deletetooltip"
                                                      />
                                                      <UncontrolledTooltip
                                                        placement="top"
                                                        target="deletetooltip"
                                                      >
                                                        Delete
                                                      </UncontrolledTooltip>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td colSpan={7} className="no-data">
                                        No portfolio data available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              {usersById[0]?.DeviceDetails &&
              usersById[0]?.DeviceDetails.length > 0 ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row className="mb-3">
                          <CardTitle className="h4">Active Devices</CardTitle>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Device Id</th>
                                    <th>Device Type</th>
                                    <th>Time Zone</th>
                                    <th>Connection Type</th>
                                    {/* <th>Status</th>
                                                          <th>Action</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {usersById[0]?.DeviceDetails.map(
                                    (item, idx) => (
                                      <tr key={idx}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.deviceId}</td>
                                        <td>{item.deviceType}</td>
                                        <td>{item.timeZone}</td>
                                        <td>{item.connectionType}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              {/* Complaints Integration Pending */}
              {usersById[0]?.Complaints &&
              usersById[0]?.Complaints.length > 0 ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row className="mb-3">
                          <CardTitle className="h4">Report Filed</CardTitle>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Complaint Description</th>
                                    <th>Resolve</th>
                                    <th>Allot an offices</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td>Description 1</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                      >
                                        Resolve
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={toggleViewModal}
                                      >
                                        Allot an offices
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">2</th>
                                    <td>Description 2</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                      >
                                        Resolve
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={toggleViewModal}
                                      >
                                        Allot an offices
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              {usersById[0]?.status !== "approved" &&
              usersById[0]?.status !== "freeze" ? (
                <Row>
                  <Col xs="12">
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        style={{ marginRight: "20px" }}
                        onClick={() => { history.push(`/add-lr?id=${userId}`) }}
                      >
                        Edit Details
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        style={{ marginRight: "20px" }}
                        disabled={!approveEnable}
                        onClick={onClickApprove}
                      >
                        Approve
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        onClick={() => onClickReject("user")}
                      >
                        Reject
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        onClick={() => onClickReject("document")}
                        style={{ marginLeft: "20px" }}
                      >
                        Reject Document
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs="12">
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        style={{ marginRight: "20px" }}
                        onClick={() => { history.push(`/add-lr?id=${userId}`) }}
                      >
                        Edit Details
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          onClickFreeze(usersById[0]?.status)
                        }}
                      >
                        {usersById[0]?.status === "freeze"
                          ? "Unfreeze Account"
                          : "Freeze Account"}
                      </button>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </>
      ) : null}
    </>
  )
}

export default LegalEntityDetails
