import {
  GET_REPORTS_FAIL,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_BY_ID_FAIL,
  GET_REPORTS_BY_ID_SUCCESS,
  ADD_REPORTS_SUCCESS,
  ADD_REPORTS_FAIL,
  UPDATE_REPORTS_SUCCESS,
  UPDATE_REPORTS_FAIL,
  DELETE_REPORTS_SUCCESS,
  DELETE_REPORTS_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  reports: [],
  reportsById : [],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {}
}

const Reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {}
      }

    case GET_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }

    case GET_REPORTS_BY_ID_SUCCESS:
        return {
          ...state,
          reportsById: action.payload,
          error: {}
        }
  
    case GET_REPORTS_BY_ID_FAIL:
        return {
          ...state,
          error: action.payload,
          reportsById : [],
        }

    case ADD_REPORTS_SUCCESS:
      return {
        ...state,
        reports: [...state.reports, action.payload],
        addUpdateSuccess: true,
        error: {}
      }

    case ADD_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_REPORTS_SUCCESS:
      return {
        ...state,
        reports: state.reports.map(reports =>
          reports.id === action.payload.id
            ? { reports, ...action.payload }
            : reports
        ),
        addUpdateSuccess: true,
        error: {}
      }

    case UPDATE_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_REPORTS_SUCCESS:
      return {
        ...state,
        reports: state.reports.filter(
          reports => reports.id !== action.reportsId
        ),
        deleteSuccess: true,
        error: {}
      }

    case DELETE_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return { 
        ...state, 
        deleteSuccess: action.payload 
      };

    default:
      return state
  }
}

export default Reports
