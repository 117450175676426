import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_TRANSACTION,
  GET_TRANSACTION_BY_ID,
  GET_ACTIVECHATREQUESTHISTORY_BY_ID,
  ADD_NEW_TRANSACTION,
  DELETE_TRANSACTION,
  UPDATE_TRANSACTION,
} from "./actionTypes"
import {
  getTransactionFail,
  getTransactionSuccess,
  getTransactionByIdFail,
  getTransactionByIdSuccess,
  getActiveChatRequestHistoryByIdSuccess,
  getActiveChatRequestHistoryByIdFail,
  addTransactionFail,
  addTransactionSuccess,
  updateTransactionSuccess,
  updateTransactionFail,
  deleteTransactionSuccess,
  deleteTransactionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTransaction,
  /* addNewTransaction,
  updateTransaction,
  deleteTransaction, */
  getActiveChatRequestHistoryById,
  updateTransaction,
  getTransactionById,
} from "helpers/fakebackend_helper"
import "react-toastify/dist/ReactToastify.css"

function* fetchTransaction() {
  try {
    const response = yield call(getTransaction)
    yield put(getTransactionSuccess(response.data))
  } catch (error) {
    yield put(getTransactionFail(error.response))
  }
}

function* fetchTransactionById({ payload: transactionId }) {
  try {
    const response = yield call(getTransactionById, transactionId)
    yield put(getTransactionByIdSuccess(response.data))
  } catch (error) {
    yield put(getTransactionByIdFail(error.response))
  }
}

function* fetchActiveChatRequestHistoryById({ payload: activerequestid }) {
  console.log("inside activechatrequhistory", activerequestid)
  try {
    const response = yield call(
      getActiveChatRequestHistoryById,
      activerequestid
    )
    yield put(getActiveChatRequestHistoryByIdSuccess(response.data))
  } catch (error) {
    yield put(getActiveChatRequestHistoryByIdFail(error.response))
  }
}

function* onUpdateTransaction({ payload: transaction }) {
  try {
    const response = yield call(updateTransaction, transaction)
    yield put(updateTransactionSuccess(response))
  } catch (error) {
    yield put(updateTransactionFail(error.response))
  }
}

function* onDeleteTransaction({ payload: transaction }) {
  try {
    const response = yield call(deleteTransaction, transaction)
    yield put(deleteTransactionSuccess(response, transaction))
  } catch (error) {
    yield put(deleteTransactionFail(error.response))
  }
}

function* onAddNewTransaction({ payload: transaction }) {
  try {
    const response = yield call(addNewTransaction, transaction)

    yield put(addTransactionSuccess(response))
  } catch (error) {
    yield put(addTransactionFail(error.response))
  }
}

function* transactionSaga() {
  yield takeEvery(GET_TRANSACTION, fetchTransaction)
  yield takeEvery(GET_TRANSACTION_BY_ID, fetchTransactionById)
  yield takeEvery(
    GET_ACTIVECHATREQUESTHISTORY_BY_ID,
    fetchActiveChatRequestHistoryById
  )
  yield takeEvery(ADD_NEW_TRANSACTION, onAddNewTransaction)
  yield takeEvery(UPDATE_TRANSACTION, onUpdateTransaction)
  yield takeEvery(DELETE_TRANSACTION, onDeleteTransaction)
}

export default transactionSaga
