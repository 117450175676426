import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
})

export const getNotification = () => ({
  type: GET_NOTIFICATIONS,
})

export const getNotificationSuccess = Notification => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: Notification,
})

export const getNotificationFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
})
