import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_NEWS,
  GET_NEWS_BY_ID,
  ADD_NEWS,
  DELETE_NEWS,
  UPDATE_NEWS,
} from "./actionTypes"
import {
  getNewsFail,
  getNewsSuccess,
  getNewsByIdFail,
  getNewsByIdSuccess,
  addNewsFail,
  addNewsSuccess,
  updateNewsSuccess,
  updateNewsFail,
  deleteNewsSuccess,
  deleteNewsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getNews,
  addNewNews,
  updateNews,
  deleteNews,
  getNewsById,
} from "helpers/fakebackend_helper"
import "react-toastify/dist/ReactToastify.css"

function* fetchNews() {
  try {
    const response = yield call(getNews)
    yield put(getNewsSuccess(response.data))
  } catch (error) {
    yield put(getNewsFail(error.response))
  }
}

function* fetchNewsById({ payload: newsId }) {
  try {
    console.log(payload, "News Payload")
    const response = yield call(getNewsById, newsId)
    console.log("news saga", response.data)
    yield put(getNewsByIdSuccess(response.data))
    console.log("news saga 1", response.data)
  } catch (error) {
    yield put(getNewsByIdFail(error.response))
  }
}

function* onUpdateNews({ payload: news }) {
  try {
    const response = yield call(updateNews, news)
    yield put(updateNewsSuccess(response))
  } catch (error) {
    yield put(updateNewsFail(error.response))
  }
}

function* onDeleteNews({ payload: news }) {
  try {
    const response = yield call(deleteNews, news)
    yield put(deleteNewsSuccess(response, news))
  } catch (error) {
    yield put(deleteNewsFail(error.response))
  }
}

function* onAddNewNews({ payload: news }) {
  try {
    const response = yield call(addNewNews, news)

    yield put(addNewsSuccess(response))
  } catch (error) {
    yield put(addNewsFail(error.response))
  }
}

function* newsSaga() {
  yield takeEvery(GET_NEWS, fetchNews)
  yield takeEvery(GET_NEWS_BY_ID, fetchNewsById)
  yield takeEvery(ADD_NEWS, onAddNewNews)
  yield takeEvery(UPDATE_NEWS, onUpdateNews)
  yield takeEvery(DELETE_NEWS, onDeleteNews)
}

export default newsSaga
