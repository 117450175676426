import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
//import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";

import companySaga from "./company/saga"
import documentUploadSaga from "./document-upload/saga"
import commonSaga from "./common/saga"
import highlightsSaga from "./highlights/saga"
import newsSaga from "./news/saga"
import insightsSaga from "./insights/saga"
import reportsSaga from "./reports/saga"
import usersSaga from "./users/saga"
import interestnfavouriteSaga from "./interestnfavourite/saga"
import transactionSaga from "./transaction/saga"
import portfolioSaga from "./portfolio/saga"
import complaintsSaga from "./complaints/saga"
import userroleSaga from "./userRole/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    //fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(companySaga),
    fork(documentUploadSaga),
    fork(commonSaga),
    fork(highlightsSaga),
    fork(newsSaga),
    fork(insightsSaga),
    fork(reportsSaga),
    fork(usersSaga),
    fork(interestnfavouriteSaga),
    fork(transactionSaga),
    fork(portfolioSaga),
    fork(userroleSaga),
    fork(complaintsSaga)
  ])
}
