import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { useFormik } from "formik"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import classnames from "classnames"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Accordion,
  AccordionHeader,
  AccordionItem,
  CardText,
  CardTitle,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import {
  getHighlights as onGetHighlights,
  deleteHighlights as onDeleteHighlights,
  updateDeleteSuccessFlag as updateHighlightDeleteSuccessFlag,
} from "store/highlights/actions"
import {
  getNews as onGetNews,
  deleteNews as onDeleteNews,
  updateDeleteSuccessFlag as updateNewsDeleteSuccessFlag,
} from "store/news/actions"
import {
  getInsights as onGetInsights,
  deleteInsights as onDeleteInsights,
  updateDeleteSuccessFlag as updateInsightDeleteSuccessFlag,
} from "store/insights/actions"
import {
  getReports as onGetReports,
  deleteReports as onDeleteReports,
  updateDeleteSuccessFlag as updateReportDeleteSuccessFlag,
} from "store/reports/actions"
import { toast } from "react-toastify"

const Templates = props => {
  const history = useHistory()
  const { highlights, error, highlightsDeleteSuccess } = useSelector(state => ({
    highlights: state.Highlights.highlights,
    error: state.Highlights.error,
    deleteSuccess: state.Highlights.deleteSuccess,
  }))

  const [highlightsList, setHighlightsList] = useState([])
  const [selectedHighlights, setSelectedHighlights] = useState([])
  useEffect(() => {
    dispatch(onGetHighlights())
  }, [])

  useEffect(() => {
    if (error?.status === 401) {
      history.push("/login")
    } else if (highlightsDeleteSuccess) {
      toast.success(`Highlight deleted successfully`, { autoClose: 2000 })
    } else if (
      !highlightsDeleteSuccess &&
      highlightsDeleteSuccess !== null &&
      highlightsDeleteSuccess !== undefined &&
      highlightsDeleteSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
    dispatch(updateHighlightDeleteSuccessFlag(null))
  }, [error, highlightsDeleteSuccess])

  //delete Highlights
  const [deleteModalHighlights, setDeleteModalHighlights] = useState(false)

  const onClickDeleteHighlights = selectedHighlights => {
    setSelectedHighlights(selectedHighlights)
    setDeleteModal(true)
  }

  const handleDeleteHighlights = () => {
    if (selectedHighlights.id) {
      dispatch(onDeleteHighlights(selectedHighlights.id))
      setDeleteModal(false)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChangeHighlights = (type, { page, searchText }) => {
    setHighlightsList(
      highlights.filter(highlights =>
        Object.keys(highlights).some(key =>
          highlights[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  const highlightsPageOptions = {
    sizePerPage: 5,
    totalSize: highlights.length, // replace later with size(orders),
    custom: true,
  }

  //NEWS changes
  const { news, error1, newsDeleteSuccess } = useSelector(state => ({
    news: state.News.newsList,
    error1: state.News.error,
    deleteSuccess: state.News.deleteSuccess,
  }))

  const [newsList, setNewsList] = useState([])
  const [selectedNews, setSelectedNews] = useState([])
  useEffect(() => {
    dispatch(onGetNews())
  }, [])

  useEffect(() => {
    if (error1?.status === 401) {
      history.push("/login")
    } else if (newsDeleteSuccess) {
      toast.success(`News deleted successfully`, { autoClose: 2000 })
    } else if (
      !newsDeleteSuccess &&
      newsDeleteSuccess !== null &&
      newsDeleteSuccess !== undefined &&
      newsDeleteSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
    dispatch(updateNewsDeleteSuccessFlag(null))
  }, [error1, newsDeleteSuccess])

  //delete News
  const [deleteModalNews, setDeleteModalNews] = useState(false)

  const handleDeleteRecord = () => {
    if (activeTab1 === "news") handleDeleteNews()
    else if (activeTab1 === "insights") handleDeleteInsights()
    else if (activeTab1 === "highlights") handleDeleteHighlights()
    else if (activeTab1 === "report") handleDeleteReports()
  }

  const onClickDeleteNews = selectedNews => {
    setSelectedNews(selectedNews)
    setDeleteModal(true)
  }

  const handleDeleteNews = () => {
    if (selectedNews.id) {
      dispatch(onDeleteNews(selectedNews.id))
      setDeleteModal(false)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChangeNews = (type, { page, searchText }) => {
    setNewsList(
      news.filter(news =>
        Object.keys(news).some(key =>
          news[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  const newsPageOptions = {
    sizePerPage: 5,
    totalSize: news.length, // replace later with size(orders),
    custom: true,
  }

  //INSIGHTS changes

  const { insights, error2, insightsDeleteSuccess } = useSelector(state => ({
    insights: state.Insights.insights,
    error2: state.Insights.error,
    deleteSuccess: state.Insights.deleteSuccess,
  }))

  const [insightsList, setInsightsList] = useState([])
  const [selectedInsights, setSelectedInsights] = useState([])
  useEffect(() => {
    dispatch(onGetInsights())
  }, [])

  useEffect(() => {
    if (error2?.status === 401) {
      history.push("/login")
    } else if (insightsDeleteSuccess) {
      toast.success(`Insight data deleted successfully`, { autoClose: 2000 })
    } else if (
      !insightsDeleteSuccess &&
      insightsDeleteSuccess !== null &&
      insightsDeleteSuccess !== undefined &&
      insightsDeleteSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
    dispatch(updateInsightDeleteSuccessFlag(null))
  }, [error2, insightsDeleteSuccess])

  //delete Insights
  const [deleteModalInsights, setDeleteModalInsights] = useState(false)

  const onClickDeleteInsights = selectedInsights => {
    setSelectedInsights(selectedInsights)
    setDeleteModal(true)
  }

  const handleDeleteInsights = () => {
    if (selectedInsights.id) {
      dispatch(onDeleteInsights(selectedInsights.id))
      setDeleteModal(false)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChangeInsights = (type, { page, searchText }) => {
    setInsightsList(
      insights.filter(insights =>
        Object.keys(insights).some(key =>
          insights[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  const insightsPageOptions = {
    sizePerPage: 5,
    totalSize: insights.length, // replace later with size(orders),
    custom: true,
  }

  //REPORTS changes

  const { reports, error3, reportsDeleteSuccess } = useSelector(state => ({
    reports: state.Reports.reports,
    error3: state.Reports.error,
    deleteSuccess: state.Reports.deleteSuccess,
  }))

  const [reportsList, setReportsList] = useState([])
  const [selectedReports, setSelectedReports] = useState([])
  useEffect(() => {
    dispatch(onGetReports())
  }, [])

  useEffect(() => {
    if (error3?.status === 401) {
      history.push("/login")
    } else if (reportsDeleteSuccess) {
      toast.success(`Report deleted successfully`, { autoClose: 2000 })
    } else if (
      !reportsDeleteSuccess &&
      reportsDeleteSuccess !== null &&
      reportsDeleteSuccess !== undefined &&
      reportsDeleteSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
    dispatch(updateReportDeleteSuccessFlag(null))
  }, [error3, reportsDeleteSuccess])

  //delete Reports
  const [deleteModalReports, setDeleteModalReports] = useState(false)

  const onClickDeleteReports = selectedReports => {
    setSelectedReports(selectedReports)
    setDeleteModal(true)
  }

  const handleDeleteReports = () => {
    if (selectedReports.id) {
      dispatch(onDeleteReports(selectedReports.id))
      setDeleteModal(false)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChangeReports = (type, { page, searchText }) => {
    setReportsList(
      reports.filter(reports =>
        Object.keys(reports).some(key =>
          reports[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  const reportsPageOptions = {
    sizePerPage: 5,
    totalSize: reports.length, // replace later with size(orders),
    custom: true,
  }

  const [schedulePosts, setSchedulePosts] = useState([
    {
      id: "customCheck2",
      postType: "News",
      postTitle: "CAGL to acquire Madura Micro Finance for ₹876 cr",
      postTag: "Leading",
      postDetails:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      postDate: "04th November, 2023",
    },
    {
      id: "customCheck3",
      postType: "Blog",
      postTitle: "Creditas raises pre-series A funding from Crowd",
      postTag: "Trending",
      postDetails:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      postDate: "14th August, 2023",
    },
    {
      id: "customCheck4",
      postType: "News",
      postTitle:
        "Startup by 2 Friends Has Turned Plastic Waste Into 300 Million FMCG Bottles",
      postTag: "Highlight",
      postDetails:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      postDate: "10th September, 2023",
    },
    {
      id: "customCheck5",
      postType: "Report",
      postTitle:
        "Startup by 2 Friends Has Turned Plastic Waste Into 300 Million FMCG Bottles",
      postTag: "Leading",
      postDetails:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      postDate: "01st December, 2023",
    },
    {
      id: "customCheck6",
      postType: "Report",
      postTitle: "CAGL to acquire Madura Micro Finance for ₹876 cr",
      postTag: "In Focus",
      postDetails:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      postDate: "04th November, 2023",
    },
    {
      id: "customCheck7",
      postType: "News",
      postTitle: "CAGL to acquire Madura Micro Finance for ₹876 cr",
      postTag: "Trending",
      postDetails:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      postDate: "04th November, 2023",
    },
    {
      id: "customCheck8",
      postType: "Blog",
      postTitle: "CAGL to acquire Madura Micro Finance for ₹876 cr",
      postTag: "Leading",
      postDetails:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      postDate: "04th November, 2023",
    },
  ])

  const dispatch = useDispatch()

  const [orderList, setOrderList] = useState([])
  const [order, setOrder] = useState(null)

  // validation

  /*const { orders } = useSelector(state => ({
    orders: state.ecommerce.orders,
  }));*/

  // const selectRow = {
  //   mode: "checkbox",
  // };

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  //pagination customization
  const schedulePostsPageOptions = {
    sizePerPage: 5,
    totalSize: schedulePosts.length, // replace later with size(orders),
    custom: true,
  }

  /* const newsPageOptions = {
    sizePerPage: 5,
    totalSize: newsPosts.length, // replace later with size(orders),
    custom: true,
  }; */

  const { SearchBar } = Search

  // Role mapping
  const roleMapping = {
    individual_investor: 'Individual Investor',
    advisor: 'Advisor',
    legalrepresent: 'Legal Representative',
    // Add more mappings if necessary
  };

  // Custom formatter function
  const roleFormatter = (cell) => {
    // Split the comma-separated values
    const roles = cell.split(',');

    // Map each role to its human-readable label
    const formattedRoles = roles.map(role => roleMapping[role] || role);

    // Join the formatted roles back into a single string
    return formattedRoles.join(', ');
  };

  const highlightsColumns = () => [
    {
      dataField: "id",
      text: "Highlight Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Title",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "eventType",
      text: "Type",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    /* {
      dataField: "provience",
      text: "Provience",
      sort: true,
    }, */
    {
      dataField: "eventdate",
      text: "Date and Time",
      sort: true,
      /* formatter: (cellContent, highlights) => (
          eventdate = eventdate+eventtime
      ) */
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, highlights) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={`/add-eventHighlights?highlightcode=${highlights.highlightcode}`}
              className="text-success"
              //onClick={() => onClickDelete(order)}
            >
              <i className="bx bxs-edit-alt font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDeleteHighlights(highlights)}
            >
              <i className="bx bxs-trash font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]
  const newsColumns = () => [
    {
      dataField: "id",
      text: "News Id",
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "chip",
      text: "Type",
      sort: true,
    },
    {
      dataField: "targetUser",
      text: "Category",
      sort: true,
      formatter: roleFormatter,
    },
    {
      dataField: "Newdate",
      text: "Date and Time",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, news) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={`/add-news?newcode=${news.newcode}`}
              className="text-success"
              //onClick={() => onClickDelete(order)}
            >
              <i className="bx bxs-edit-alt font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDeleteNews(news)}
            >
              <i className="bx bxs-trash font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]
  const insightsColumns = () => [
    {
      dataField: "id",
      text: "Insight Id",
      sort: true,
    },
    {
      dataField: "headline",
      text: "Title",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "tag",
      text: "Type",
      sort: true,
    },
    {
      dataField: "targetUser",
      text: "Category",
      sort: true,
      formatter: roleFormatter,
    },
    {
      dataField: "insightDate",
      text: "Date and Time",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, insights) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={`/add-insights?insightcode=${insights.insightcode}`}
              className="text-success"
              //onClick={() => onClickDelete(order)}
            >
              <i className="bx bxs-edit-alt font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDeleteInsights(insights)}
            >
              <i className="bx bxs-trash font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]
  const reportsColumns = () => [
    {
      dataField: "id",
      text: "Report Id",
      sort: true,
    },
    {
      dataField: "filename",
      text: "Title",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "companyid",
      text: "Company",
      sort: true,
    },
    {
      dataField: "reporttype",
      text: "Type",
      sort: true,
    },
    {
      dataField: "targetUser",
      text: "Category",
      sort: true,
      formatter: roleFormatter,
    },
    {
      dataField: "tenure",
      text: "Tenure",
      sort: true,
    },
    {
      dataField: "tag",
      text: "Tag",
      sort: true,
    },
    {
      dataField: "reportdate",
      text: "Date and Time",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, report) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={`/add-reports?reportcode=${report.reportcode}`}
              className="text-success"
              //onClick={() => onClickDelete(order)}
            >
              <i className="bx bxs-edit-alt font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDeleteReports(report)}
            >
              <i className="bx bxs-trash font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]
  //   useEffect(() => {
  //     if (users && !users.length) {
  //       dispatch(onGetOrders());
  //     }
  //   }, [dispatch, users]);

  //   useEffect(() => {
  //     setOrderList(users);
  //   }, [users]);

  //   useEffect(() => {
  //     if (!isEmpty(users) && !!isEdit) {
  //       setOrderList(users);
  //       setIsEdit(false);
  //     }
  //   }, [users]);

  const toggle = () => {
    if (modal) {
      setModal(false)
      setOrder(null)
    } else {
      setModal(true)
    }
  }

  const handleOrderClick = arg => {
    const order = arg

    setOrder({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    })

    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = order => {
    setOrder(order)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    // if (users.id) {
    // dispatch(onDeleteOrder(order));
    onPaginationPageChange(1)
    setDeleteModal(false)
    // }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "postTitle",
      order: "desc",
    },
  ]
  const [activeTab1, setActiveTab1] = useState("news")
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)

  const [col5, setcol5] = useState(true)
  const [col6, setcol6] = useState(true)
  const [col7, setcol7] = useState(true)

  const [col8, setcol8] = useState(true)
  const [col9, setcol9] = useState(true)
  const [col10, setcol10] = useState(false)
  const [col11, setcol11] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
  }

  const t_col5 = () => {
    setcol5(!col5)
  }

  const t_col6 = () => {
    setcol6(!col6)
  }

  const t_col7 = () => {
    setcol7(!col7)
  }

  const t_col8 = () => {
    setcol6(!col6)
    setcol7(!col7)
  }

  const t_col9 = () => {
    setcol9(!col9)
    setcol10(false)
    setcol11(false)
  }

  const t_col10 = () => {
    setcol10(!col10)
    setcol9(false)
    setcol11(false)
  }

  const t_col11 = () => {
    setcol11(!col11)
    setcol10(false)
    setcol9(false)
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setActiveTab1(tab)
    }
  }
  const handleTableChange = (type, { page, searchText }) => {
    setCustomerList(
      Users.filter(Users =>
        Object.keys(Users).some(key =>
          Users[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRecord}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Templates</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Templates" breadcrumbItem="Templates" />
          {/* <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                <CardTitle className="mb-4">Schedule Posts</CardTitle>
                  <PaginationProvider
                    pagination={paginationFactory(schedulePostsPageOptions)}
                    keyField="id"
                    columns={postsColumns(toggle)}
                    data={schedulePosts}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={schedulePosts}
                        columns={postsColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Existing User Details</CardTitle> */}
                  <Nav pills className="navtab-bg nav-justified mb-2">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "news",
                        })}
                        onClick={() => {
                          toggle1("news")
                        }}
                      >
                        News
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "insights",
                        })}
                        onClick={() => {
                          toggle1("insights")
                        }}
                      >
                        Insights
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "highlights",
                        })}
                        onClick={() => {
                          toggle1("highlights")
                        }}
                      >
                        Highlights
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "report",
                        })}
                        onClick={() => {
                          toggle1("report")
                        }}
                      >
                        Report
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab1} className="p-3">
                    <TabPane tabId="news">
                      <Row>
                        <Col sm="12">
                          <Row className="mb-2">
                            <Col sm="6">
                              <CardTitle className="mb-4">News</CardTitle>
                            </Col>
                            <Col sm="6">
                              <div className="text-sm-end">
                                <Link
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2 btn btn-primary"
                                  //onClick={handleOrderClicks}
                                  to="/add-news"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add News
                                </Link>
                              </div>
                            </Col>
                          </Row>

                          <PaginationProvider
                            pagination={paginationFactory(newsPageOptions)}
                            keyField="id"
                            columns={newsColumns(toggle)}
                            data={news}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={news}
                                columns={newsColumns(toggle)}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    {/* <Row className="mb-2">
                                      <Col sm="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar {...toolkitProps.searchProps} />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row> */}
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            onClick={handleTableChangeNews}
                                            filter={ filterFactory() }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="insights">
                      <Row>
                        <Col sm="12">
                          <Row className="mb-2">
                            <Col sm="6">
                              <CardTitle className="mb-4">Insights</CardTitle>
                            </Col>
                            <Col sm="6">
                              <div className="text-sm-end">
                                <Link
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2 btn btn-primary"
                                  //onClick={handleOrderClicks}
                                  to="/add-insights"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add Insights
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <PaginationProvider
                            pagination={paginationFactory(insightsPageOptions)}
                            keyField="id"
                            columns={insightsColumns(toggle)}
                            data={insights}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={insights}
                                columns={insightsColumns(toggle)}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            onTableChange={
                                              handleTableChangeInsights
                                            }
                                            filter={ filterFactory() }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="highlights">
                      <Row>
                        <Col sm="12">
                          <Row className="mb-2">
                            <Col sm="6">
                              <CardTitle className="mb-4">Highlights</CardTitle>
                            </Col>
                            <Col sm="6">
                              <div className="text-sm-end">
                                <Link
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2 btn btn-primary"
                                  //onClick={handleTableChangeHighlights}
                                  to="/add-eventHighlights"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add Highlights
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <PaginationProvider
                            pagination={paginationFactory(
                              highlightsPageOptions
                            )}
                            keyField="id"
                            columns={highlightsColumns(toggle)}
                            data={highlights}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={highlights}
                                columns={highlightsColumns(toggle)}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            onClick={
                                              handleTableChangeHighlights
                                            }
                                            filter={ filterFactory() }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="report">
                      <Row>
                        <Col sm="12">
                          <Row className="mb-2">
                            <Col sm="6">
                              <CardTitle className="mb-4">Reports</CardTitle>
                            </Col>
                            <Col sm="6">
                              <div className="text-sm-end">
                                <Link
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2 btn btn-primary"
                                  //onClick={handleOrderClicks}
                                  to="/add-reports"
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add Reports
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <PaginationProvider
                            pagination={paginationFactory(reportsPageOptions)}
                            keyField="id"
                            columns={reportsColumns(toggle)}
                            data={reports}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={reports}
                                columns={reportsColumns(toggle)}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            onClick={handleTableChangeReports}
                                            filter={ filterFactory() }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Templates.propTypes = {
  users: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default Templates
