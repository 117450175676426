import {
  GET_HIGHLIGHTS_FAIL,
  GET_HIGHLIGHTS_SUCCESS,
  GET_HIGHLIGHTS_BY_ID_SUCCESS,
  GET_HIGHLIGHTS_BY_ID_FAIL,
  ADD_HIGHLIGHTS_SUCCESS,
  ADD_HIGHLIGHTS_FAIL,
  UPDATE_HIGHLIGHTS_SUCCESS,
  UPDATE_HIGHLIGHTS_FAIL,
  DELETE_HIGHLIGHTS_SUCCESS,
  DELETE_HIGHLIGHTS_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  highlights: [],
  highlightsById:[],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {}
}

const Highlights = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        highlights: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {}
      }

    case GET_HIGHLIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }
    
    case GET_HIGHLIGHTS_BY_ID_SUCCESS:
      return {
        ...state,
        highlightsById: action.payload,
        error: {}
      }

    case GET_HIGHLIGHTS_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        highlightsById:[],
      }    

    case ADD_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        highlights: [...state.highlights, action.payload],
        addUpdateSuccess: true,
        error: {}
      }

    case ADD_HIGHLIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        highlights: state.highlights.map(highlights =>
          highlights.id === action.payload.id
            ? { highlights, ...action.payload }
            : highlights
        ),
        addUpdateSuccess: true,
        error: {}
      }

    case UPDATE_HIGHLIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        highlights: state.highlights.filter(
          highlights => highlights.id !== action.highlightsId
        ),
        deleteSuccess: true,
        error: {}
      }

    case DELETE_HIGHLIGHTS_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return { 
        ...state, 
        deleteSuccess: action.payload 
      };

    default:
      return state
  }
}

export default Highlights
