import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_INSIGHTS,
  GET_INSIGHTS_BY_ID,
  ADD_NEW_INSIGHTS,
  DELETE_INSIGHTS,
  UPDATE_INSIGHTS,
} from "./actionTypes"
import {
  getInsightsFail,
  getInsightsSuccess,
  getInsightsByIdFail,
  getInsightsByIdSuccess,
  addInsightsFail,
  addInsightsSuccess,
  updateInsightsSuccess,
  updateInsightsFail,
  deleteInsightsSuccess,
  deleteInsightsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getInsights,
  addNewInsights,
  updateInsights,
  deleteInsights,
  getInsightsById,
} from "helpers/fakebackend_helper"
import 'react-toastify/dist/ReactToastify.css';

function* fetchInsights() {
  try {
    const response = yield call(getInsights)
    yield put(getInsightsSuccess(response.data))
  } catch (error) {
    yield put(getInsightsFail(error.response))
  }
}

function* fetchInsightsById({payload: insightsId}) {
  try {
    const response = yield call(getInsightsById, insightsId)
    yield put(getInsightsByIdSuccess(response.data))
  } catch (error) {
    yield put(getInsightsByIdFail(error.response))
  }
}

function* onUpdateInsights({ payload: insights }) {
  try {
    const response = yield call(updateInsights, insights)
    yield put(updateInsightsSuccess(response))
  } catch (error) {
    yield put(updateInsightsFail(error.response))
  }
}

function* onDeleteInsights({ payload: insights }) {
  try {
    const response = yield call(deleteInsights, insights)
    yield put(deleteInsightsSuccess(response, insights))
  } catch (error) {
    yield put(deleteInsightsFail(error.response))
  }
}

function* onAddNewInsights({ payload: insights }) {
  try {
    const response = yield call(addNewInsights, insights)

    yield put(addInsightsSuccess(response))
  } catch (error) {
    yield put(addInsightsFail(error.response))
  }
}

function* insightsSaga() {
  yield takeEvery(GET_INSIGHTS, fetchInsights)
  yield takeEvery(GET_INSIGHTS_BY_ID, fetchInsightsById)
  yield takeEvery(ADD_NEW_INSIGHTS, onAddNewInsights)
  yield takeEvery(UPDATE_INSIGHTS, onUpdateInsights)
  yield takeEvery(DELETE_INSIGHTS, onDeleteInsights)
}

export default insightsSaga
