import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { Editor } from "react-draft-wysiwyg"
import { convertToRaw, ContentState, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewCompany as onAddNewCompany,
  updateCompany as onUpdateCompany,
  getCompanyById as onGetCompanyById,
  deleteSectionEntry,
  updateDeleteSuccessFlag,
} from "store/company/actions"
import { getSustainGoal as onGetSustainGoals } from "store/common/actions"
import { uploadDocument as uploadDocument } from "store/document-upload/actions"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
//redux
import { useSelector, useDispatch } from "react-redux"
import { countBy, find } from "lodash"
import { toast } from "react-toastify"
// import { ToastContainer, toast } from 'react-toastify';

const AddCompany = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const companyId = searchParams.get("id")
  const optionCountry = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahrain" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BR", label: "Brazil" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "CV", label: "Cape Verde" },
    { value: "KY", label: "Cayman Islands" },
    { value: "CF", label: "Central African Republic" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmas Island" },
    { value: "CC", label: "Cocos (Keeling) Islands" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros" },
    { value: "CG", label: "Congo" },
    { value: "CK", label: "Cook Islands" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Cote d&#39;Ivoire" },
    { value: "HR", label: "Croatia (Hrvatska)" },
    { value: "CU", label: "Cuba" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "ET", label: "Ethiopia" },
    { value: "FK", label: "Falkland Islands (Malvinas)" },
    { value: "FO", label: "Faroe Islands" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GF", label: "French Guiana" },
    { value: "PF", label: "French Polynesia" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Greece" },
    { value: "GL", label: "Greenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IQ", label: "Iraq" },
    { value: "IE", label: "Ireland" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JO", label: "Jordan" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KR", label: "Korea, Republic of" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" },
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libyan Arab Jamahiriya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macau" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "MD", label: "Moldova, Republic of" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands" },
    { value: "AN", label: "Netherlands Antilles" },
    { value: "NC", label: "New Caledonia" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Island" },
    { value: "MP", label: "Northern Mariana Islands" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" },
    { value: "PW", label: "Palau" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" },
    { value: "RE", label: "Reunion" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russian Federation" },
    { value: "RW", label: "Rwanda" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint LUCIA" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" },
    { value: "SN", label: "Senegal" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SK", label: "Slovakia (Slovak Republic)" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SH", label: "St. Helena" },
    { value: "PM", label: "St. Pierre and Miquelon" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Suriname" },
    { value: "SZ", label: "Swaziland" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syrian Arab Republic" },
    { value: "TW", label: "Taiwan, Province of China" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania, United Republic of" },
    { value: "TH", label: "Thailand" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks and Caicos Islands" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates" },
    { value: "GB", label: "United Kingdom" },
    { value: "US", label: "United States" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Viet Nam" },
    { value: "VG", label: "Virgin Islands (British)" },
    { value: "VI", label: "Virgin Islands (U.S.)" },
    { value: "WF", label: "Wallis and Futuna Islands" },
    { value: "EH", label: "Western Sahara" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
  ]

  const language = JSON.parse(localStorage.getItem("language"))
  const [cmpObj, setCmpObj] = useState({})
  const inpSummaryRow = [
    { sectionname: "summary", key: "", value: "", lang_id: language[1].id },
  ]
  const inpImpactRow = [
    { sectionname: "impact", key: "", value: "", lang_id: language[1].id },
  ]
  const inpSummaryRow3 = [
    { sectionname: "summary", key: "", value: "", lang_id: language[0].id },
  ]
  const inpImpactRow3 = [
    { sectionname: "impact", key: "", value: "", lang_id: language[0].id },
  ]
  const [selectedFiles, setselectedFiles] = useState([])
  const [impactInputFields, setImpactInputFields] = useState([])
  const [summaryInputFields, setSummaryInputFields] = useState([])
  const [impactInputFields3, setImpactInputFields3] = useState([])
  const [summaryInputFields3, setSummaryInputFields3] = useState([])
  const [summaryEditorState, setSummaryEditorState] = useState({
    // id: null,
    companyid: companyId,
    sectionname: "summary",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[1].id,
  })
  const [impactEditorState, setImpactEditorState] = useState({
    // id: null,
    companyid: companyId,
    sectionname: "impact",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[1].id,
  })
  const [sdgEditorState, setSdgEditorState] = useState({
    // id: null,
    companyid: companyId,
    sectionname: "sustainGoal",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[1].id,
  })
  const [summaryEditorState3, setSummaryEditorState3] = useState({
    // id: null,
    companyid: companyId,
    sectionname: "summary",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[0].id,
  })
  const [impactEditorState3, setImpactEditorState3] = useState({
    // id: null,
    companyid: companyId,
    sectionname: "impact",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[0].id,
  })
  const [sdgEditorState3, setSdgEditorState3] = useState({
    // id: null,
    companyid: companyId,
    sectionname: "sustainGoal",
    key: "description",
    editorValue: EditorState.createEmpty(),
    value: "",
    lang_id: language[0].id,
  })
  const [isEdit, setIsEdit] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState({})
  const [tempEntityData, setTempEntityData] = useState({})

  const [activeTab, setActiveTab] = useState(language[0].id)
  const {
    companyById,
    sustainGoals,
    addUpdateSuccess,
    error,
    deleteSuccess,
    document,
  } = useSelector(state => ({
    companyById: state.Company.companyById,
    sustainGoals: state.Common.sustainGoals,
    error: state.Company.error,
    addUpdateSuccess: state.Company.addUpdateSuccess,
    deleteSuccess: state.Company.deleteSuccess,
    document: state.DocumentUpload.document,
  }))

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
    else if (addUpdateSuccess) {
      toast.success(`Company ${isEdit ? "updated" : "added"} successfully`, {
        autoClose: 2000,
      })
      validation.resetForm()
      history.push("/company")
    } else if (
      !addUpdateSuccess &&
      addUpdateSuccess !== null &&
      addUpdateSuccess !== undefined &&
      addUpdateSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
  }, [error, addUpdateSuccess])

  useEffect(() => {
    if (deleteSuccess) {
      removeInputFields(tempEntityData.fieldRef, tempEntityData.index)
      toast.success(`Data deleted successfully`, { autoClose: 2000 })
      setTempEntityData({})
    } else if (
      !deleteSuccess &&
      deleteSuccess !== null &&
      deleteSuccess !== undefined &&
      deleteSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
    dispatch(updateDeleteSuccessFlag(null))
  }, [deleteSuccess])

  function handleAcceptedFiles(files) {
    dispatch(uploadDocument(files[0]))
    // validation.setFieldValue(
    //   "companylogo",
    //   "https://ucarecdn.com/b223131b-a0a0-4812-9805-0029fe27c169/banyan_logo.png"
    // )
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  useEffect(() => {
    console.log("document", document)
    if (document !== undefined) {
      validation.setFieldValue("companylogo", document?.fileurl)
    }
  }, [document])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAddFields(fieldRef) {
    console.log(fieldRef, "field ref")
    if (fieldRef === "summary")
      setSummaryInputFields([...summaryInputFields, inpSummaryRow[0]])
    else if (fieldRef === "impact")
      setImpactInputFields([...impactInputFields, inpImpactRow[0]])
    else if (fieldRef === "impact3")
      setImpactInputFields3([...impactInputFields3, inpImpactRow3[0]])
    else if (fieldRef === "summary3")
      setSummaryInputFields3([...summaryInputFields3, inpSummaryRow3[0]])
  }

  // Function for Remove Input Fields
  function handleRemoveFields(fieldRef, index, entityId) {
    if (checkDataIsPresent("sectiondata", entityId)) {
      setTempEntityData({
        fieldRef: fieldRef,
        index: index,
      })
      dispatch(deleteSectionEntry(entityId, "sectiondata"))
    } else {
      removeInputFields(fieldRef, index)
    }
  }

  const removeItemByIndex = (array, indexToRemove) => {
    if (indexToRemove < 0 || indexToRemove >= array.length) {
      return array // Return the original array if the index is out of bounds
    }
    const newArray = [...array] // Create a shallow copy of the original array
    newArray.splice(indexToRemove, 1) // Remove one element at the specified index
    return newArray
  }

  const checkDataIsPresent = (model, entityId) => {
    return find(selectedCompany[model], { id: entityId })
  }

  const removeInputFields = (fieldRef, index) => {
    console.log('fieldRef, index', fieldRef, index)
    if (fieldRef === "summary")
      setSummaryInputFields(removeItemByIndex(summaryInputFields, index))
    else if (fieldRef === "impact")
      setImpactInputFields(removeItemByIndex(impactInputFields, index))
    else if (fieldRef === "sustainarr") {
      validation.handleChange("sdgOptions", index)
      validation.setFieldValue("sdgOptions", index)
    } else if (fieldRef === "summary3")
      setSummaryInputFields3(removeItemByIndex(summaryInputFields3, index))
    else if (fieldRef === "impact3")
      setImpactInputFields3(removeItemByIndex(impactInputFields3, index))
  }

  const [sustainArray, setSustainArray] = useState([])
  useEffect(() => {
    console.log("sustainGoals", sustainGoals)
    if (sustainGoals.length <= 0) {
      //call api to get company data;
      dispatch(onGetSustainGoals())
    } else {
      let temp = []
      sustainGoals.forEach(element => {
        temp.push({
          label: element.value,
          value: element.id,
        })
      })
      setSustainArray(temp)
    }
  }, [sustainGoals])

  useEffect(() => {
    setCmpObj({
      companyId: companyId,
      lang_id: 1,
    })
  }, [companyId])

  useEffect(() => {
    console.log(cmpObj, "Obj")
    if (companyId !== null && companyId !== undefined) {
      //call api to get company data;
      let company_id = {
        companyId: companyId,
        lang_id: activeTab === 1 ? language[1].id : language[0].id,
      }
      // console.log(company_id, companyId, "Comp obj")

      dispatch(onGetCompanyById(company_id))
    }
  }, [activeTab])

  useEffect(() => {
    if (companyById.length > 0 && companyId !== null) {
      setSelectedCompany(companyById[0])
      setIsEdit(true)
      prefillCompanyData(companyById[0])
    }
  }, [companyById])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      lang_id: language[1].id,
      name: "",
      companylogo: "",
      country: "",
      ebita: null,
      turnover: null,
      activerequest: 0,
      summaryDesc: "",
      summaryDesc3: "",
      impactDesc: "",
      impactDesc3: "",
      sdgDesc: "",
      sdgDesc3: "",
      sdgOptions: null,
      sdgOptions3: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Company Name"),
      companylogo: Yup.mixed().required("Please upload company logo"),
      country: Yup.string()
        .required("Please Select Country")
        .notOneOf(["-1"], "Please Select Country"),
      ebita: Yup.number().required("Please Enter EBITA"),
      turnover: Yup.number().required("Please Enter Turnover"),
    }),
    onSubmit: values => {
      let sectionData = []
      let tempSustainArr = []
      if (activeTab === 1) {
        if (values["summaryDesc"] !== "") {
          // setSummaryEditorState((prevState) => ({...prevState, value: values["summaryDesc"]}));
          // let temp = inpSummaryRow[0]
          // temp.key = "description"
          // temp.value = values["summaryDesc"]
          // if(isEdit)
          //   temp['companyid'] = selectedCompany.id;
          // sectionData.push(temp)
          // sectionData.push(summaryEditorState);
          sectionData.push(summaryEditorState)
          delete sectionData[0].editorValue
        }
        if (values["impactDesc"] !== "") {
          // setImpactEditorState((prevState) => ({...prevState, value: values["impactDesc"]}));
          // let temp = inpImpactRow[0]
          // temp.key = "description"
          // temp.value = values["impactDesc"]
          // if(isEdit)
          //   temp['companyid'] = selectedCompany.id;
          // sectionData.push(temp)
          // sectionData.push(impactEditorState);
          sectionData.push(impactEditorState)
          delete sectionData[1].editorValue
        }
        if (values["sdgDesc"] !== "") {
          // setSdgEditorState((prevState) => ({...prevState, value: values["sdgDesc"]}));
          // let temp = { sectionname: "sustainGoal", key: "", value: "" }
          // temp.key = "description"
          // temp.value = values["sdgDesc"]
          // if(isEdit)
          //   temp['companyid'] = selectedCompany.id;
          // sectionData.push(temp)
          // sectionData.push(sdgEditorState)
          sectionData.push(sdgEditorState)
          delete sectionData[2].editorValue
        }

        if (summaryInputFields.length > 0) {
          let tempSummaryInpField = summaryInputFields.filter((item) => {
            return item.key !== "" || item.value !== ""
          })
          let temp = sectionData.concat(tempSummaryInpField)
          sectionData = temp
        }
        if (impactInputFields.length > 0) {
          let tempImpactInpField = impactInputFields.filter((item) => {
            return item.key !== "" || item.value !== ""
          })
          let temp = sectionData.concat(tempImpactInpField)
          sectionData = temp
        }
      }
      if (activeTab === 3) {
        if (values["summaryDesc3"] !== "") {
          // setSummaryEditorState((prevState) => ({...prevState, value: values["summaryDesc"]}));
          // let temp = inpSummaryRow[0]
          // temp.key = "description"
          // temp.value = values["summaryDesc"]
          // if(isEdit)
          //   temp['companyid'] = selectedCompany.id;
          // sectionData.push(temp)
          // sectionData.push(summaryEditorState);
          sectionData.push(summaryEditorState3)
          delete sectionData[0].editorValue
        }
        if (values["impactDesc3"] !== "") {
          // setImpactEditorState((prevState) => ({...prevState, value: values["impactDesc"]}));
          // let temp = inpImpactRow[0]
          // temp.key = "description"
          // temp.value = values["impactDesc"]
          // if(isEdit)
          //   temp['companyid'] = selectedCompany.id;
          // sectionData.push(temp)
          // sectionData.push(impactEditorState);
          sectionData.push(impactEditorState3)
          delete sectionData[1].editorValue
        }
        if (values["sdgDesc3"] !== "") {
          // setSdgEditorState((prevState) => ({...prevState, value: values["sdgDesc"]}));
          // let temp = { sectionname: "sustainGoal", key: "", value: "" }
          // temp.key = "description"
          // temp.value = values["sdgDesc"]
          // if(isEdit)
          //   temp['companyid'] = selectedCompany.id;
          // sectionData.push(temp)
          // sectionData.push(sdgEditorState)
          sectionData.push(sdgEditorState3)
          delete sectionData[2].editorValue
        }

        if (summaryInputFields3.length > 0) {
          let tempSummaryInpField = summaryInputFields3.filter((item) => {
            return item.key !== "" && item.value !== ""
          })
          let temp = sectionData.concat(tempSummaryInpField)
          sectionData = temp
        }
        if (impactInputFields3.length > 0) {
          let tempImpactInpField = impactInputFields3.filter((item) => {
            return item.key !== "" && item.value !== ""
          })
          let temp = sectionData.concat(tempImpactInpField)
          sectionData = temp
        }
      }

      if (values["sdgOptions"]?.length > 0) {
        values["sdgOptions"].forEach(element => {
          if (isEdit)
            tempSustainArr.push({
              sustaingoalid: element.value,
              companyid: selectedCompany.id,
              id: element.id !== undefined ? element.id : null,
            })
          else tempSustainArr.push({ sustaingoalid: element.value })
        })
      }

      addOrUpdateCompany(values, sectionData, tempSustainArr)
    },
  })

  const addOrUpdateCompany = (values, sectionData, tempSustainArr) => {
    if (isEdit) {
      const updateCompanyPayload = {
        lang_id: language[1].id,
        name: values["name"],
        companylogo: values["companylogo"],
        country: values["country"],
        ebita: values["ebita"],
        turnover: values["turnover"],
        activerequest: 0,
        sectiondata: sectionData,
        sustaingoalarchiveddesc: values["sdgDesc"],
        sustainarr: tempSustainArr,

        id: selectedCompany.id,
        companycode: selectedCompany.companycode,
        value: selectedCompany.value === "" ? 0 : selectedCompany.value,
        type: selectedCompany.type,
        targetUser:
          selectedCompany.targetUser === "" ? null : selectedCompany.targetUser,
        imgUrl: selectedCompany.imgUrl,
        otherdetail: selectedCompany.otherdetail,
        isNew: false,
      }
      // update company
      dispatch(onUpdateCompany(updateCompanyPayload))
    } else {
      const newCompanyPayload = {
        lang_id: language[1].id,
        name: values["name"],
        companylogo: values["companylogo"],
        country: values["country"],
        ebita: values["ebita"],
        turnover: values["turnover"],
        activerequest: 0,
        sectiondata: sectionData,
        sustaingoalarchiveddesc: values["sdgDesc"],
        sustainarr: tempSustainArr,

        value: 0,
        type: "",
        targetUser: null,
        imgUrl: "",
        otherdetail: "",
        isNew: true,
      }
      // save new customer
      dispatch(onAddNewCompany(newCompanyPayload))
    }
  }

  const handleEditorChange = (newEditorState, fieldName) => {
    const currentContent = convertToRaw(newEditorState.getCurrentContent())
    const isEmpty = currentContent?.blocks[0]?.text === ""
    // const html = draftToHtml(rawContentState);

    validation.setFieldValue(
      fieldName,
      isEmpty ? "" : draftToHtml(currentContent)
    )
    validation.setFieldTouched(fieldName, true)

    fieldName === "summaryDesc"
      ? setSummaryEditorState(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : fieldName === "impactDesc"
      ? setImpactEditorState(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : fieldName === "sdgDesc"
      ? setSdgEditorState(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : fieldName === "summaryDesc3"
      ? setSummaryEditorState3(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : fieldName === "impactDesc3"
      ? setImpactEditorState3(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : fieldName === "sdgDesc3"
      ? setSdgEditorState3(prevState => ({
          ...prevState,
          editorValue: newEditorState,
          value: isEmpty ? "" : draftToHtml(currentContent),
        }))
      : ""
  }

  const handleOnChange = (secRef, fieldRef, index, value) => {
    let tempObj =
      secRef === "summary"
        ? summaryInputFields[index]
        : secRef === "impact"
        ? impactInputFields[index]
        : secRef === "summary3"
        ? summaryInputFields3[index]
        : secRef === "impact3"
        ? impactInputFields3[index]
        : ""
    if (fieldRef === "key") {
      tempObj.key = value
    } else if (fieldRef === "value") {
      tempObj.value = value
    }
    if (isEdit) tempObj["companyid"] = selectedCompany?.id

    if (secRef === "summary") {
      setSummaryInputFields(prevItems => {
        const newArray = [...prevItems]
        newArray[index] = tempObj
        return newArray
      })
    } else if (secRef === "impact") {
      setImpactInputFields(prevItems => {
        const newArray = [...prevItems]
        newArray[index] = tempObj
        return newArray
      })
    } else if (secRef === "summary3") {
      setSummaryInputFields3(prevItems => {
        const newArray = [...prevItems]
        newArray[index] = tempObj
        return newArray
      })
    } else if (secRef === "impact3") {
      setImpactInputFields3(prevItems => {
        const newArray = [...prevItems]
        newArray[index] = tempObj
        return newArray
      })
    }
  }

  const prefillCompanyData = company => {
    console.log("company prefill data", company)

    validation.setFieldValue("lang_id", company?.lang_id)
    validation.setFieldValue("name", company?.name)
    validation.setFieldValue("country", company?.country)
    validation.setFieldValue("ebita", company?.ebita)
    validation.setFieldValue("turnover", company?.turnover)
    // validation.setFieldValue("activerequest", company?.activerequest);

    let tempSummary = [],
      tempImpact = []
    if (activeTab === 1) {
      company?.sectiondata.forEach(item => {
        if (item.key === "description") {
          item.sectionname === "summary"
            ? setSummaryEditorState(prevState => ({
                ...prevState,
                editorValue: getContentBlock(item.value),
                id: item.id,
                companyid: company?.id,
                value: item?.value,
              }))
            : item.sectionname === "impact"
            ? setImpactEditorState(prevState => ({
                ...prevState,
                editorValue: getContentBlock(item.value),
                id: item.id,
                companyid: company?.id,
                value: item?.value,
              }))
            : item.sectionname === "sustainGoal"
            ? setSdgEditorState(prevState => ({
                ...prevState,
                editorValue: getContentBlock(item.value),
                id: item.id,
                companyid: company?.id,
                value: item?.value,
              }))
            : ""
          item.sectionname === "summary"
            ? validation.setFieldValue("summaryDesc", item?.value)
            : item.sectionname === "impact"
            ? validation.setFieldValue("impactDesc", item?.value)
            : item.sectionname === "sustainGoal"
            ? validation.setFieldValue("sdgDesc", item?.value)
            : ""
        } else {
          let tempSecSummary = { ...inpSummaryRow[0] }
          let tempSecImpact = { ...inpImpactRow[0] }
          if (item.sectionname === "summary") {
            tempSecSummary.key = item.key
            tempSecSummary.value = item.value
            tempSecSummary["companyid"] = company.id
            tempSecSummary["id"] = item.id
            tempSummary.push(tempSecSummary)
          } else if (item.sectionname === "impact") {
            tempSecImpact.key = item.key
            tempSecImpact.value = item.value
            tempSecImpact["companyid"] = company.id
            tempSecImpact["id"] = item.id
            tempImpact.push(tempSecImpact)
          }
        }
      })
      setSummaryInputFields(tempSummary)
      setImpactInputFields(tempImpact)

      let tempSdgOptions = []
      if (company?.sustainarr.length > 0) {
        company?.sustainarr.forEach(item => {
          tempSdgOptions.push({
            label: item.SustainGoal.value,
            value: item.sustaingoalid,
            id: item.id,
          })
        })
      }
      validation.setFieldValue("sdgOptions", tempSdgOptions)
    } else if (activeTab === 3) {
      company?.sectiondata.forEach(item => {
        if (item.key === "description") {
          item.sectionname === "summary"
            ? setSummaryEditorState3(prevState => ({
                ...prevState,
                editorValue: getContentBlock(item.value),
                id: item.id,
                companyid: company?.id,
                value: item?.value,
              }))
            : item.sectionname === "impact"
            ? setImpactEditorState3(prevState => ({
                ...prevState,
                editorValue: getContentBlock(item.value),
                id: item.id,
                companyid: company?.id,
                value: item?.value,
              }))
            : item.sectionname === "sustainGoal"
            ? setSdgEditorState3(prevState => ({
                ...prevState,
                editorValue: getContentBlock(item.value),
                id: item.id,
                companyid: company?.id,
                value: item?.value,
              }))
            : ""
          item.sectionname === "summary"
            ? validation.setFieldValue("summaryDesc3", item?.value)
            : item.sectionname === "impact"
            ? validation.setFieldValue("impactDesc3", item?.value)
            : item.sectionname === "sustainGoal"
            ? validation.setFieldValue("sdgDesc3", item?.value)
            : ""
        } else {
          let tempSecSummary = { ...inpSummaryRow3[0] }
          let tempSecImpact = { ...inpImpactRow3[0] }
          if (item.sectionname === "summary") {
            console.log(tempSecSummary, "Summary Stat")
            tempSecSummary.key = item.key
            tempSecSummary.value = item.value
            tempSecSummary["companyid"] = company.id
            tempSecSummary["id"] = item.id
            tempSummary.push(tempSecSummary)
          } else if (item.sectionname === "impact") {
            tempSecImpact.key = item.key
            tempSecImpact.value = item.value
            tempSecImpact["companyid"] = company.id
            tempSecImpact["id"] = item.id
            tempImpact.push(tempSecImpact)
          }
        }
      })
      setSummaryInputFields3(tempSummary)
      setImpactInputFields3(tempImpact)

      let tempSdgOptions = []
      if (company?.sustainarr.length > 0) {
        company?.sustainarr.forEach(item => {
          tempSdgOptions.push({
            label: item.SustainGoal.value,
            value: item.sustaingoalid,
            id: item.id,
          })
        })
      }
      validation.setFieldValue("sdgOptions", tempSdgOptions)
    }

    getImageFileFromUrl(company?.companylogo, "company-logo.png").then(file => {
      if (file) {
        let temp = [
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          }),
        ]
        setselectedFiles(temp)
      }
    })
    validation.setFieldValue("companylogo", company?.companylogo)
  }

  const getContentBlock = htmlContent => {
    const contentBlock = htmlToDraft(htmlContent)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const newEditorState = EditorState.createWithContent(contentState)
      return newEditorState
    }
    return ""
  }

  async function getImageFileFromUrl(imageUrl, fileName) {
    try {
      // Fetch image data
      const response = await fetch(imageUrl)
      const blob = await response.blob()

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: blob.type })

      return file
    } catch (error) {
      console.error("Error fetching image:", error)
      return null
    }
  }

  // useEffect(() => {
  //   console.log('summaryInputFields', summaryInputFields, impactInputFields);
  // })

  const toggle = tab => {
    console.log(activeTab)
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      {/* <ToastContainer /> */}
      <div className="page-content">
        <MetaTags>
          <title>{isEdit ? "Edit Company" : "Add New Comapny"}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {isEdit ? (
            <Breadcrumbs title="Company" breadcrumbItem="Edit Company" />
          ) : (
            <Breadcrumbs title="Company" breadcrumbItem="Add New Company" />
          )}
          <Form
            //onSubmit={validation.handleSubmit}
            //handleValidSubmit();
            onSubmit={validation.handleSubmit}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {isEdit ? "Edit Company" : "Add New Comapny"}
                    </CardTitle>

                    {/* <FormGroup className="select2-container mb-4" row>
                      <Label className="col-form-label col-lg-12">
                        Language
                      </Label>
                      <Col md="10">
                        <select
                          className={`form-control select2`}
                          title="Language"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="lang_id"
                          value={validation.values.lang_id || ""}
                          placeholder="select language"
                          disabled={isEdit}
                        >
                          {language.map((option, index) => (
                            <option key={index} value={option.id}>
                              {option.language_name}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </FormGroup> */}

                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="name"
                        className="col-form-label col-lg-12"
                      >
                        Name of the Company
                      </Label>
                      <Col lg="10">
                        <Input
                          id="companyname"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Company Name..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-12">
                        Photograph
                      </Label>
                      <Col lg="10">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className={`dropzone ${
                                validation.touched.companylogo &&
                                validation.errors.companylogo
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input
                                  name="companylogo"
                                  onChange={validation.handleChange}
                                  {...getInputProps()}
                                />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                        {validation.touched.companylogo &&
                        validation.errors.companylogo ? (
                          <FormFeedback type="invalid">
                            {validation.errors.companylogo}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="select2-container mb-4" row>
                      <Label className="col-form-label col-lg-12">
                        Country
                      </Label>
                      <Col md="10">
                        <select
                          className={`form-control select2 ${
                            validation.touched.country &&
                            validation.errors.country
                              ? "is-invalid"
                              : ""
                          }`}
                          title="Country"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          name="country"
                          value={validation.values.country || ""}
                          aria-invalid={
                            validation.touched.country &&
                            validation.errors.country
                              ? true
                              : false
                          }
                          placeholder="select country"
                        >
                          <option value="-1">select country</option>
                          {optionCountry.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {validation.touched.country &&
                        validation.errors.country ? (
                          <FormFeedback type="invalid">
                            {validation.errors.country}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="EBITA"
                        className="col-form-label col-lg-12"
                      >
                        EBIDTA(YoY) %
                      </Label>
                      <Col lg="10">
                        <Input
                          id="ebita"
                          name="ebita"
                          type="number"
                          className="form-control"
                          placeholder="Enter EBITA"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.ebita || ""}
                          invalid={
                            validation.touched.ebita && validation.errors.ebita
                              ? true
                              : false
                          }
                        />
                        {validation.touched.ebita && validation.errors.ebita ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ebita}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="turnover"
                        className="col-form-label col-lg-12"
                      >
                        Turnover(YoY) %
                      </Label>
                      <Col lg="10">
                        <Input
                          id="turnover"
                          name="turnover"
                          type="number"
                          className="form-control"
                          placeholder="Enter Turnover..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.turnover || ""}
                          invalid={
                            validation.touched.turnover &&
                            validation.errors.turnover
                              ? true
                              : false
                          }
                        />
                        {validation.touched.turnover &&
                        validation.errors.turnover ? (
                          <FormFeedback type="invalid">
                            {validation.errors.turnover}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    {/* <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="activerequest"
                        className="col-form-label col-lg-12"
                      >
                        Active Request
                      </Label>
                      <Col lg="10">
                        <Input
                          id="activerequest"
                          name="activerequest"
                          type="number"
                          className="form-control"
                          placeholder="Enter Active Request..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.activerequest || ""}
                          invalid={
                            validation.touched.activerequest &&
                            validation.errors.activerequest
                              ? true
                              : false
                          }
                        />
                        {validation.touched.activerequest &&
                        validation.errors.activerequest ? (
                          <FormFeedback type="invalid">
                            {validation.errors.activerequest}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Nav pills className="navtab-bg nav-justified mb-2">
                {language.map((option, index) => (
                  <NavItem key={index} value={option.id}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === option.id,
                      })}
                      onClick={() => {
                        toggle(option.id)
                      }}
                    >
                      {option.language_name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab} className="p-3">
                <TabPane tabId={1} key={1} value={1}>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Overview</CardTitle>
                        {/* <form className="outer-repeater"> */}
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <FormGroup className="mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Summary
                              </Label>
                              <Col lg="10">
                                <Editor
                                  id="summaryDesc"
                                  name="summaryDesc"
                                  editorState={summaryEditorState.editorValue}
                                  onEditorStateChange={newEditorState =>
                                    handleEditorChange(
                                      newEditorState,
                                      "summaryDesc"
                                    )
                                  }
                                  value={validation.values.summaryDesc || ""}
                                />
                                {validation.touched.summaryDesc &&
                                validation.errors.summaryDesc ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.summaryDesc}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col className="mt-3" lg="10">
                                <div className="inner-repeater mb-4">
                                  <Label className="col-form-label col-lg-12">
                                    Statistics
                                  </Label>
                                  <div className="inner form-group mb-0 row">
                                    <div
                                      className="inner col-lg-10 ml-md-auto"
                                      id="repeater"
                                    >
                                      {summaryInputFields.map((item, index) => (
                                        <div
                                          key={index}
                                          id={"summary" + index}
                                          className="mb-3 row align-items-center"
                                        >
                                          <Col md="5">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              defaultValue={item.key}
                                              value={item.key}
                                              placeholder="Enter Label..."
                                              onChange={e =>
                                                handleOnChange(
                                                  "summary",
                                                  "key",
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col md="5">
                                            <div className="mt-4 mt-md-0">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                defaultValue={item.value}
                                                value={item.value}
                                                placeholder="Enter Value..."
                                                onChange={e =>
                                                  handleOnChange(
                                                    "summary",
                                                    "value",
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col md="2">
                                            <div className="mt-2 mt-md-0 d-grid">
                                              <Button
                                                color="primary"
                                                className="inner"
                                                onClick={() => {
                                                  handleRemoveFields(
                                                    "summary",
                                                    index,
                                                    item.id
                                                  )
                                                }}
                                                block
                                              >
                                                Delete
                                              </Button>
                                            </div>
                                          </Col>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <Row className="justify-content-start">
                                    <Col lg="10">
                                      <Button
                                        color="success"
                                        className="inner"
                                        onClick={() => {
                                          handleAddFields("summary")
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Impact
                              </Label>
                              <Col lg="10">
                                <Editor
                                  id="impactDesc"
                                  name="impactDesc"
                                  editorState={impactEditorState.editorValue}
                                  onEditorStateChange={newEditorState =>
                                    handleEditorChange(
                                      newEditorState,
                                      "impactDesc"
                                    )
                                  }
                                  value={validation.values.impactDesc || ""}
                                />
                              </Col>
                              <Col className="mt-3" lg="10">
                                <div className="inner-repeater mb-4">
                                  <Label className="col-form-label col-lg-12">
                                    Statistics
                                  </Label>
                                  <div className="inner form-group mb-0 row">
                                    <div
                                      className="inner col-lg-10 ml-md-auto"
                                      id="repeater"
                                    >
                                      {impactInputFields.map((item, index) => (
                                        <div
                                          key={index}
                                          id={"impact" + index}
                                          className="mb-3 row align-items-center"
                                        >
                                          <Col md="6">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              defaultValue={item.key}
                                              value={item.key}
                                              placeholder="Enter Label..."
                                              onChange={e =>
                                                handleOnChange(
                                                  "impact",
                                                  "key",
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col md="4">
                                            <div className="mt-4 mt-md-0">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                defaultValue={item.value}
                                                value={item.value}
                                                placeholder="Enter Value..."
                                                onChange={e =>
                                                  handleOnChange(
                                                    "impact",
                                                    "value",
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col md="2">
                                            <div className="mt-2 mt-md-0 d-grid">
                                              <Button
                                                color="primary"
                                                className="inner"
                                                onClick={() => {
                                                  handleRemoveFields(
                                                    "impact",
                                                    index,
                                                    item.id
                                                  )
                                                }}
                                                block
                                              >
                                                Delete
                                              </Button>
                                            </div>
                                          </Col>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <Row className="justify-content-start">
                                    <Col lg="10">
                                      <Button
                                        color="success"
                                        className="inner"
                                        onClick={() => {
                                          handleAddFields("impact")
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Sustanable Developement Goals
                              </Label>
                              <Col lg="10">
                                <Editor
                                  id="sdgDesc"
                                  name="sdgDesc"
                                  editorState={sdgEditorState.editorValue}
                                  onEditorStateChange={newEditorState =>
                                    handleEditorChange(
                                      newEditorState,
                                      "sdgDesc"
                                    )
                                  }
                                  value={validation.values.sdgDesc || ""}
                                />
                              </Col>
                              <Col className="mt-3" md="10">
                                <div className="mb-3">
                                  <Select
                                    id="sdgOptions"
                                    name="sdgOptions"
                                    type="select"
                                    onChange={(option, e) => {
                                      console.log("option", option, e)
                                      if (e.action === "remove-value") {
                                        if (
                                          checkDataIsPresent(
                                            "sustainarr",
                                            e.removedValue.id
                                          )
                                        ) {
                                          setTempEntityData({
                                            fieldRef: "sustainarr",
                                            index: option,
                                          })
                                          dispatch(
                                            deleteSectionEntry(
                                              e.removedValue.id,
                                              "sustainarr"
                                            )
                                          )
                                        } else {
                                          validation.handleChange(
                                            "sdgOptions",
                                            option
                                          )
                                          validation.setFieldValue(
                                            "sdgOptions",
                                            option
                                          )
                                        }
                                      } else {
                                        validation.handleChange(
                                          "sdgOptions",
                                          option
                                        )
                                        validation.setFieldValue(
                                          "sdgOptions",
                                          option
                                        )
                                      }
                                    }}
                                    value={validation.values.sdgOptions}
                                    isMulti={true}
                                    options={sustainArray}
                                    classNamePrefix="select2-selection"
                                    styles={{
                                      clearIndicator: base => ({
                                        ...base,
                                        display: "none",
                                      }),
                                    }}
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                            <Row>
                              <Col>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        {/* </form> */}
                      </CardBody>
                    </Card>
                  </Col>
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab} className="p-3">
                <TabPane tabId={3} key={3} value={3}>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Overview</CardTitle>
                        {/* <form className="outer-repeater"> */}
                        <div data-repeater-list="outer-group" className="outer">
                          <div data-repeater-item className="outer">
                            <FormGroup className="mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Summary
                              </Label>
                              <Col lg="10">
                                <Editor
                                  id="summaryDesc3"
                                  name="summaryDesc3"
                                  editorState={summaryEditorState3.editorValue}
                                  onEditorStateChange={newEditorState =>
                                    handleEditorChange(
                                      newEditorState,
                                      "summaryDesc3"
                                    )
                                  }
                                  value={validation.values.summaryDesc3 || ""}
                                />
                                {validation.touched.summaryDesc3 &&
                                validation.errors.summaryDesc3 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.summaryDesc3}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col className="mt-3" lg="10">
                                <div className="inner-repeater mb-4">
                                  <Label className="col-form-label col-lg-12">
                                    Statistics
                                  </Label>
                                  <div className="inner form-group mb-0 row">
                                    <div
                                      className="inner col-lg-10 ml-md-auto"
                                      id="repeater"
                                    >
                                      {summaryInputFields3.map(
                                        (item, index) => (
                                          <div
                                            key={index}
                                            id={"summary3" + index}
                                            className="mb-3 row align-items-center"
                                          >
                                            <Col md="5">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                defaultValue={item.key}
                                                placeholder="Enter Label..."
                                                value={item.key}
                                                onChange={e =>
                                                  handleOnChange(
                                                    "summary3",
                                                    "key",
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </Col>
                                            <Col md="5">
                                              <div className="mt-4 mt-md-0">
                                                <Input
                                                  type="text"
                                                  className="form-control"
                                                  defaultValue={item.value}
                                                  value={item.value}
                                                  placeholder="Enter Value..."
                                                  onChange={e =>
                                                    handleOnChange(
                                                      "summary3",
                                                      "value",
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </Col>
                                            <Col md="2">
                                              <div className="mt-2 mt-md-0 d-grid">
                                                <Button
                                                  color="primary"
                                                  className="inner"
                                                  onClick={() => {
                                                    handleRemoveFields(
                                                      "summary3",
                                                      index,
                                                      item.id
                                                    )
                                                  }}
                                                  block
                                                >
                                                  Delete
                                                </Button>
                                              </div>
                                            </Col>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <Row className="justify-content-start">
                                    <Col lg="10">
                                      <Button
                                        color="success"
                                        className="inner"
                                        onClick={() => {
                                          handleAddFields("summary3")
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Impact
                              </Label>
                              <Col lg="10">
                                <Editor
                                  id="impactDesc3"
                                  name="impactDesc3"
                                  editorState={impactEditorState3.editorValue}
                                  onEditorStateChange={newEditorState =>
                                    handleEditorChange(
                                      newEditorState,
                                      "impactDesc3"
                                    )
                                  }
                                  value={validation.values.impactDesc3 || ""}
                                />
                              </Col>
                              <Col className="mt-3" lg="10">
                                <div className="inner-repeater mb-4">
                                  <Label className="col-form-label col-lg-12">
                                    Statistics
                                  </Label>
                                  <div className="inner form-group mb-0 row">
                                    <div
                                      className="inner col-lg-10 ml-md-auto"
                                      id="repeater"
                                    >
                                      {impactInputFields3.map((item, index) => (
                                        <div
                                          key={index}
                                          id={"impact3" + index}
                                          className="mb-3 row align-items-center"
                                        >
                                          <Col md="6">
                                            <Input
                                              type="text"
                                              className="form-control"
                                              defaultValue={item.key}
                                              value={item.key}
                                              placeholder="Enter Label..."
                                              onChange={e =>
                                                handleOnChange(
                                                  "impact3",
                                                  "key",
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col md="4">
                                            <div className="mt-4 mt-md-0">
                                              <Input
                                                type="text"
                                                className="form-control"
                                                defaultValue={item.value}
                                                value={item.value}
                                                placeholder="Enter Value..."
                                                onChange={e =>
                                                  handleOnChange(
                                                    "impact3",
                                                    "value",
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col md="2">
                                            <div className="mt-2 mt-md-0 d-grid">
                                              <Button
                                                color="primary"
                                                className="inner"
                                                onClick={() => {
                                                  handleRemoveFields(
                                                    "impact3",
                                                    index,
                                                    item.id
                                                  )
                                                }}
                                                block
                                              >
                                                Delete
                                              </Button>
                                            </div>
                                          </Col>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <Row className="justify-content-start">
                                    <Col lg="10">
                                      <Button
                                        color="success"
                                        className="inner"
                                        onClick={() => {
                                          handleAddFields("impact3")
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </FormGroup>

                            <FormGroup className="mb-4" row>
                              <Label className="col-form-label col-lg-12">
                                Sustainable Development Goals
                              </Label>
                              <Col lg="10">
                                <Editor
                                  id="sdgDesc3"
                                  name="sdgDesc3"
                                  editorState={sdgEditorState3.editorValue}
                                  onEditorStateChange={newEditorState =>
                                    handleEditorChange(
                                      newEditorState,
                                      "sdgDesc3"
                                    )
                                  }
                                  value={validation.values.sdgDesc3 || ""}
                                />
                              </Col>
                              <Col className="mt-3" md="10">
                                <div className="mb-3">
                                  <Select
                                    id="sdgOptions"
                                    name="sdgOptions"
                                    type="select"
                                    onChange={(option, e) => {
                                      console.log("option", option, e)
                                      if (e.action === "remove-value") {
                                        if (
                                          checkDataIsPresent(
                                            "sustainarr",
                                            e.removedValue.id
                                          )
                                        ) {
                                          setTempEntityData({
                                            fieldRef: "sustainarr",
                                            index: option,
                                          })
                                          dispatch(
                                            deleteSectionEntry(
                                              e.removedValue.id,
                                              "sustainarr"
                                            )
                                          )
                                        } else {
                                          validation.handleChange(
                                            "sdgOptions",
                                            option
                                          )
                                          validation.setFieldValue(
                                            "sdgOptions",
                                            option
                                          )
                                        }
                                      } else {
                                        validation.handleChange(
                                          "sdgOptions",
                                          option
                                        )
                                        validation.setFieldValue(
                                          "sdgOptions",
                                          option
                                        )
                                      }
                                    }}
                                    value={validation.values.sdgOptions}
                                    isMulti={true}
                                    options={sustainArray}
                                    classNamePrefix="select2-selection"
                                    styles={{
                                      clearIndicator: base => ({
                                        ...base,
                                        display: "none",
                                      }),
                                    }}
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                            <Row>
                              <Col>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        {/* </form> */}
                      </CardBody>
                    </Card>
                  </Col>
                </TabPane>
              </TabContent>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddCompany.propTypes = {
  company: PropTypes.array,
  onAddNewCompany: PropTypes.func,
}

export default AddCompany
