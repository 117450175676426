import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_USERROLE,
  GET_USERROLE_BY_ID,
  ADD_USERROLE,
  DELETE_USERROLE,
  UPDATE_USERROLE,
} from "./actionTypes"
import {
  getUserRoleFail,
  getUserRoleSuccess,
  getUserRoleByIdFail,
  getUserRoleByIdSuccess,
  addUserRoleFail,
  addUserRoleSuccess,
  updateUserRoleSuccess,
  updateUserRoleFail,
  deleteUserRoleSuccess,
  deleteUserRoleFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUserRole,
  addNewUserRole,
  updateUserRole,
  deleteUserRole,
  getUserRoleById
} from "helpers/fakebackend_helper"
import 'react-toastify/dist/ReactToastify.css';

function* fetchUserRole() {
  try {
    const response = yield call(getUserRole)
    yield put(getUserRoleSuccess(response.data))
  } catch (error) {
    yield put(getUserRoleFail(error.response))
  }
}

function* fetchUserRoleById({payload: userroleId}) {
  try {
    const response = yield call(getUserRoleById, userroleId)
    console.log('userrole saga', response.data);
    yield put(getUserRoleByIdSuccess(response.data))
    console.log('userrole saga 1', response.data);
  } catch (error) {
    yield put(getUserRoleByIdFail(error.response))
  }
}

function* onUpdateUserRole({ payload: userrole }) {
  try {
    const response = yield call(updateUserRole, userrole)
    yield put(updateUserRoleSuccess(response))
  } catch (error) {
    yield put(updateUserRoleFail(error.response))
  }
}

function* onDeleteUserRole({ payload: userrole }) {
  try {
    const response = yield call(deleteUserRole, userrole)
    yield put(deleteUserRoleSuccess(response, userrole))
  } catch (error) {
    yield put(deleteUserRoleFail(error.response))
  }
}

function* onAddNewUserRole({ payload: userrole }) {
  try {
    const response = yield call(addNewUserRole, userrole)

    yield put(addUserRoleSuccess(response))
  } catch (error) {
    yield put(addUserRoleFail(error.response))
  }
}

function* userroleSaga() {
  yield takeEvery(GET_USERROLE, fetchUserRole)
  yield takeEvery(GET_USERROLE_BY_ID ,fetchUserRoleById)
  yield takeEvery(ADD_USERROLE, onAddNewUserRole)
  yield takeEvery(UPDATE_USERROLE, onUpdateUserRole)
  yield takeEvery(DELETE_USERROLE, onDeleteUserRole)
}

export default userroleSaga
