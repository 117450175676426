import {
  GET_NEWS,
  GET_NEWS_FAIL,
  GET_NEWS_SUCCESS,
  GET_NEWS_BY_ID,
  GET_NEWS_BY_ID_SUCCESS,
  GET_NEWS_BY_ID_FAIL,
  ADD_NEWS,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_FAIL,
  UPDATE_NEWS,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

export const getNews = () => ({
  type: GET_NEWS,
})

export const getNewsSuccess = News => ({
  type: GET_NEWS_SUCCESS,
  payload: News,
})

export const getNewsFail = error => ({
  type: GET_NEWS_FAIL,
  payload: error,
})

export const getNewsById = newsId => ({
  type: GET_NEWS_BY_ID,
  payload: newsId,
})

export const getNewsByIdSuccess = News => ({
  type: GET_NEWS_BY_ID_SUCCESS,
  payload: News,
})

export const getNewsByIdFail = error => ({
  type: GET_NEWS_BY_ID_FAIL,
  payload: error,
})

export const addNewNews = news => ({
  type: ADD_NEWS,
  payload: news,
})

export const addNewsSuccess = news => ({
  type: ADD_NEWS_SUCCESS,
  payload: news,
})

export const addNewsFail = error => ({
  type: ADD_NEWS_FAIL,
  payload: error,
})

export const updateNews = news => ({
  type: UPDATE_NEWS,
  payload: news,
})

export const updateNewsSuccess = news => ({
  type: UPDATE_NEWS_SUCCESS,
  payload: news,
})

export const updateNewsFail = error => ({
  type: UPDATE_NEWS_FAIL,
  payload: error,
})

export const deleteNews = news => ({
  type: DELETE_NEWS,
  payload: news,
})

export const deleteNewsSuccess = (response, newsId) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: response,
  newsId: newsId,
})

export const deleteNewsFail = error => ({
  type: DELETE_NEWS_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = success => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
})
