/* INSIGHTS */
export const GET_INSIGHTS = "GET_INSIGHTS"
export const GET_INSIGHTS_SUCCESS = "GET_INSIGHTS_SUCCESS"
export const GET_INSIGHTS_FAIL = "GET_INSIGHTS_FAIL"

export const GET_INSIGHTS_BY_ID = "GET_INSIGHTS_BY_ID"
export const GET_INSIGHTS_BY_ID_SUCCESS = "GET_INSIGHTS_BY_ID_SUCCESS"
export const GET_INSIGHTS_BY_ID_FAIL = "GET_INSIGHTS_BY_ID_FAIL"

/**
 * add INSIGHTS
 */
export const ADD_NEW_INSIGHTS = "ADD_NEW_INSIGHTS"
export const ADD_INSIGHTS_SUCCESS = "ADD_INSIGHTS_SUCCESS"
export const ADD_INSIGHTS_FAIL = "ADD_INSIGHTS_FAIL"

/**
 * Edit INSIGHTS
 */
export const UPDATE_INSIGHTS = "UPDATE_INSIGHTS"
export const UPDATE_INSIGHTS_SUCCESS = "UPDATE_INSIGHTS_SUCCESS"
export const UPDATE_INSIGHTS_FAIL = "UPDATE_INSIGHTS_FAIL"

/**
 * Delete INSIGHTS
 */
export const DELETE_INSIGHTS = "DELETE_INSIGHTS"
export const DELETE_INSIGHTS_SUCCESS = "DELETE_INSIGHTS_SUCCESS"
export const DELETE_INSIGHTS_FAIL = "DELETE_INSIGHTS_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';