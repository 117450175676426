import React, { useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
//import { Container } from "reactstrap";
import {
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  Card,
  Button,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import "./AdvisorDetails.css"
//import {PersonaDetail} from "./personaDetail.js"
import { withRouter, Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import UsersModal from "pages/Users/UserModal"
import {
  freezeUser,
  //addNewReports as onAddNewReports,
  //updateReports as onUpdateReports,
  //getReports as onGetReports,
  getUsersById as onGetUsersById,
  verifyUsers as onVerifyUsers,
} from "store/users/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import ReasonModal from "pages/Users/ReasonModal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
//import { Document, Page } from 'react-pdf';
const AdvisorDetails = () => {
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search)
  const userId = searchParams.get("id")
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [approveEnable, setApproveEnable] = useState(false)
  const { usersById, addUpdateSuccess, error, deleteSuccess, freezeSuccess } =
    useSelector(state => ({
      usersById: state.Users.usersById,
      error: state.Users.error,
      addUpdateSuccess: state.Users.addUpdateSuccess,
      deleteSuccess: state.Users.deleteSuccess,
      freezeSuccess: state.Users.freezeSuccess,
    }))
  const history = useHistory()
  const [selectedUsers, setSelectedUsers] = useState({})
  const [modalImageUrl, setModalImageUrl] = useState("")
  const toggleViewModal = imageUrl => {
    setModalImageUrl(imageUrl)
    setModal1(!modal1)
  }
  const [clientDetail, setclientDetail] = useState([
    {
      id: "clientId1",
      //customerName: "Neal Matthews",
      ClientName: "Neal",
      emailId: "neal.matthews@test.com",
    },
    {
      id: "clientId2",
      //customerName: "Jamal Burnett",
      ClientName: "Jamal Burnett",
      emailId: "Jamal.Burnett@test.com",
    },
    {
      id: "clientId3",
      //customerName: "Barry Dick",
      ClientName: "Barry Dick",
      emailId: "Barry.Dick@test.com",
    },
  ])
  const clientColumns = () => [
    {
      dataField: "id",
      text: "Client ID",
    },
    {
      dataField: "ClientName",
      text: "Client Name",
      sort: true,
    },
    {
      dataField: "emailId",
      text: "Email Id",
      sort: true,
    },
  ]
  const [documents, setDocuments] = useState([])
  useEffect(() => {
    if (userId !== null && userId !== undefined) {
      //call api to get company data;
      dispatch(onGetUsersById(userId))
    }
  }, [userId])

  useEffect(() => {
    //console.log('Insights', usersById)
    if (usersById.length > 0 && usersById !== null) {
      setSelectedUsers(usersById[0])
      setDocuments(usersById[0]?.userdetail?.document)
      //setIsEdit(true)
      //assignInitialValues(usersById[0])
    }
  }, [usersById])

  useEffect(() => {
    if (freezeSuccess === true && usersById[0]?.isActive) {
      toast.success("Account has been freezed!")
      dispatch(onGetUsersById(userId))
    } else if (freezeSuccess === true && !usersById[0]?.isActive) {
      toast.success("Account has been Unfreezed!")
      dispatch(onGetUsersById(userId))
    } else if (freezeSuccess === false) {
      toast.error("Something went wrong!")
    }
  }, [freezeSuccess])

  const [selectedDocument, setSelectedDocument] = useState(false)
  const onClickApprove = () => {
    //setSelectedDocument(selectedDocument);
    //setModal2(!modal1)
    usersById[0].userdetail.document = documents
    console.log(usersById)
    const obj = {
      id: usersById[0].id,
      //"userdetail":[{
      rejectionreason: null,
      document: usersById[0].userdetail.document,
      //}]
    }

    obj["email"] = usersById[0]?.email;
    obj["firstname"] = usersById[0]?.userdetail?.firstname;
    obj["lastname"] = usersById[0]?.userdetail?.lastname;
    dispatch(onVerifyUsers(obj))
  }
  const [rejectType, setRejectType] = useState("")
  const onClickReject = type => {
    setRejectType(type)
    setModal2(!modal2)
    const obj = {
      id: usersById[0].id,
      //"userdetail":[{
      rejectionreason: null,
      document: usersById[0].userdetail.document,
      //}]
    }

    // if (type === "document") {
    //   obj["email"] = usersById[0]?.email
    // }
    obj["email"] = usersById[0]?.email;
    obj["firstname"] = usersById[0]?.userdetail?.firstname;
    obj["lastname"] = usersById[0]?.userdetail?.lastname;
    // obj["email"] = usersById[0]?.email;

    //dispatch(onVerifyUsers(obj))
    setModalImageUrl(obj)
  }

  const onClickFreeze = status => {
    const obj = {
      id: usersById[0].id,
      isActive: status === "freeze" ? true : false,
    }
    dispatch(freezeUser(obj))
  }

  const toggleDocumentStatus = (id, status) => {
    setDocuments(
      documents.map((doc, i) => {
        if (doc.id === id) {
          documents[i].status = status
          return { ...doc, status: status }
        }
        return doc
      })
    )
    setApproveEnable(areAllStatusApproved(documents))
  }
  const areAllStatusApproved = array => {
    return array.every(obj => obj.status === "Approved")
  }

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
    else if (addUpdateSuccess) {
      toast.success(`User updated successfully`, { autoClose: 2000 })
      //validation.resetForm();
      history.push("/users")
    } else if (
      !addUpdateSuccess &&
      addUpdateSuccess !== null &&
      addUpdateSuccess !== undefined &&
      addUpdateSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
  }, [error, addUpdateSuccess])
  return (
    <>
      {usersById && usersById !== undefined ? (
        <>
          <UsersModal
            isOpen={modal1}
            toggle={toggleViewModal}
            imageUrl={modalImageUrl}
          />
          <ReasonModal
            isOpen={modal2}
            toggle={onClickReject}
            imageUrl={modalImageUrl}
            rejectType={rejectType}
          />
          <div className="page-content">
            <MetaTags>
              <title>Advisor</title>
            </MetaTags>
            <Container fluid={true}>
              <Breadcrumbs title="Users" breadcrumbItem="Advisor" />
              {/* <Row>
            <Col xs="12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between float-right">
                <Button color="primary" className="btn-rounded ">
                  Remove
                </Button>
              </div>
            </Col>
          </Row> */}
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <CardTitle className="h4">Personal Details</CardTitle>
                        <Col>
                          <label
                            htmlFor="fname"
                            className="col-md-4 col-form-label"
                          >
                            First Name
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="text"
                              id="fname"
                              name="fname"
                              placeholder="Enter Your First Name"
                              value={usersById[0]?.userdetail?.firstname || ""}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col>
                          <label
                            htmlFor="lname"
                            className="col-md-4 col-form-label"
                          >
                            Last Name
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="text"
                              id="lname"
                              name="lname"
                              placeholder="Enter Your Last Name"
                              value={usersById[0]?.userdetail?.lastname || ""}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col>
                          <label
                            htmlFor="phoneNumber"
                            className="col-md-4 col-form-label"
                          >
                            Phone Number
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder="Enter Phone Number"
                              type="tel"
                              value={usersById[0]?.contact || ""}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <CardTitle className="h4">Documents</CardTitle>
                        <Row>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Sub Type</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {documents.map((doc, idx) => (
                                    <tr key={idx}>
                                      <th scope="row">{doc.id}</th>
                                      <td>{doc.fileName}</td>
                                      <td>{doc.documenttype}</td>
                                      <td>{doc.documentsubtype}</td>
                                      <td>
                                        {doc.status == "Decline"
                                          ? "Rejected"
                                          : doc.status}
                                      </td>
                                      <td>
                                        {/* <button type="button" className="btn btn-light btn-sm">View</button>
                                    <button type="button" className="btn btn-light btn-sm">Approve</button>
                                    <button type="button" className="btn btn-light btn-sm">Reject</button> */}
                                        <div className="d-flex gap-3">
                                          <div
                                            className="text"
                                            onClick={() =>
                                              toggleViewModal(doc.documenturl)
                                            }
                                          >
                                            <i
                                              className="mdi mdi-eye font-size-18"
                                              id="viewtooltip"
                                            />
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="viewtooltip"
                                            >
                                              View
                                            </UncontrolledTooltip>
                                          </div>
                                          {usersById[0]?.status !==
                                          "approved" ? (
                                            <div
                                              className="text-success"
                                              onClick={() =>
                                                toggleDocumentStatus(
                                                  doc.id,
                                                  "Approved"
                                                )
                                              }
                                            >
                                              <i
                                                className="mdi mdi-check-decagram font-size-18"
                                                id="approvetooltip"
                                              />
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="approvetooltip"
                                              >
                                                Approve
                                              </UncontrolledTooltip>
                                            </div>
                                          ) : null}
                                          {usersById[0]?.status !==
                                          "approved" ? (
                                            <div
                                              className="text-danger"
                                              onClick={() =>
                                                toggleDocumentStatus(
                                                  doc.id,
                                                  "Decline"
                                                )
                                              }
                                            >
                                              <i
                                                className="mdi mdi-alpha-x-circle font-size-18"
                                                id="rejecttooltip"
                                              />
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="rejecttooltip"
                                              >
                                                Reject
                                              </UncontrolledTooltip>
                                            </div>
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                  {/* <tr>
                                <th scope="row">2</th>
                                <td>Document 2</td>
                                <td>Address Proof</td>
                                <td>
                                  <div className="d-flex gap-3">
                                      <Link
                                        to="#"
                                        className="text"
                                        onClick={() => onClickDelete(row)}
                                      >
                                        <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                                        <UncontrolledTooltip placement="top" target="viewtooltip">
                                        View
                                        </UncontrolledTooltip>
                                      </Link> 
                                      <Link
                                        to="#"
                                        className="text-success"
                                        onClick={() => onClickDelete(row)}
                                      >
                                        <i className="mdi mdi-check-decagram font-size-18" id="approvetooltip" />
                                        <UncontrolledTooltip placement="top" target="approvetooltip">
                                        Approve
                                        </UncontrolledTooltip>
                                      </Link>
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() => onClickDelete(row)}
                                      >
                                        <i className="mdi mdi-alpha-x-circle font-size-18" id="rejecttooltip" />
                                        <UncontrolledTooltip placement="top" target="rejecttooltip">
                                        Reject
                                        </UncontrolledTooltip>
                                      </Link>
                                     </div>
                                </td>
                            </tr> */}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* User Relation Integration */}
              {usersById[0]?.UserRelations &&
              usersById[0]?.UserRelations.length > 0 ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row className="mb-3">
                          <CardTitle className="h4">
                            Client&apos;s Details
                          </CardTitle>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Email Id</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {usersById[0]?.UserRelations.map(item => {
                                    return (
                                      item.requestStatus === "approved" && (
                                        <tr>
                                          <td>{item?.investor?.id}</td>
                                          <td>
                                            {item?.investor?.role ===
                                            "legalrepresent"
                                              ? item?.investor?.userdetail
                                                  ?.userlrdetail[0]?.companyname
                                              : item?.investor?.userdetail
                                                  ?.firstname +
                                                " " +
                                                item?.investor?.userdetail
                                                  ?.lastname}
                                          </td>
                                          <td>{item?.investor?.email}</td>
                                        </tr>
                                      )
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              {usersById[0]?.DeviceDetails &&
              usersById[0]?.DeviceDetails.length > 0 ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row className="mb-3">
                          <CardTitle className="h4">Active Devices</CardTitle>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Device Id</th>
                                    <th>Device Type</th>
                                    <th>Time Zone</th>
                                    <th>Connection Type</th>
                                    {/* <th>Status</th>
                                                          <th>Action</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {usersById[0]?.DeviceDetails.map(
                                    (item, idx) => (
                                      <tr key={idx}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.deviceId}</td>
                                        <td>{item.deviceType}</td>
                                        <td>{item.timeZone}</td>
                                        <td>{item.connectionType}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              {/* Complaints Integration Pending */}
              {usersById[0]?.Complaints &&
              usersById[0]?.Complaints.length > 0 ? (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <Row className="mb-3">
                          <CardTitle className="h4">Report Filed</CardTitle>
                          <Col>
                            <div className="table-responsive">
                              <Table className="align-middle mb-0">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Complaint Description</th>
                                    <th>Resolve</th>
                                    <th>Allot an offices</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td>Description 1</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                      >
                                        Resolve
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={toggleViewModal}
                                      >
                                        Allot an offices
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">2</th>
                                    <td>Description 2</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                      >
                                        Resolve
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={toggleViewModal}
                                      >
                                        Allot an offices
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              {usersById[0]?.status !== "approved" &&
              usersById[0]?.status !== "freeze" ? (
                <Row>
                  <Col xs="12">
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        style={{ marginRight: "20px" }}
                        onClick={() => { history.push(`/add-advisor?id=${userId}`) }}
                      >
                        Edit Details
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        style={{ marginRight: "20px" }}
                        disabled={!approveEnable}
                        onClick={onClickApprove}
                      >
                        Approve
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        onClick={() => onClickReject("user")}
                      >
                        Reject
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        onClick={() => onClickReject("document")}
                        style={{ marginLeft: "20px" }}
                      >
                        Reject Document
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs="12">
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-success approve-user"
                        style={{ marginRight: "20px" }}
                        onClick={() => { history.push(`/add-advisor?id=${userId}`) }}
                      >
                        Edit Details
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          onClickFreeze(usersById[0]?.status)
                        }}
                      >
                        {usersById[0]?.status === "freeze"
                          ? "Unfreeze Account"
                          : "Freeze Account"}
                      </button>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </>
      ) : null}
    </>
  )
}

export default AdvisorDetails
