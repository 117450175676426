import {
  GET_NEWS_FAIL,
  GET_NEWS_SUCCESS,
  GET_NEWS_BY_ID_SUCCESS,
  GET_NEWS_BY_ID_FAIL,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_FAIL,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  newsList: [],
  newsById: [],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {},
}

const News = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        newsList: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {},
      }

    case GET_NEWS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }

    case GET_NEWS_BY_ID_SUCCESS:
      console.log("news reducer", action.payload)
      return {
        ...state,
        newsById: action.payload,
        error: {},
      }

    case GET_NEWS_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        newsById: [],
      }

    case ADD_NEWS_SUCCESS:
      return {
        ...state,
        newsList: [...state.newsList, action.payload],
        addUpdateSuccess: true,
        error: {},
      }

    case ADD_NEWS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        newsList: state.newsList.map(news =>
          news.id === action.payload.id ? { news, ...action.payload } : news
        ),
        addUpdateSuccess: true,
        error: {},
      }

    case UPDATE_NEWS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        newsList: state.newsList.filter(news => news.id !== action.newsId),
        deleteSuccess: true,
        error: {},
      }

    case DELETE_NEWS_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.payload,
      }

    default:
      return state
  }
}

export default News
