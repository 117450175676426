import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_COMPLAINTS,
  GET_COMPLAINTS_BY_ID,
  ADD_NEW_COMPLAINTS,
  DELETE_COMPLAINTS,
  UPDATE_COMPLAINTS,
} from "./actionTypes"
import {
  getComplaintsFail,
  getComplaintsSuccess,
  getComplaintsByIdFail,
  getComplaintsByIdSuccess,
  addComplaintsFail,
  addComplaintsSuccess,
  updateComplaintsSuccess,
  updateComplaintsFail,
  deleteComplaintsSuccess,
  deleteComplaintsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getComplaints,
  /*  addNewComplaints,
  updateComplaints,
  deleteComplaints,  */
  getComplaintsById,
  updateComplaints,
} from "helpers/fakebackend_helper"
import 'react-toastify/dist/ReactToastify.css';

function* fetchComplaints() {
  try {
    const response = yield call(getComplaints)
    yield put(getComplaintsSuccess(response.data))
  } catch (error) {
    yield put(getComplaintsFail(error.response))
  }
}

function* fetchComplaintsById({payload: complaintsId}) {
  try {
    const response = yield call(getComplaintsById, complaintsId)
    yield put(getComplaintsByIdSuccess(response.data))
  } catch (error) {
    yield put(getComplaintsByIdFail(error.response))
  }
}

function* onUpdateComplaints({ payload: complaints }) {
  try {
    const response = yield call(updateComplaints, complaints)
    yield put(updateComplaintsSuccess(response))
  } catch (error) {
    yield put(updateComplaintsFail(error.response))
  }
}

function* onDeleteComplaints({ payload: complaints }) {
  try {
    const response = yield call(deleteComplaints, complaints)
    yield put(deleteComplaintsSuccess(response, complaints))
  } catch (error) {
    yield put(deleteComplaintsFail(error.response))
  }
}

function* onAddNewComplaints({ payload: complaints }) {
  try {
    const response = yield call(addNewComplaints, complaints)

    yield put(addComplaintsSuccess(response))
  } catch (error) {
    yield put(addComplaintsFail(error.response))
  }
}

function* complaintsSaga() {
  yield takeEvery(GET_COMPLAINTS, fetchComplaints)
  yield takeEvery(GET_COMPLAINTS_BY_ID, fetchComplaintsById)
  yield takeEvery(ADD_NEW_COMPLAINTS, onAddNewComplaints)
  yield takeEvery(UPDATE_COMPLAINTS, onUpdateComplaints)
  yield takeEvery(DELETE_COMPLAINTS, onDeleteComplaints)
}

export default complaintsSaga
