import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_COUNTRY,
  GET_LANGUAGE,
  GET_SUSTAIN_GOAL
} from "./actionTypes"
import {
  getLanguageSuccess,
  getLanguageFail,
  getSustainGoalFail,
  getSustainGoalSuccess,
  getCountrySuccess,
  getCountryFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLanguage,
  getSustainGoal,
  getCountry
} from "helpers/fakebackend_helper"

function* fetchLanguage() {
  try {
    const response = yield call(getLanguage)
    localStorage.setItem("language", JSON.stringify(response.data))
    yield put(getLanguageSuccess(response.data))
  } catch (error) {
    yield put(getLanguageFail(error.response))
  }
}

function* fetchSustainGoals() {
  try {
    const response = yield call(getSustainGoal)
    yield put(getSustainGoalSuccess(response.data))
  } catch (error) {
    yield put(getSustainGoalFail(error.response))
  }
}

function* fetchCountryList() {
  try {
    const response = yield call(getCountry)
    yield put(getCountrySuccess(response.data))
  } catch (error) {
    yield put(getCountryFail(error.response))
  }
}

function* commonSaga() {
  yield takeEvery(GET_LANGUAGE, fetchLanguage),
  yield takeEvery(GET_SUSTAIN_GOAL, fetchSustainGoals)
  yield takeEvery(GET_COUNTRY, fetchCountryList)
}

export default commonSaga
