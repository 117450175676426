/* PORTFOLIO*/
export const GET_PORTFOLIO= "GET_PORTFOLIO"
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS"
export const GET_PORTFOLIO_FAIL = "GET_PORTFOLIO_FAIL"

export const GET_PORTFOLIO_BY_ID = "GET_PORTFOLIO_BY_ID"
export const GET_PORTFOLIO_BY_ID_SUCCESS = "GET_PORTFOLIO_BY_ID_SUCCESS"
export const GET_PORTFOLIO_BY_ID_FAIL = "GET_PORTFOLIO_BY_ID_FAIL"

/**
 * add PORTFOLIO
 */
export const ADD_NEW_PORTFOLIO= "ADD_NEW_PORTFOLIO"
export const ADD_PORTFOLIO_SUCCESS = "ADD_PORTFOLIO_SUCCESS"
export const ADD_PORTFOLIO_FAIL = "ADD_PORTFOLIO_FAIL"

/**
 * Edit PORTFOLIO
 */
export const UPDATE_PORTFOLIO= "UPDATE_PORTFOLIO"
export const UPDATE_PORTFOLIO_SUCCESS = "UPDATE_PORTFOLIO_SUCCESS"
export const UPDATE_PORTFOLIO_FAIL = "UPDATE_PORTFOLIO_FAIL"

/**
 * Delete PORTFOLIO
 */
export const DELETE_PORTFOLIO= "DELETE_PORTFOLIO"
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS"
export const DELETE_PORTFOLIO_FAIL = "DELETE_PORTFOLIO_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';

export const RESET_ADD_UPDATE_SUCCESS = 'RESET_ADD_UPDATE_SUCCESS';