import {
  GET_TRANSACTION,
  GET_TRANSACTION_FAIL,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_BY_ID,
  GET_TRANSACTION_BY_ID_SUCCESS,
  GET_TRANSACTION_BY_ID_FAIL,
  GET_ACTIVECHATREQUESTHISTORY_BY_ID,
  GET_ACTIVECHATREQUESTHISTORY_BY_ID_SUCCESS,
  GET_ACTIVECHATREQUESTHISTORY_BY_ID_FAIL,
  ADD_NEW_TRANSACTION,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAIL,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAIL,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

export const getTransaction = () => ({
  type: GET_TRANSACTION,
})

export const getTransactionSuccess = Transaction => ({
  type: GET_TRANSACTION_SUCCESS,
  payload: Transaction,
})

export const getTransactionFail = error => ({
  type: GET_TRANSACTION_FAIL,
  payload: error,
})

export const getTransactionById = transactionId => ({
  type: GET_TRANSACTION_BY_ID,
  payload: transactionId,
})

export const getTransactionByIdSuccess = Transaction => ({
  type: GET_TRANSACTION_BY_ID_SUCCESS,
  payload: Transaction,
})

export const getTransactionByIdFail = error => ({
  type: GET_TRANSACTION_BY_ID_FAIL,
  payload: error,
})

export const getActiveChatRequestHistoryById = activerequestID => ({
  type: GET_ACTIVECHATREQUESTHISTORY_BY_ID,
  payload: activerequestID,
})

export const getActiveChatRequestHistoryByIdSuccess = Transaction => ({
  type: GET_ACTIVECHATREQUESTHISTORY_BY_ID_SUCCESS,
  payload: Transaction,
})

export const getActiveChatRequestHistoryByIdFail = error => ({
  type: GET_ACTIVECHATREQUESTHISTORY_BY_ID_FAIL,
  payload: error,
})

export const addNewTransaction = transaction => ({
  type: ADD_NEW_TRANSACTION,
  payload: transaction,
})

export const addTransactionSuccess = transaction => ({
  type: ADD_TRANSACTION_SUCCESS,
  payload: transaction,
})

export const addTransactionFail = error => ({
  type: ADD_TRANSACTION_FAIL,
  payload: error,
})

export const updateTransaction = transaction => ({
  type: UPDATE_TRANSACTION,
  payload: transaction,
})

export const updateTransactionSuccess = transaction => ({
  type: UPDATE_TRANSACTION_SUCCESS,
  payload: transaction,
})

export const updateTransactionFail = error => ({
  type: UPDATE_TRANSACTION_FAIL,
  payload: error,
})

export const deleteTransaction = transaction => ({
  type: DELETE_TRANSACTION,
  payload: transaction,
})

export const deleteTransactionSuccess = (response, transactionId) => ({
  type: DELETE_TRANSACTION_SUCCESS,
  payload: response,
  transactionId: transactionId,
})

export const deleteTransactionFail = error => ({
  type: DELETE_TRANSACTION_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = success => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
})
