import {
  GET_COUNTRY_FAIL,
  GET_COUNTRY_SUCCESS,
  GET_LANGUAGE_FAIL,
  GET_LANGUAGE_SUCCESS,
  GET_SUSTAIN_GOAL_FAIL,
  GET_SUSTAIN_GOAL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  lang: [],
  sustainGoals: [],
  countryList: []
}

const Common = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        lang: action.payload,
        error: {}
      }

    case GET_LANGUAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SUSTAIN_GOAL_SUCCESS:
      return {
        ...state,
        sustainGoals: action.payload,
        error: {}
      }

    case GET_SUSTAIN_GOAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        countryList: action.payload?.sort((a, b) => a?.countryname.localeCompare(b?.countryname)),
        error: {}
      }

    case GET_COUNTRY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Common
