import { call, put, takeEvery } from "redux-saga/effects"
import {
  UPLOAD_DOCUMENT
} from "./actionTypes"
import {
  uploadDocumentSuccess,
  uploadDocumentFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  postUploadDocument
} from "helpers/fakebackend_helper"

function* onUploadDocument({ payload: document }) {
  try {
    const response = yield call(postUploadDocument, document)
    console.log('document saga', response);
    yield put(uploadDocumentSuccess(response.data))
    console.log('document saga 1', response);
  } catch (error) {
    yield put(uploadDocumentFail(error.response))
  }
}

function* documentUploadSaga() {
  yield takeEvery(UPLOAD_DOCUMENT, onUploadDocument)
}

export default documentUploadSaga
