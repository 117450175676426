import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import img7 from "../../assets/images/aadhaar-card-sample.webp"
import img4 from "../../assets/images/product/img-4.png"
import img1 from "../../assets/images/crypto/features-img/img-1.png"
const UsersModal = props => {
  const { isOpen, toggle, imageUrl } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      imageUrl={imageUrl}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Document</ModalHeader>
        <ModalBody>
          {/* <div>
            <img src={imageUrl} className="img100" />
          </div> */}

          <iframe
            src={imageUrl}
            title="Document Preview"
            width="100%"
            height="500px"
          />
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

UsersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  imageUrl: PropTypes.string,
}

export default UsersModal
