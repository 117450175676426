import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_USERS,
  ADD_NEW_USERS,
  DELETE_USERS,
  UPDATE_USERS,
  GET_USERS_BY_ID,
  VERIFY_USERS,
  REJECT_USERS,
  GET_DELETED_USERS,
  DELETE_USERS_REQUEST,
  FREEZE_USERS,
} from "./actionTypes"
import {
  getUsersFail,
  getUsersSuccess,
  getUsersByIdFail,
  getUsersByIdSuccess,
  addUsersFail,
  addUsersSuccess,
  updateUsersSuccess,
  updateUsersFail,
  deleteUsersSuccess,
  deleteUsersFail,
  verifyUsersFail,
  verifyUsersSuccess,
  rejectUsersFail,
  rejectUsersSuccess,
  getDeletedUsersSuccess,
  getDeletedUsersFail,
  deleteUsersRequestSuccess,
  deleteUsersRequestFail,
  freezeUsersSuccess,
  freezeUsersFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  getUsersById,
  addNewUsers,
  updateUsers,
  deleteUsers,
  verifyUsers,
  rejectUsers,
  getDeletedUsers,
  deleteUserRequest,
  freezeUser,
} from "helpers/fakebackend_helper"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    console.log("fetchUsers response", response)
    yield put(getUsersSuccess(response.data))
  } catch (error) {
    console.log("fetchUsers error", error.response)
    yield put(getUsersFail(error.response))
  }
}

function* fetchUsersById({ payload: usersId }) {
  try {
    const response = yield call(getUsersById, usersId)
    yield put(getUsersByIdSuccess(response.data))
  } catch (error) {
    yield put(getUsersByIdFail(error.response))
  }
}

function* onUpdateUsers({ payload: users }) {
  try {
    const response = yield call(updateUsers, users)
    yield put(updateUsersSuccess(response))
    //toast.success("User Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateUsersFail(error.response))
    //toast.error("User Not Updated Properly", { autoClose: 2000 });
  }
}

function* onDeleteUsers({ payload: users }) {
  try {
    const response = yield call(deleteUsers, users)
    yield put(deleteUsersSuccess(response, users))
    toast.success("User Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteUsersFail(error.response))
    toast.error("User Not Deleted Properly", { autoClose: 2000 });
  }
}

function* onAddNewUsers({ payload: users }) {
  try {
    const response = yield call(addNewUsers, users)

    yield put(addUsersSuccess(response))
    //toast.success("Users Added Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addUsersFail(error.response))
    //toast.error("Users Not Added Properly", { autoClose: 2000 });
  }
}

function* onVerifyUsers({ payload: users }) {
  try {
    const response = yield call(verifyUsers, users)

    yield put(verifyUsersSuccess(response))
    //toast.success("Users Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(verifyUsersFail(error.response))
    //toast.error("Users Not Updated Properly", { autoClose: 2000 });
  }
}

function* onRejectUsers({ payload: users }) {
  try {
    const response = yield call(rejectUsers, users)

    yield put(rejectUsersSuccess(response))
    //toast.success("Users Updated Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(rejectUsersFail(error.response))
    //toast.error("Users Not Updated Properly", { autoClose: 2000 });
  }
}

function* fetchDeletedUsers() {
  try {
    const response = yield call(getDeletedUsers)
    yield put(getDeletedUsersSuccess(response.data))
  } catch (error) {
    yield put(getDeletedUsersFail(error.response))
  }
}

function* onDeleteUsersReq({ payload: users }) {
  try {
    const response = yield call(deleteUserRequest, users)
    yield put(deleteUsersRequestSuccess(response, users))
    //toast.success("Users Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(deleteUsersRequestFail(error.response))
    //toast.error("Users Not Deleted Properly", { autoClose: 2000 });
  }
}

function* onFreezeUserReq({ payload: freezeObj }) {
  try {
    console.log(freezeObj)
    const response = yield call(freezeUser, freezeObj)
    yield put(freezeUsersSuccess(response, freezeObj))
    //toast.success("Users Deleted Successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(freezeUsersFail(error.response))
    //toast.error("Users Not Deleted Properly", { autoClose: 2000 });
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USERS_BY_ID, fetchUsersById)
  yield takeEvery(ADD_NEW_USERS, onAddNewUsers)
  yield takeEvery(UPDATE_USERS, onUpdateUsers)
  yield takeEvery(DELETE_USERS, onDeleteUsers)
  yield takeEvery(VERIFY_USERS, onVerifyUsers)
  yield takeEvery(REJECT_USERS, onRejectUsers)
  yield takeEvery(GET_DELETED_USERS, fetchDeletedUsers)
  yield takeEvery(DELETE_USERS_REQUEST, onDeleteUsersReq)
  yield takeEvery(FREEZE_USERS, onFreezeUserReq)
}

export default usersSaga
