import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { getTransaction as onGetTransaction } from "store/transaction/actions"
import { useDispatch, useSelector } from "react-redux"
const Transactions = () => {
  const dispatch = useDispatch()
  const { transaction, error } = useSelector(state => ({
    transaction: state.Transaction.transaction,
    error: state.Transaction.error,
  }))
  const [transactionData, setTransactionData] = useState(transaction)
  const [tableData, setTableData] = useState([])
  const [users, setUsers] = useState(transaction)
  /* [
    {
      id: "customCheck2",
      transact_date: "2023-10-10",
      //bidDetails: "bidDetails-test1",
      buyer: "Neal Matthews",
      seller: "darshan",
      company: "Telcom",
      price: "300",
      quantity: "30",
    },
    {
      id: "customCheck3",
      transact_date: "2023-10-10",
      //bidDetails: "bidDetails-test2",
      buyer: "Jamal Burnett",
      seller: "anna",
      company: "Botcon",
      price: "1000",
      quantity: "20",
    },
    {
      id: "customCheck4",
      transact_date: "2023-10-10",
      //bidDetails: "bidDetails-test3",
      buyer: "Juan Mitchell",
      seller: "sagar",
      company: "LNT",
      price: "240",
      quantity: "100",
    },
    {
      id: "customCheck5",
      transact_date: "2023-10-10",
      //bidDetails: "bidDetails-test4",
      buyer: "Barry Dick",
      seller: "santosh",
      company: "Bomcot",
      price: "380",
      quantity: "200",
    },
    {
      id: "customCheck6",
      transact_date: "2023-10-10",
      //bidDetails: "bidDetails-test5",
      buyer: "Ronald Taylor",
      seller: "falgun",
      company: "Vivo",
      price: "970",
      quantity: "20",
    },
  ]) */

  const usersColumns = () => [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "transact_date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "buyer",
      text: "Receiver",
      sort: true,
    },
    {
      dataField: "seller",
      text: "Provider",
      sort: true,
    },
    {
      dataField: "company",
      text: "Company Name",
      sort: true,
    },
    // {
    //   dataField: "price",
    //   text: "Price",
    //   sort: true,
    // },
    // {
    //   dataField: "quantity",
    //   text: "Quantity",
    //   sort: true,
    // },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(() => {
    dispatch(onGetTransaction())
    setTransactionData(transaction)
  }, [dispatch])

  useEffect(() => {
    if (transaction.length !== 0) {
      let temp = []
      transaction.map(item => {
        if (item.transact_date !== null) {
          temp.push({
            id: item.id,
            transact_date: item.transact_date,
            buyer:
              item.Buyer_Detail.role === "legalrepresent"
                ? item.Buyer_Detail?.lrDetails[0]?.companyname
                : item.Buyer_Detail.userdetail.firstname +
                  " " +
                  item.Buyer_Detail.userdetail.lastname,
            seller:
              item.Seller_Detail.role === "legalrepresent"
                ? item.Seller_Detail?.lrDetails[0]?.companyname
                : item.Seller_Detail.userdetail.firstname +
                  " " +
                  item.Seller_Detail.userdetail.lastname,
            company: item.Company.name,
            price: item.price,
            quantity: item.quantity,
            status: item.status,
          })
        }
      })
      setTableData(temp)
    }
  }, [transaction])

  const pageOptions = {
    sizePerPage: 6,
    totalSize: tableData.length, // replace later with size(orders),
    custom: true,
  }
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {/* <CardTitle className="mb-4">Transactions</CardTitle> */}
          <Row>
            <Col xl="9">
              <div className="mb-4 h4 card-title">Chats</div>
            </Col>
            <Col xl="3">
              <Link
                to="/dashboard-transactions"
                color="link"
                className="btn btn-link btn-view-all"
              >
                View All
              </Link>
            </Col>
          </Row>

          <ToolkitProvider
            keyField="id"
            data={tableData}
            columns={usersColumns()}
            bootstrap4
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"table-light"}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Transactions
