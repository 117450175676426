import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"

//import Charts
// import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import Users from "./Users"
import Transactions from "./Transactions"
import Highlights from "./Highlights"
import Complaints from "./Complaints"
import ScheduleContent from "./ScheduleContent"
import ActiveBids from "./ActiveBids"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getHighlights as onGetHighlights } from "store/highlights/actions"
import {
  getUsers as onGetUsers,
  getDeletedUsers as onGetDeletedUsers,
} from "store/users/actions"
import { getTransaction as onGetTransaction } from "store/transaction/actions"
import { getNotification } from "../../store/actions"

import { getComplaints as onGetComplaints } from "store/complaints/actions"
const Dashboard = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const { transaction, error3, notification } = useSelector(state => ({
    transaction: state.Transaction.transaction,
    error3: state.Transaction.error,
    notification: state.Dashboard.notification,
  }))
  const [pendingTransaction, setPendingTransaction] = useState(0)

  const [pendingComplaints, setPendingComplaints] = useState(0)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetUsers())
  }, [])

  const { users, error1 } = useSelector(state => ({
    users: state.Users.users,
    error1: state.Users.error,
  }))

  const { deletedUsers } = useSelector(state => ({
    deletedUsers: state.Users.deletedUsers,
  }))

  useEffect(() => {
    dispatch(onGetComplaints())
  }, [])
  useEffect(() => {
    dispatch(onGetTransaction())
  }, [])
  useEffect(() => {
    dispatch(onGetDeletedUsers())
  }, [])
  const { complaints, error2 } = useSelector(state => ({
    complaints: state.Complaints.complaints,
    error2: state.Complaints.error,
  }))

  const newUsers = users.filter(
    user => user.status === "document approval pending"
  )

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    // dispatch(onGetChartsData(pType));
  }

  useEffect(() => {
    // dispatch(onGetChartsData("yearly"));
    // dispatch(getNotification())
  }, [dispatch])

  useEffect(() => {
    dispatch(onGetHighlights())
  }, [])

  useEffect(() => {
    dispatch(getNotification())
    const interval = setInterval(() => {
      dispatch(getNotification())
    }, 30 * 1000 * 60)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    setPendingTransaction(
      transaction.filter(res => {
        return res.status === "pending"
      }).length
    )
  }, [transaction])

  useEffect(() => {
    setPendingComplaints(
      complaints.filter(res => {
        return res.status === "pending"
      }).length
    )
  }, [complaints])

  const { highlights, error } = useSelector(state => ({
    highlights: state.Highlights.highlights,
    error: state.Highlights.error,
  }))

  const reports_1 = [
    {
      title: "New Users",
      iconClass: "bx-copy-alt",
      description: newUsers?.length,
      link: "/users"
    },
    {
      title: "Pending Chats",
      iconClass: "bx-copy-alt",
      description: pendingTransaction,
      link: "/dashboard-transactions"
    }
  ]

  const reports_2 = [
    {
      title: "Unresolved Complaints",
      iconClass: "bx-copy-alt",
      description: pendingComplaints,
      link: "/complaints"
    },
    {
      title: "User Delete Request",
      iconClass: "bx-copy-alt",
      description: deletedUsers?.length,
      link: "/user-deletion"
    },
    { title: "Highlight rsvp", 
      iconClass: "bx-copy-alt", 
      description: highlights.filter((item) => { return item.eventType !== "communication" })?.length,
      link: "/dashboard-highlights"
    },
    /* { title: "Scheduled Content", iconClass: "bx-copy-alt", description: "1,723" } */
  ]
  //console.log("highlights-->",highlights)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid className="dashboard">
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="12">
              <Row>
                {reports_1.map((report, key) => (
                  <Col md="6" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Link to={`${report.link}`}>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Row>
                {reports_2.map((report, key) => (
                  <Col md="6" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Link to={`${report.link}`}>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          {/* <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  
                  <Row>
                    <Col xl="9">
                      <div className="mb-4 h4 card-title">Activity History</div>
                    </Col>
                  </Row>
                  <div className="table-wrap">
                    <div className="table-body">
                      {notification.slice(0, 10).map((item, index) => {
                        return (
                          <>
                            {item.title === "New Delete Request" && (
                              <div key={index} className="table-row">
                                {item.message} by{" "}
                                {item?.User?.userdetail?.firstname}
                              </div>
                            )}
                            {item.title === "Transaction is Added" && (
                              <div key={index} className="table-row">
                                A New Transaction has been added on{" "}
                                {item?.createdOn}
                              </div>
                            )}
                            {item.title === "New User Registered." && (
                              <div key={index} className="table-row">
                                A New User has been registered on{" "}
                                {item?.createdOn}
                              </div>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <Row>
            {/* <Col xl="6">
              <Users />
            </Col> */}
            <Col xl="12">
              <Transactions />
            </Col>
            {/* <Col xl="6">
              <Complaints />
            </Col> */}
          </Row>

          {/*  <Row>
            <Col xl="6">
              <Highlights/>
            </Col>
            <Col xl="6">
              <Complaints />
            </Col>
          </Row> */}

          {/* <Row>
            <Col xl="6">
              <ScheduleContent />
            </Col>
          </Row> */}

          {/* <Row>
            <Col>
              <ActiveBids />
            </Col>
          </Row> */}
        </Container>
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
