/* COMPANY */
export const GET_COMPANY = "GET_COMPANY"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL"

/* COMPANY */
export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID"
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS"
export const GET_COMPANY_BY_ID_FAIL = "GET_COMPANY_BY_ID_FAIL"
export const RESET_COMPANY_BY_ID = "RESET_COMPANY_BY_ID"

/**
 * add COMPANY
 */
export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

/**
 * Edit COMPANY
 */
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

/**
 * Delete COMPANY
 */
export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';

/**
 * Delete COMPANY
 */
export const DELETE_SECTION_ENTRY = "DELETE_SECTION_ENTRY"
export const DELETE_SECTION_ENTRY_SUCCESS = "DELETE_SECTION_ENTRY_SUCCESS"
export const DELETE_SECTION_ENTRY_FAIL = "DELETE_SECTION_ENTRY_FAIL"