import {
  GET_HIGHLIGHTS,
  GET_HIGHLIGHTS_FAIL,
  GET_HIGHLIGHTS_SUCCESS,
  GET_HIGHLIGHTS_BY_ID,
  GET_HIGHLIGHTS_BY_ID_SUCCESS,
  GET_HIGHLIGHTS_BY_ID_FAIL,
  ADD_NEW_HIGHLIGHTS,
  ADD_HIGHLIGHTS_SUCCESS,
  ADD_HIGHLIGHTS_FAIL,
  UPDATE_HIGHLIGHTS,
  UPDATE_HIGHLIGHTS_SUCCESS,
  UPDATE_HIGHLIGHTS_FAIL,
  DELETE_HIGHLIGHTS,
  DELETE_HIGHLIGHTS_SUCCESS,
  DELETE_HIGHLIGHTS_FAIL,
  UPDATE_DELETE_SUCCESS
} from "./actionTypes"

export const getHighlights = () => ({
  type: GET_HIGHLIGHTS,
})

export const getHighlightsSuccess = Highlights => ({
  type: GET_HIGHLIGHTS_SUCCESS,
  payload: Highlights,
})

export const getHighlightsFail = error => ({
  type: GET_HIGHLIGHTS_FAIL,
  payload: error,
})

export const getHighlightsById = (highlightsId) => ({
  type: GET_HIGHLIGHTS_BY_ID,
  payload: highlightsId,
})

export const getHighlightsByIdSuccess = Highlights => ({
  type: GET_HIGHLIGHTS_BY_ID_SUCCESS,
  payload: Highlights,
})

export const getHighlightsByIdFail = error => ({
  type: GET_HIGHLIGHTS_BY_ID_FAIL,
  payload: error,
})

export const addNewHighlights = highlights => ({
  type: ADD_NEW_HIGHLIGHTS,
  payload: highlights,
})

export const addHighlightsSuccess = highlights => ({
  type: ADD_HIGHLIGHTS_SUCCESS,
  payload: highlights,
})

export const addHighlightsFail = error => ({
  type: ADD_HIGHLIGHTS_FAIL,
  payload: error,
})

export const updateHighlights = highlights => ({
  type: UPDATE_HIGHLIGHTS,
  payload: highlights,
})

export const updateHighlightsSuccess = highlights => ({
  type: UPDATE_HIGHLIGHTS_SUCCESS,
  payload: highlights,
})

export const updateHighlightsFail = error => ({
  type: UPDATE_HIGHLIGHTS_FAIL,
  payload: error,
})

export const deleteHighlights = highlights => ({
  type: DELETE_HIGHLIGHTS,
  payload: highlights,
})

export const deleteHighlightsSuccess = (response, highlightsId) => ({
  type: DELETE_HIGHLIGHTS_SUCCESS,
  payload: response,
  highlightsId: highlightsId
})

export const deleteHighlightsFail = error => ({
  type: DELETE_HIGHLIGHTS_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = (success) => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
});