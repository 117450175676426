import {
  GET_PORTFOLIO_FAIL,
  GET_PORTFOLIO_SUCCESS,
  GET_PORTFOLIO_BY_ID_SUCCESS,
  GET_PORTFOLIO_BY_ID_FAIL,
  ADD_PORTFOLIO_SUCCESS,
  ADD_PORTFOLIO_FAIL,
  UPDATE_PORTFOLIO_SUCCESS,
  UPDATE_PORTFOLIO_FAIL,
  DELETE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_FAIL,
  UPDATE_DELETE_SUCCESS,
  RESET_ADD_UPDATE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  portfolio: [],
  portfolioById : [],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {}
}

const Portfolio = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolio: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {}
      }

    case GET_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }
      
    case GET_PORTFOLIO_BY_ID_SUCCESS:
      return {
        ...state,
        portfolioById: action.payload,
        error: {}
      }

    case GET_PORTFOLIO_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        portfolioById : [],
      }  

    case ADD_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolio: [...state.portfolio, action.payload],
        addUpdateSuccess: true,
        error: {}
      }

    case ADD_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolio: state.portfolio.map(portfolio =>
          portfolio.id === action.payload.id
            ? { portfolio, ...action.payload }
            : portfolio
        ),
        addUpdateSuccess: true,
        error: {}
      }

    case UPDATE_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case RESET_ADD_UPDATE_SUCCESS:
      return { 
        ...state, 
        addUpdateSuccess: action.payload 
      };

    case DELETE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        // portfolio: state.portfolio.filter(
        //   portfolio => portfolio.id !== action.portfolioId
        // ),
        deleteSuccess: true,
        error: {}
      }

    case DELETE_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return { 
        ...state, 
        deleteSuccess: action.payload 
      };

    default:
      return state
  }
}

export default Portfolio
