/* UPLOAD DOCUMENT */
export const GET_LANGUAGE = "GET_LANGUAGE"
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS"
export const GET_LANGUAGE_FAIL = "GET_LANGUAGE_FAIL"

/* UPLOAD DOCUMENT */
export const GET_SUSTAIN_GOAL = "GET_SUSTAIN_GOAL"
export const GET_SUSTAIN_GOAL_SUCCESS = "GET_SUSTAIN_GOAL_SUCCESS"
export const GET_SUSTAIN_GOAL_FAIL = "GET_SUSTAIN_GOAL_FAIL"

/* GET COUNTRY */
export const GET_COUNTRY = "GET_COUNTRY"
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS"
export const GET_COUNTRY_FAIL = "GET_SUSTAIN_GOAL_FAIL"