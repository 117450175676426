/* COMPLAINTS*/
export const GET_COMPLAINTS= "GET_COMPLAINTS"
export const GET_COMPLAINTS_SUCCESS = "GET_COMPLAINTS_SUCCESS"
export const GET_COMPLAINTS_FAIL = "GET_COMPLAINTS_FAIL"

export const GET_COMPLAINTS_BY_ID = "GET_COMPLAINTS_BY_ID"
export const GET_COMPLAINTS_BY_ID_SUCCESS = "GET_COMPLAINTS_BY_ID_SUCCESS"
export const GET_COMPLAINTS_BY_ID_FAIL = "GET_COMPLAINTS_BY_ID_FAIL"

/**
 * add COMPLAINTS
 */
export const ADD_NEW_COMPLAINTS= "ADD_NEW_COMPLAINTS"
export const ADD_COMPLAINTS_SUCCESS = "ADD_COMPLAINTS_SUCCESS"
export const ADD_COMPLAINTS_FAIL = "ADD_COMPLAINTS_FAIL"

/**
 * Edit COMPLAINTS
 */
export const UPDATE_COMPLAINTS= "UPDATE_COMPLAINTS"
export const UPDATE_COMPLAINTS_SUCCESS = "UPDATE_COMPLAINTS_SUCCESS"
export const UPDATE_COMPLAINTS_FAIL = "UPDATE_COMPLAINTS_FAIL"

/**
 * Delete COMPLAINTS
 */
export const DELETE_COMPLAINTS= "DELETE_COMPLAINTS"
export const DELETE_COMPLAINTS_SUCCESS = "DELETE_COMPLAINTS_SUCCESS"
export const DELETE_COMPLAINTS_FAIL = "DELETE_COMPLAINTS_FAIL"

/**
 * Update Delete Success Flag State
 */
export const UPDATE_DELETE_SUCCESS = 'UPDATE_DELETE_SUCCESS';