import {
  GET_COMPLAINTS_FAIL,
  GET_COMPLAINTS_SUCCESS,
  GET_COMPLAINTS_BY_ID_SUCCESS,
  GET_COMPLAINTS_BY_ID_FAIL,
  ADD_COMPLAINTS_SUCCESS,
  ADD_COMPLAINTS_FAIL,
  UPDATE_COMPLAINTS_SUCCESS,
  UPDATE_COMPLAINTS_FAIL,
  DELETE_COMPLAINTS_SUCCESS,
  DELETE_COMPLAINTS_FAIL,
  UPDATE_DELETE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  complaints: [],
  complaintsById : [],
  addUpdateSuccess: null,
  deleteSuccess: null,
  error: {}
}

const Complaints = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {}
      }

    case GET_COMPLAINTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }
      
    case GET_COMPLAINTS_BY_ID_SUCCESS:
      return {
        ...state,
        complaintsById: action.payload,
        error: {}
      }

    case GET_COMPLAINTS_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        complaintsById : [],
      }  

    case ADD_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: [...state.complaints, action.payload],
        addUpdateSuccess: true,
        error: {}
      }

    case ADD_COMPLAINTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: state.complaints.map(complaints =>
          complaints.id === action.payload.id
            ? { complaints, ...action.payload }
            : complaints
        ),
        addUpdateSuccess: true,
        error: {}
      }

    case UPDATE_COMPLAINTS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: state.complaints.filter(
          complaints => complaints.id !== action.complaintsId
        ),
        deleteSuccess: true,
        error: {}
      }

    case DELETE_COMPLAINTS_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return { 
        ...state, 
        deleteSuccess: action.payload 
      };

    default:
      return state
  }
}

export default Complaints
