import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  chartsData: [],
  notification: [],
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data,
          }
        default:
          return state
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsDataError: action.payload.error,
          }

        default:
          return state
      }
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.payload,
        error: {},
      }
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Dashboard
