import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Button, Card, CardBody, Col, Row, Badge } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"

// import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

//redux
import { useSelector, useDispatch } from "react-redux"

const ActiveBids = props => {
  const dispatch = useDispatch()

  const { orders } = useSelector(state => ({
    orders: state.ecommerce.orders,
  }))

  useEffect(() => {
    dispatch(onGetOrders())
  }, [dispatch]);

  const selectRow = {
    mode: "checkbox",
  }

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search

  // const toggleModal = () => {
  //   setModal1(!modal1)
  // }
  const toggleViewModal = () => setModal1(!modal1)

  const activeBidsColumns = toggleModal => [
    // {
    //   dataField: "orderId",
    //   text: "Order ID",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <Link to="#" className="text-body fw-bold">
    //       {row.orderId}
    //     </Link>
    //   ),
    // },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
    },
    // {
    //   dataField: "orderdate",
    //   text: "Date",
    //   sort: true,
    // },
    {
      dataField: "bidDetails",
      text: "Bid Details",
      sort: true,
    },
    {
      dataField: "activeBuyers",
      text: "Active Buyers",
      sort: true,
      // eslint-disable-next-line react/display-name
      // formatter: (cellContent, row) => (
      //   <Badge
      //     className={"font-size-12 badge-soft-" + row.badgeclass}
      //     color={row.badgeClass}
      //     pill
      //   >
      //     {row.paymentStatus}
      //   </Badge>
      // ),
    },
    {
      dataField: "sellers",
      text: "Active Sellers",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={"font-size-11 badge-soft-" + row.badgeclass}
          color={row.badgeClass}
        >
          {row.status}
        </Badge>
      ),
    },
    // {
    //   dataField: "paymentMethod",
    //   isDummyField: true,
    //   text: "Payment Method",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <>
    //       <i
    //         className={
    //           row.paymentMethod !== "COD"
    //             ? "fab fa-cc-" + toLowerCase1(row.paymentMethod) + " me-1"
    //             : "fab fas fa-money-bill-alt me-1"
    //         }
    //       />{" "}
    //       {row.paymentMethod}
    //     </>
    //   ),
    // },
    // {
    //   dataField: "view",
    //   isDummyField: true,
    //   text: "View Details",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: () => (
    //     <Button
    //       type="button"
    //       color="primary"
    //       className="btn-sm btn-rounded"
    //       onClick={toggleViewModal}
    //     >
    //       View Details
    //     </Button>
    //   ),
    // },
  ]

  useEffect(() => {
    if (orders && !orders.length) {
      onGetOrders()
    }
  }, [onGetOrders, orders])

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const toLowerCase1 = str => {
    return str.toLowerCase()
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <Card>
        <CardBody>
          <Row>
            <Col xl="9">
              <div className="mb-4 h4 card-title">Active Bids</div>
            </Col>
            <Col xl="3">
              <Link to="#" color="link" className="btn btn-link btn-view-all">View All</Link>
            </Col>
          </Row>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={activeBidsColumns(toggle)}
            data={orders}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={activeBidsColumns(toggle)}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="pagination pagination-rounded justify-content-end">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ActiveBids.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(ActiveBids)
