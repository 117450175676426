import React, { useEffect, useRef } from "react"

function EmbeddedApp() {
  // const parentUrl = window.location.href;

  // return (
  //   <iframe
  //     src={`https://feuat.application-ai-x.com/login?parentUrl=${encodeURIComponent(parentUrl)}`}
  //     // src={`http://localhost:3000/login?parentUrl=${encodeURIComponent(parentUrl)}`}
  //     style={{ width: "100%", height: "100vh", border: "none" }}
  //     title="Embedded Application"
  //     // ref={iframeRef}
  //     id="onboardingIframe"
  //     // onLoad={sendMessageToChild}
  //   />
  // )
}

export default EmbeddedApp
