import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  getComplaints,
  updateComplaints as onUpdateComplaints,
} from "store/complaints/actions"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import "./complaints.css"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"

const Complaints = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { complaints, addUpdateSuccess, error } = useSelector(state => ({
    complaints: state.Complaints.complaints,
    error: state.Complaints.error,
    addUpdateSuccess: state.Complaints.addUpdateSuccess,
  }))
  // const [complaintsData, setComplaintsData] = useState(complaints)
  const [tableData, setTableData] = useState([])
  const { SearchBar } = Search
  //const [users, setUsers] = useState(transaction)

  const [users, setUsers] = useState(
    /* [
    {
      id: "customCheck2",
      complainant: "Neal Matthews",
      // mobileNo: "9999999999",
      // emailId: "neal.matthews@test.com",
      //type: "Investor",
    },
    {
      id: "customCheck3",
      complainant: "Jamal Burnett",
      //mobileNo: "9999999988",
      //emailId: "Jamal.Burnett@test.com",
      //type: "Investor",
    },
    {
      id: "customCheck4",
      complainant: "Juan Mitchell",
      // mobileNo: "9999999977",
      // emailId: "Juan.Mitchell@test.com",
      // type: "Advisor",
    },
    {
      id: "customCheck5",
      complainant: "Barry Dick",
      // mobileNo: "9999999966",
      // emailId: "Barry.Dick@test.com",
      // type: "Investor",
    },
    {
      id: "customCheck6",
      complainant: "Ronald Taylor",
      // mobileNo: "9999999955",
      // emailId: "Ronald.Taylor@test.com",
      // type: "Advisor",
    }
  ] */ complaints
  )

  const nameFormatter = (cell, row) => {
    return row?.User?.role === 'legalrepresent'
      ? row?.User?.lrDetails[0]?.companyname
      : `${row?.User?.userdetail.firstname} ${row?.User?.userdetail.lastname}`;
  };

  const usersColumns = () => [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name of Complainant",
      sort: true,
      formatter: nameFormatter,
    },
    {
      dataField: "name",
      text: "Name of Complaint against",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    /*{
      dataField: "mobileNo",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "emailId",
      text: "Email Id",
      sort: true,
    },
    {
      dataField: "type",
      text: "User Type",
      sort: true,
    }, */
    // {
    //   dataField: "view",
    //   isDummyField: true,
    //   text: "Assign Officer",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <Button
    //       type="button"
    //       color="primary"
    //       className="btn-sm btn-rounded"
    //       onClick={() => {}}
    //     >
    //       Assign Officer
    //     </Button>
    //   ),
    // },
    {
      dataField: "resolve",
      isDummyField: true,
      text: "Resolve",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => {
            updateComplaints(row.id)
          }}
        >
          Resolve
        </Button>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  useEffect(() => {
    dispatch(getComplaints())
  }, [dispatch])

  const updateComplaints = id => {
    const updateComplaintsPayload = {
      id: id,
      status: "resolve",
    }
    dispatch(onUpdateComplaints(updateComplaintsPayload))
  }

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
    else if (addUpdateSuccess) {
      toast.success(`Complaints updated successfully`, { autoClose: 2000 })
      dispatch(getComplaints())
    } else if (
      !addUpdateSuccess &&
      addUpdateSuccess !== null &&
      addUpdateSuccess !== undefined &&
      addUpdateSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
  }, [error, addUpdateSuccess])

  useEffect(() => {
    if (complaints.length !== 0) {
      let temp = complaints.filter((item) => {
        return item.status !== "resolve"
      })
      setTableData(temp)
    }
  }, [complaints])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Complaints</CardTitle> */}
                  <Row>
                    <Col xl="9">
                      <div className="mb-4 h4 card-title">Complaints</div>
                    </Col>
                    {/* <Col xl="3">
                        <Link to="/#" color="link" className="btn btn-link btn-view-all">View All</Link>
                      </Col> */}
                  </Row>

                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={usersColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              {/* <SearchBar {...toolkitProps.searchProps} /> */}
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                filter={filterFactory()}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Complaints
