import {
  GET_COMPLAINTS,
  GET_COMPLAINTS_FAIL,
  GET_COMPLAINTS_SUCCESS,
  GET_COMPLAINTS_BY_ID,
  GET_COMPLAINTS_BY_ID_SUCCESS,
  GET_COMPLAINTS_BY_ID_FAIL,
  ADD_NEW_COMPLAINTS,
  ADD_COMPLAINTS_SUCCESS,
  ADD_COMPLAINTS_FAIL,
  UPDATE_COMPLAINTS,
  UPDATE_COMPLAINTS_SUCCESS,
  UPDATE_COMPLAINTS_FAIL,
  DELETE_COMPLAINTS,
  DELETE_COMPLAINTS_SUCCESS,
  DELETE_COMPLAINTS_FAIL,
  UPDATE_DELETE_SUCCESS
} from "./actionTypes"

export const getComplaints = () => ({
  type: GET_COMPLAINTS,
})

export const getComplaintsSuccess = Complaints => ({
  type: GET_COMPLAINTS_SUCCESS,
  payload: Complaints,
})

export const getComplaintsFail = error => ({
  type: GET_COMPLAINTS_FAIL,
  payload: error,
})

export const getComplaintsById = (complaintsId) => ({
  type: GET_COMPLAINTS_BY_ID,
  payload: complaintsId,
})

export const getComplaintsByIdSuccess = Complaints => ({
  type: GET_COMPLAINTS_BY_ID_SUCCESS,
  payload: Complaints,
})

export const getComplaintsByIdFail = error => ({
  type: GET_COMPLAINTS_BY_ID_FAIL,
  payload: error,
})

export const addNewComplaints = complaints => ({
  type: ADD_NEW_COMPLAINTS,
  payload: complaints,
})

export const addComplaintsSuccess = complaints => ({
  type: ADD_COMPLAINTS_SUCCESS,
  payload: complaints,
})

export const addComplaintsFail = error => ({
  type: ADD_COMPLAINTS_FAIL,
  payload: error,
})

export const updateComplaints = complaints => ({
  type: UPDATE_COMPLAINTS,
  payload: complaints,
})

export const updateComplaintsSuccess = complaints => ({
  type: UPDATE_COMPLAINTS_SUCCESS,
  payload: complaints,
})

export const updateComplaintsFail = error => ({
  type: UPDATE_COMPLAINTS_FAIL,
  payload: error,
})

export const deleteComplaints = complaints => ({
  type: DELETE_COMPLAINTS,
  payload: complaints,
})

export const deleteComplaintsSuccess = (response, complaintsId) => ({
  type: DELETE_COMPLAINTS_SUCCESS,
  payload: response,
  complaintsId: complaintsId
})

export const deleteComplaintsFail = error => ({
  type: DELETE_COMPLAINTS_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = (success) => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
});