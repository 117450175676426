import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"

import {
  Container,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  UncontrolledTooltip,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getTransaction } from "store/actions"
const index = props => {
  const dispatch = useDispatch()
  const { transaction } = useSelector(state => ({
    transaction: state.Transaction.transaction,
  }))

  const [transactionData, setTransactionData] = useState(transaction)
  const [tableData, setTableData] = useState([])
  // const [transactions, setTransactions] = useState([
  //   {
  //     id: "customCheck2",
  //     transact_date: "2023-10-10",
  //     //bidDetails: "bidDetails-test1",
  //     buyer: "Neal Matthews",
  //     seller: "darshan",
  //     company: "Telcom",
  //     price: "300",
  //     quantity: "30",
  //   },
  //   {
  //     id: "customCheck3",
  //     transact_date: "2023-10-10",
  //     //bidDetails: "bidDetails-test2",
  //     buyer: "Jamal Burnett",
  //     seller: "anna",
  //     company: "Botcon",
  //     price: "1000",
  //     quantity: "20",
  //   },
  //   {
  //     id: "customCheck4",
  //     transact_date: "2023-10-10",
  //     //bidDetails: "bidDetails-test3",
  //     buyer: "Juan Mitchell",
  //     seller: "sagar",
  //     company: "LNT",
  //     price: "240",
  //     quantity: "100",
  //   },
  //   {
  //     id: "customCheck5",
  //     transact_date: "2023-10-10",
  //     //bidDetails: "bidDetails-test4",
  //     buyer: "Barry Dick",
  //     seller: "santosh",
  //     company: "Bomcot",
  //     price: "380",
  //     quantity: "200",
  //   },
  //   {
  //     id: "customCheck6",
  //     transact_date: "2023-10-10",
  //     //bidDetails: "bidDetails-test5",
  //     buyer: "Ronald Taylor",
  //     seller: "falgun",
  //     company: "Vivo",
  //     price: "970",
  //     quantity: "20",
  //   },
  //   {
  //     id: "customCheck7",
  //     transact_date: "2023-10-10",
  //     //bidDetails: "bidDetails-test6",
  //     buyer: "Juan Mitchell",
  //     seller: "sagar",
  //     company: "TCS",
  //     price: "2000",
  //     quantity: "50",
  //   },
  //   {
  //     id: "customCheck8",
  //     transact_date: "2023-10-10",
  //     //bidDetails: "bidDetails-test7",
  //     buyer: "Barry Dick",
  //     seller: "santosh",
  //     company: "Jio",
  //     price: "980",
  //     quantity: "1000",
  //   },
  //   {
  //     id: "customCheck9",
  //     transact_date: "2023-10-10",
  //     //bidDetails: "bidDetails-test8",
  //     buyer: "Ronald Taylor",
  //     seller: "falgun",
  //     company: "Fortune",
  //     price: "250",
  //     quantity: "500",
  //   },
  // ])

  useEffect(() => {
    dispatch(getTransaction())
    setTransactionData(transaction)
  }, [dispatch])

  useEffect(() => {
    if (transaction.length !== 0) {
      let temp = []
      transaction.map(item => {
        // if (item.transact_date !== null) {
        temp.push({
          id: item.id,
          transact_date: item.transact_date,
          buyer:
            item.Buyer_Detail.role === "legalrepresent"
              ? item.Buyer_Detail?.lrDetails[0]?.companyname
              : item.Buyer_Detail.userdetail.firstname +
                " " +
                item.Buyer_Detail.userdetail.lastname,
          seller:
            item.Seller_Detail.role === "legalrepresent"
              ? item.Seller_Detail?.lrDetails[0]?.companyname
              : item.Seller_Detail.userdetail.firstname +
                " " +
                item.Seller_Detail.userdetail.lastname,
          company: item.Company.name,
          price: item.price,
          quantity: item.quantity,
          status: item.status,
        })
        // }
      })
      setTableData(temp)
    }
  }, [transaction])

  const pageOptions = {
    sizePerPage: 5,
    totalSize: transaction.length, // replace later with size(orders),
    custom: true,
  }

  const transactionColumns = () => [
    // {
    //   dataField: "orderId",
    //   text: "Order ID",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <Link to="#" className="text-body fw-bold">
    //       {row.orderId}
    //     </Link>
    //   ),
    // },
    /* {
      dataField: "bidDetails",
      text: "Bid Details",
      sort: true,
    }, */
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "transact_date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "buyer",
      text: "Receiver",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "seller",
      text: "Provider",
      sort: true,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      // formatter: (cellContent, row) => (
      //   <Badge
      //     className={"font-size-12 badge-soft-" + row.badgeclass}
      //     color={row.badgeClass}
      //     pill
      //   >
      //     {row.paymentStatus}
      //   </Badge>
      // ),
    },
    {
      dataField: "company",
      text: "Company Name",
      sort: true,
    },
    // {
    //   dataField: "price",
    //   text: "Price",
    //   sort: true,
    // },
    // {
    //   dataField: "quantity",
    //   text: "Quantity",
    //   sort: true,
    // },
    {
      dataField: "status",
      text: "Status",
      sort: false,
    },
    // {
    //   dataField: "paymentMethod",
    //   isDummyField: true,
    //   text: "Payment Method",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <>
    //       <i
    //         className={
    //           row.paymentMethod !== "COD"
    //             ? "fab fa-cc-" + toLowerCase1(row.paymentMethod) + " me-1"
    //             : "fab fas fa-money-bill-alt me-1"
    //         }
    //       />{" "}
    //       {row.paymentMethod}
    //     </>
    //   ),
    // },
    // {
    //   dataField: "view",
    //   isDummyField: true,
    //   text: "View Details",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: () => (
    //     <Button
    //       type="button"
    //       color="primary"
    //       className="btn-sm btn-rounded"
    //       onClick={toggleViewModal}
    //     >
    //       View Details
    //     </Button>
    //   ),
    // },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            <Link to={`/transaction-details?id=${row.id}`} className="text">
              <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View Details
              </UncontrolledTooltip>
            </Link>
            {/* <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(company)}
            >
              <i className="bx bxs-trash font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link> */}
          </div>
        </>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "transactionId",
      order: "desc",
    },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Chats Dashboard</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Chats" />

          <Card>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={transactionColumns()}
                data={tableData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={tableData}
                    columns={transactionColumns()}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                {/* <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" /> */}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive transaction-table">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                filter={filterFactory()}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="pagination pagination-rounded justify-content-end">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
