import {
  GET_INSIGHTS,
  GET_INSIGHTS_FAIL,
  GET_INSIGHTS_SUCCESS,
  GET_INSIGHTS_BY_ID,
  GET_INSIGHTS_BY_ID_SUCCESS,
  GET_INSIGHTS_BY_ID_FAIL,
  ADD_NEW_INSIGHTS,
  ADD_INSIGHTS_SUCCESS,
  ADD_INSIGHTS_FAIL,
  UPDATE_INSIGHTS,
  UPDATE_INSIGHTS_SUCCESS,
  UPDATE_INSIGHTS_FAIL,
  DELETE_INSIGHTS,
  DELETE_INSIGHTS_SUCCESS,
  DELETE_INSIGHTS_FAIL,
  UPDATE_DELETE_SUCCESS
} from "./actionTypes"

export const getInsights = () => ({
  type: GET_INSIGHTS,
})

export const getInsightsSuccess = Insights => ({
  type: GET_INSIGHTS_SUCCESS,
  payload: Insights,
})

export const getInsightsFail = error => ({
  type: GET_INSIGHTS_FAIL,
  payload: error,
})

export const getInsightsById = (insightsId) => ({
  type: GET_INSIGHTS_BY_ID,
  payload: insightsId,
})

export const getInsightsByIdSuccess = Insights => ({
  type: GET_INSIGHTS_BY_ID_SUCCESS,
  payload: Insights,
})

export const getInsightsByIdFail = error => ({
  type: GET_INSIGHTS_BY_ID_FAIL,
  payload: error,
})

export const addNewInsights = insights => ({
  type: ADD_NEW_INSIGHTS,
  payload: insights,
})

export const addInsightsSuccess = insights => ({
  type: ADD_INSIGHTS_SUCCESS,
  payload: insights,
})

export const addInsightsFail = error => ({
  type: ADD_INSIGHTS_FAIL,
  payload: error,
})

export const updateInsights = insights => ({
  type: UPDATE_INSIGHTS,
  payload: insights,
})

export const updateInsightsSuccess = insights => ({
  type: UPDATE_INSIGHTS_SUCCESS,
  payload: insights,
})

export const updateInsightsFail = error => ({
  type: UPDATE_INSIGHTS_FAIL,
  payload: error,
})

export const deleteInsights = insights => ({
  type: DELETE_INSIGHTS,
  payload: insights,
})

export const deleteInsightsSuccess = (response, insightsId) => ({
  type: DELETE_INSIGHTS_SUCCESS,
  payload: response,
  insightsId: insightsId
})

export const deleteInsightsFail = error => ({
  type: DELETE_INSIGHTS_FAIL,
  payload: error,
})

export const updateDeleteSuccessFlag = (success) => ({
  type: UPDATE_DELETE_SUCCESS,
  payload: success,
});