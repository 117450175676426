import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_HIGHLIGHTS,
  ADD_NEW_HIGHLIGHTS,
  DELETE_HIGHLIGHTS,
  UPDATE_HIGHLIGHTS,
  GET_HIGHLIGHTS_BY_ID,
} from "./actionTypes"
import {
  getHighlightsFail,
  getHighlightsSuccess,
  getHighlightsByIdFail,
  getHighlightsByIdSuccess,
  addHighlightsFail,
  addHighlightsSuccess,
  updateHighlightsSuccess,
  updateHighlightsFail,
  deleteHighlightsSuccess,
  deleteHighlightsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getHighlights,
  getHighlightsById,
  addNewHighlights,
  updateHighlights,
  deleteHighlights,
} from "helpers/fakebackend_helper"
import 'react-toastify/dist/ReactToastify.css';

function* fetchHighlights() {
  try {
    const response = yield call(getHighlights)
    yield put(getHighlightsSuccess(response.data))
  } catch (error) {
    yield put(getHighlightsFail(error.response))
  }
}

function* fetchHighlightsById({payload: highlightsId}) {
  try {
    const response = yield call(getHighlightsById, highlightsId)
    yield put(getHighlightsByIdSuccess(response.data))
  } catch (error) {
    yield put(getHighlightsByIdFail(error.response))
  }
}

function* onUpdateHighlights({ payload: highlights }) {
  try {
    const response = yield call(updateHighlights, highlights)
    yield put(updateHighlightsSuccess(response))
  } catch (error) {
    yield put(updateHighlightsFail(error.response))
  }
}

function* onDeleteHighlights({ payload: highlights }) {
  try {
    const response = yield call(deleteHighlights, highlights)
    yield put(deleteHighlightsSuccess(response, highlights))
  } catch (error) {
    yield put(deleteHighlightsFail(error.response))
  }
}

function* onAddNewHighlights({ payload: highlights }) {
  try {
    const response = yield call(addNewHighlights, highlights)
    yield put(addHighlightsSuccess(response))
  } catch (error) {
    yield put(addHighlightsFail(error.response))
  }
}

function* highlightsSaga() {
  yield takeEvery(GET_HIGHLIGHTS, fetchHighlights)
  yield takeEvery(GET_HIGHLIGHTS_BY_ID, fetchHighlightsById)
  yield takeEvery(ADD_NEW_HIGHLIGHTS, onAddNewHighlights)
  yield takeEvery(UPDATE_HIGHLIGHTS, onUpdateHighlights)
  yield takeEvery(DELETE_HIGHLIGHTS, onDeleteHighlights)
}

export default highlightsSaga
